import { memo, useEffect, useMemo, useState } from 'react';
import Div from 'shared/Basic/Div'
import Select from 'react-select'


const HoursOpen = memo(({ name, field, formik, options, theme, value }) => {
    const days = ["sunday", "monday", "tuesday", "wednesday", "thursday", "friday", "saturday"]
    const hours = ["00", "01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23"]
    const minutes = ["00", "15", "30", "45", "50"]

    const timingOptions = useMemo(() => {
        let opt = []
        for (let i of hours) {
            for (let j of minutes) {
                opt.push({
                    value: i + ":" + j,
                    label: i + ":" + j
                })
            }
        }
        return opt
    })

    const handleSelect = (day,type,t) =>{
        formik.setFieldValue("hours",{
            ...value,
            [day]: { ...value[day],[type]: t}
        })
    }

    return (
        <Div className="open_hours_sec">
            <h2>Open Hours</h2>
            {
                days.map((weekDay, i) => (

                        <div className='open_hours_innr_sec'>
                            <span className='day'>{`${weekDay[0].toUpperCase()}${weekDay.slice(1,)}`}</span>
                            <div className='selection_sec'>
                                <div className='custom_slect'>
                                    <Select
                                        placeholder="Select Open Time"
                                        classNamePrefix="select_time"
                                        value={timingOptions.filter(eachOption => eachOption.value === value?.[weekDay]?.["open"])}

                                        onChange={(e) => handleSelect(weekDay, "open", e.value)}
                                        theme={(theme) => ({
                                            ...theme,
                                            colors: {
                                                ...theme.colors,
                                                primary25: 'rgba(255, 180, 0, 0.2)',
                                                primary: 'rgba(255, 180, 0, 0.7)',
                                            }
                                        })}
                                        options={timingOptions.filter((each) => {
                                            if (!!value[weekDay]?.["close"]) {
                                                return each.value < value[weekDay]["close"] ? true : false
                                            }
                                            return true
                                        })}
                                    />
                                </div>
                                <div className='custom_slect'>
                                    <Select
                                        placeholder="Select Closing Time"
                                        classNamePrefix="select_time"
                                        value={timingOptions.filter(eachOption => eachOption.value === value?.[weekDay]?.["close"])}
                                        onChange={(e) => handleSelect(weekDay, "close", e.value)}
                                        theme={(theme) => ({
                                            ...theme,
                                            colors: {
                                                ...theme.colors,
                                                primary25: 'rgba(255, 180, 0, 0.2)',
                                                primary: 'rgba(255, 180, 0, 0.7)',
                                            }
                                        })}
                                        options={timingOptions.filter((each) => {
                                            if (!!value[weekDay]?.["open"]) {
                                                return each.value > value[weekDay]["open"] ? true : false
                                            }
                                            return true
                                        })}

                                    />
                                </div>
                            </div>
                        </div>
                    ))
            }
        </Div>
    )
})
export default HoursOpen

