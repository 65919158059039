import { PLACES_INDEX } from 'config/variables'
import {createReportFields, validateCreateReport} from 'features/user/admin/fields/createReport'
import {submitFormWrapperStyle}                 from 'features/user/admin/views/styles'
import {useContext, useEffect}                  from 'react'
import {useDispatch, useSelector}               from 'react-redux'
import Div                                      from 'shared/Basic/Div'
import {menuPanelContext}                       from 'shared/Containers/MenuPanelController'
import Form                                     from 'shared/Fields/Form'
import {submitPlaceWrapperStyle}                from './styles'

const CreateReportPanel = () => {
    const dispatch = useDispatch()
    const {_id, token, createReportSuccess} = useSelector(state => state.user)
    const {place} = useSelector(state => state.place)
    const {setPanel} = useContext(menuPanelContext)
    console.log(place)
    useEffect(() => {
        if (createReportSuccess) {
            setPanel(null)
            dispatch({type: 'user/closeReportSubmissionPanel'})
        }else{
            console.log("Unable to close pannel report submission failed")
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [createReportSuccess])

//TODO: Create recuring report creation, need to update Form.js to include time selection
// or a dropdown
    const initialValues = {
        _id: _id,
        token: token,
        longitude: place.geojson[0].geometry.coordinates[0],
        latitude: place.geojson[0].geometry.coordinates[1],
        address1: place.geojson[0].properties.address,
        name: place.name,
        submittedBy: [_id],
        isPendingSubmission: true,
        placeId: place._id
    }

    return (
        <Div theme={submitPlaceWrapperStyle}>
            <Form
                initialValues={initialValues}
                fields={createReportFields}
                validationSchema={validateCreateReport}
                dispatchAction={'user/submitCreateReport'}
                formHeading={'Create A Report'}
                buttonText={'Submit'}
                theme={submitFormWrapperStyle}
                enableReinitialize={true}
            />
            
        </Div>
    )
}

export default CreateReportPanel
