import {takeEvery}                                from '@redux-saga/core/effects'
import {getOwnerIdentity, getOwnerIdentityList} from 'features/place/admin/taxonomy/ownerIdentity/services'
import {call, put}                                from 'redux-saga/effects'
import {createEntity, updateEntity}               from 'utils/abstractions/crud'
import {setFormData}                              from 'utils/abstractions/setFormData'

export function* createOwnerIdentity({payload}) {
    const {_id, token, name, description,} = payload

    //add to formdata so api can read
    const ownerIdentity = new FormData()
    const fields = [{name}, {description}]
    for (let field of fields)
        setFormData(ownerIdentity, field)

    const createdOwnerIdentity = yield call(createEntity, {
        _id: _id,
        token: token,
        body: ownerIdentity,
        slug: 'owner-identity'
    })
    if (!createdOwnerIdentity.error) {
        yield put({type: 'place/listOwnerIdentity'})
        // yield put(push('/admin/places/update/' + crea.slug))

    } else {
        yield put({type: 'place/createOwnerIdentityFailure', payload})

    }
}

export function* listOwnerIdentity() {
    try {
        const payload = yield call(getOwnerIdentityList)
        if (!payload.error) {
            yield put({type: 'place/listOwnerIdentitySuccess', payload})
        } else {
            yield put({type: 'place/listOwnerIdentityFailure', payload})
        }
    } catch (error) {
        yield put({type: 'place/listOwnerIdentityFailure', error})
    }
}

export function* getOwnerIdentityDetail({payload}) {
    try {
        const ownerIdentity = yield call(getOwnerIdentity, payload)
        if (!ownerIdentity.error) {
            yield put({type: 'place/getOwnerIdentitySuccess', payload: ownerIdentity})
        } else {
            yield put({type: 'place/getOwnerIdentityFailure', payload: ownerIdentity})
        }
    } catch (error) {
        yield put({type: 'place/getOwnerIdentityFailure', payload: error})
    }
}

export function* updateOwnerIdentityDetail({payload}) {
    const {slug, _id, token, name, description} = payload

    //add to formData so api can read
    const ownerIdentity = new FormData()
    const fields = [{name}, {description}]
    for (let field of fields)
        setFormData(ownerIdentity, field)

    try {
        const updated = yield call(updateEntity, {
            slug: slug,
            parentSlug: 'owner-identity',
            body: ownerIdentity,
            _id: _id,
            token: token,
        })
        if (!updated.error) {
            yield put({type: 'place/updateOwnerIdentitySuccess', payload: updated})
            yield put({
                type: 'site/setNotification',
                payload: {
                    notification: 'Updated OwnerIdentity',
                    theme: 'green'
                }
            })

        } else {
            yield put({type: 'place/updateOwnerIdentityFailure', payload: updated})
        }
    } catch (error) {
        yield put({type: 'place/updateOwnerIdentityFailure'})
    }
}


export function* watchCreateOwnerIdentity() {
    yield takeEvery('place/createOwnerIdentity', createOwnerIdentity)
}

export function* watchGetOwnerIdentityList() {
    yield takeEvery('place/listOwnerIdentity', listOwnerIdentity)
}

export function* watchGetOwnerIdentityDetail() {
    yield takeEvery('place/getOwnerIdentity', getOwnerIdentityDetail)
}

export function* watchUpdateOwnerIdentityDetail() {
    yield takeEvery('place/updateOwnerIdentity', updateOwnerIdentityDetail)
}

