import ReviewReply              from 'features/place/admin/views/ReviewReply'
import Div                 from 'shared/Basic/Div'
import {adminMenuStyle}    from 'shared/Layout/Dashboard/admin/styles'
import {leaveAReviewStyle} from './styles'

const ReplyAReview = () =>
    <Div theme={leaveAReviewStyle}>
        <Div theme={adminMenuStyle.list}>
            <Div theme={adminMenuStyle.tagline}>
                <ReviewReply/>
            </Div>
        </Div>
    </Div>


export default ReplyAReview
