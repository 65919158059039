import {takeEvery}                  from '@redux-saga/core/effects'
import {
    getVisualImpairedSolution,
    getVisualImpairedSolutionList
}                                   from 'features/place/admin/taxonomy/visualImpairedSolution/services'
import {call, put}                  from 'redux-saga/effects'
import {createEntity, updateEntity} from 'utils/abstractions/crud'
import {setFormData}                from 'utils/abstractions/setFormData'

export function* createVisualImpairedSolution({payload}) {
    const {_id, token, name, description,} = payload

    //add to formdata so api can read
    const visualImpairedSolution = new FormData()
    const fields = [{name}, {description}]
    for (let field of fields)
        setFormData(visualImpairedSolution, field)

    const createdVisualImpairedSolution = yield call(createEntity, {
        _id: _id,
        token: token,
        body: visualImpairedSolution,
        slug: 'visual-impaired-solution'
    })
    if (!createdVisualImpairedSolution.error) {
        yield put({type: 'place/listVisualImpairedSolution'})
        // yield put(push('/admin/places/update/' + crea.slug))

    } else {
        yield put({type: 'place/createVisualImpairedSolutionFailure', payload})

    }
}

export function* listVisualImpairedSolution() {
    try {
        const payload = yield call(getVisualImpairedSolutionList)
        if (!payload.error) {
            yield put({type: 'place/listVisualImpairedSolutionSuccess', payload})
        } else {
            yield put({type: 'place/listVisualImpairedSolutionFailure', payload})
        }
    } catch (error) {
        yield put({type: 'place/listVisualImpairedSolutionFailure', error})
    }
}

export function* getVisualImpairedSolutionDetail({payload}) {
    try {
        const visualImpairedSolution = yield call(getVisualImpairedSolution, payload)
        if (!visualImpairedSolution.error) {
            yield put({type: 'place/getVisualImpairedSolutionSuccess', payload: visualImpairedSolution})
        } else {
            yield put({type: 'place/getVisualImpairedSolutionFailure', payload: visualImpairedSolution})
        }
    } catch (error) {
        yield put({type: 'place/getVisualImpairedSolutionFailure', payload: error})
    }
}

export function* updateVisualImpairedSolutionDetail({payload}) {
    const {slug, _id, token, name, description} = payload

    //add to formData so api can read
    const visualImpairedSolution = new FormData()
    const fields = [{name}, {description}]
    for (let field of fields)
        setFormData(visualImpairedSolution, field)

    try {
        const updated = yield call(updateEntity, {
            slug: slug,
            parentSlug: 'visual-impaired-solution',
            body: visualImpairedSolution,
            _id: _id,
            token: token,
        })
        if (!updated.error) {
            yield put({type: 'place/updateVisualImpairedSolutionSuccess', payload: updated})
            yield put({
                type: 'site/setNotification',
                payload: {
                    notification: 'Updated VisualImpairedSolution',
                    theme: 'green'
                }
            })

        } else {
            yield put({type: 'place/updateVisualImpairedSolutionFailure', payload: updated})
        }
    } catch (error) {
        yield put({type: 'place/updateVisualImpairedSolutionFailure'})
    }
}


export function* watchCreateVisualImpairedSolution() {
    yield takeEvery('place/createVisualImpairedSolution', createVisualImpairedSolution)
}

export function* watchGetVisualImpairedSolutionList() {
    yield takeEvery('place/listVisualImpairedSolution', listVisualImpairedSolution)
}

export function* watchGetVisualImpairedSolutionDetail() {
    yield takeEvery('place/getVisualImpairedSolution', getVisualImpairedSolutionDetail)
}

export function* watchUpdateVisualImpairedSolutionDetail() {
    yield takeEvery('place/updateVisualImpairedSolution', updateVisualImpairedSolutionDetail)
}

