import {
    ADDRESS,
    ALGOLIA_SEARCH,
    AUDIO_UPLOAD,
    COUNT,
    COUNTRY,
    DATE,
    EMAIL,
    FILTER,
    IMAGE_UPLOAD,
    LIKERT,
    MULTI_SELECT,
    NUMBER,
    PASSWORD,
    REGION,
    RICH_TEXT,
    SEARCH_BAR,
    SELECT, SERVICE_AREA,
    SINGLE_SELECT,
    STATE_CITY,
    SUBMITTED_BY,
    TEL,
    TEXT,
    TEXTAREA,
    TOGGLE,
    FILE,
    HOURS_OPEN
} from 'config/variables'
import {memo}         from 'react'
import UploadAudio    from 'shared/Fields/UploadAudio'
import UploadFile    from 'shared/Fields/UploadFile'

import UploadImage    from 'shared/Fields/UploadImage'
import Tooltip        from '../Controls/ToolTip'
import Address        from './Address'
import AlgoliaSearch  from './AlgoliaSearch'
import Count          from './Count'
import Country        from './Country'
import Likert         from './Likert'
import MultiSelect    from './MultiSelect'
import Region         from './Region'
import RichTextEditor from './RichTextEditor'
import SearchBar      from './SearchBar'
import Select         from './Select'
import ServiceArea    from './ServiceArea'
import SingleSelect   from './SingleSelect'
import SmartInput     from './SmartInput'
import StateCity      from './StateCity'
import SubmittedBy    from './SubmittedBy'
import TextArea       from './TextArea'
import Toggle         from './Toggle'
import HoursOpen from './HoursOpen'

const FieldSwitch = memo(({field, formik, algoliaSearchOptions, options, theme, autoSubmit}) => {
    switch (field.type) {
        case ADDRESS:
            return <Address
                name={field.name}
                formik={formik}
            />
        case FILE:
                return <UploadFile
                    formik={formik}
                    id={field.name}
                    file={field.file}
                    cropWidth={field.cropWidth}
                    cropHeight={field.cropHeight}
                    s3Path={field.s3Path}
                    inputLabel={field.inputLabel}
                    className={formik.touched[field.name] && formik.errors[field.name] ? 'error' : ''}
                    errorMessage={formik.touched[field.name] && formik.errors[field.name] ? formik.errors[field.name] : null}
                />    
        case AUDIO_UPLOAD:
            return <UploadAudio
                formik={formik}
                id={field.name}
                file={field.file}
                cropWidth={field.cropWidth}
                cropHeight={field.cropHeight}
                s3Path={field.s3Path}
                inputLabel={field.inputLabel}
                className={formik.touched[field.name] && formik.errors[field.name] ? 'error' : ''}
                errorMessage={formik.touched[field.name] && formik.errors[field.name] ? formik.errors[field.name] : null}
            />
        case COUNT:
            return <Count
                name={field.name}
                formik={formik}
            />
        case COUNTRY:
            return <Country
                name={field.name}
                formik={formik}
            />
        case DATE:
        case EMAIL:
        case FILTER:
        case NUMBER:
        case PASSWORD:
        case TEL:
        case TEXT:
            return <SmartInput
                {...formik.getFieldProps(field.name)}
                className={formik.touched[field.name] && formik.errors[field.name] ? 'error' : ''}
                disabled={field.disabled}
                hidden={field.hidden}
                errorMessage={formik.touched[field.name] && formik.errors[field.name] ? formik.errors[field.name] : null}
                formik={formik}
                id={field.name}
                inputLabel={field.inputLabel}
                type={field.type}
                theme={theme}
                autoSubmit={autoSubmit}
                autoFocus={field.autoFocus}
            />
        case IMAGE_UPLOAD:
            return <UploadImage
                formik={formik}
                id={field.name}
                file={field.file}
                cropWidth={field.cropWidth}
                cropHeight={field.cropHeight}
                aspect={field.aspect}
                s3Path={field.s3Path}
                multiple={field.multiple}
                inputLabel={field.inputLabel}
                className={formik.touched[field.name] && formik.errors[field.name] ? 'error' : ''}
                errorMessage={formik.touched[field.name] && formik.errors[field.name] ? formik.errors[field.name] : null}
            />
        case LIKERT:
            return <Likert
                {...formik.getFieldProps(field.name)}
                formik={formik}
                field={field}
                options={options}
                helperText={field.helperText}
                className={formik.touched[field.name] && formik.errors[field.name] ? 'error' : ''}
                errorMessage={formik.touched[field.name] && formik.errors[field.name] ? formik.errors[field.name] : null}
                theme={theme}
            />
        case MULTI_SELECT:
            return <MultiSelect
                {...formik.getFieldProps(field.name)}
                name={field.name}
                formik={formik}
                field={field}
                options={options}
                className={formik.touched[field.name] && formik.errors[field.name] ? 'error' : ''}
                errorMessage={formik.touched[field.name] && formik.errors[field.name] ? formik.errors[field.name] : null}
                theme={theme}
            />
        case ALGOLIA_SEARCH:
            return <AlgoliaSearch
                {...formik.getFieldProps(field.name)}
                name={field.name}
                formik={formik}
                field={field}
                options={options}
                algoliaSearchOptions={algoliaSearchOptions}
                className={formik.touched[field.name] && formik.errors[field.name] ? 'error' : ''}
                errorMessage={formik.touched[field.name] && formik.errors[field.name] ? formik.errors[field.name] : null}
                theme={theme}
            />
        case SINGLE_SELECT:
            return <SingleSelect
                {...formik.getFieldProps(field.name)}
                name={field.name}
                formik={formik}
                field={field}
                options={options}
                className={formik.touched[field.name] && formik.errors[field.name] ? 'error' : ''}
                errorMessage={formik.touched[field.name] && formik.errors[field.name] ? formik.errors[field.name] : null}
                theme={theme}
            />
        case STATE_CITY:
            return <StateCity
                {...formik.getFieldProps(field.name)}
                name={field.name}
                formik={formik}
                field={field}
                options={options}
                theme={theme}
            />

        case REGION:
            return <Region
                name={field.name}
                formik={formik}
            />
        case RICH_TEXT:
            return <RichTextEditor
                formik={formik}
                name={field.name}
                label={field.inputLabel}
                className={formik.touched[field.name] && formik.errors[field.name] ? 'error' : ''}
                errorMessage={formik.touched[field.name] && formik.errors[field.name] ? formik.errors[field.name] : null}
                tooltip={!!field.tooltip ? <Tooltip message={field.tooltip}/> : null}
            />
        case SELECT:
            return <Select
                {...formik.getFieldProps(field.name)}
                field={field}
                options={options}
                className={formik.touched[field.name] && formik.errors[field.name] ? 'error' : ''}
                errorMessage={formik.touched[field.name] && formik.errors[field.name] ? formik.errors[field.name] : null}
            />
        case SUBMITTED_BY:
            return <SubmittedBy
                {...formik.getFieldProps(field.name)}
                name={field.name}
                formik={formik}
                inputLabel={field.inputLabel}
            />
        case TEXTAREA:
            return <TextArea
                {...formik.getFieldProps(field.name)}
                formik={formik}
                field={field}
                className={formik.touched[field.name] && formik.errors[field.name] ? 'error' : ''}
                errorMessage={formik.touched[field.name] && formik.errors[field.name] ? formik.errors[field.name] : null}
                theme={theme}
            />
        case TOGGLE:
            return <Toggle
                name={field.name}
                formik={formik}
                inputLabel={field.inputLabel}
                inputLabelHelper={field.inputLabelHelper}
                errorMessage={formik.errors[field.name] ? formik.errors[field.name] : null}
            />
        case SEARCH_BAR:
            return <SearchBar
                {...formik.getFieldProps(field.name)}
                className={formik.touched[field.name] && formik.errors[field.name] ? 'error' : ''}
                errorMessage={formik.touched[field.name] && formik.errors[field.name] ? formik.errors[field.name] : null}
                formik={formik}
                id={field.name}
                inputLabel={field.inputLabel}
                type={field.type}
                theme={theme}
                autoSubmit={autoSubmit}
                autoFocus={field.autoFocus}
            />
        case SERVICE_AREA:
            return <ServiceArea
                {...formik.getFieldProps(field.name)}
                className={formik.touched[field.name] && formik.errors[field.name] ? 'error' : ''}
                errorMessage={formik.touched[field.name] && formik.errors[field.name] ? formik.errors[field.name] : null}
                formik={formik}
                id={field.name}
                inputLabel={field.inputLabel}
                type={field.type}
                theme={theme}
                autoSubmit={autoSubmit}
                autoFocus={field.autoFocus}
            />
        case HOURS_OPEN:
            return <HoursOpen 
            {...formik.getFieldProps(field.name)}
            name={field.name}
            formik={formik}
            field={field}
            theme={theme}
            />
        default:
            return null
    }
})

export default FieldSwitch
