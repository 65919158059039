import {addCreateReportToUserHistory}   from 'features/user/services'
import {call, put, takeLatest}           from 'redux-saga/effects'
import {createReportEntity}                    from 'utils/abstractions/crud'
import {setFormData}                     from 'utils/abstractions/setFormData'

export function* submitCreateReport({payload}) {
    console.log(payload)
    const {
        _id,
        token,
        longitude,
        latitude,
        isPendingSubmission,
        name,
        submittedBy,
        placeId
    } = payload

    //add to formdata so api can read
    const place = new FormData()
    const fields = [
        {longitude},
        {latitude},
        {isPendingSubmission},
        {name},
        {submittedBy},
        {placeId}
    ]

    for (let field of fields)
        setFormData(place, field)


    const submission = yield call(createReportEntity, {
        slug: 'report',
        body: payload,
        _id: _id,
        token: token,
    })
    if (submission.error) {
        if(submission.error === 'Submission successful, but no reviews found'){
            yield put({type: 'user/createReportFailure', payload})
            yield put ({
                type: 'site/setNotification',
                payload: {
                    notification: 'No reviews found!',
                    theme: 'red'
                }
            })}
            else{
        yield put({
            type: 'user/submitReportCreateFailure',
            payload: {
                _id,
                token,
                submissionId: submission._id,
                error: submission.error
            }
        })
    }
    }

    if (!submission.error) {
        yield put({
            type: 'user/createReportSuccess',
            payload: {
                _id,
                token,
                submissionId: submission._id
            }
        })
        yield put({
            type: 'site/setNotification',
            payload: {
                notification: 'Successful Submission!',
                theme: 'green'
            }
        })

    }

    else {
       
        yield put({type: 'user/createReportFailure', payload})
        if(submission.error === 'Submission successful, but no reviews found'){
            yield put ({
                type: 'site/setNotification',
                payload: {
                    notification: 'No reviews found!',
                    theme: 'red'
                }
            })}
   /*      yield put({
            type: 'site/setNotification',
            payload: {
                notification: 'Create report failed!',
                theme: 'red'
            }
        }) */
    }
}

export function* submitCreateReportSuccess({payload}) {
    const {_id, token, submissionId} = payload

    const place = new FormData()
    const fields = [
        {submissionId}
    ]

    for (let field of fields)
        setFormData(place, field)


    const submission = yield call(addCreateReportToUserHistory, {
        _id,
        token,
        place
    })

    if (!submission.error) {
        //TODO: clear form
        yield put({
            type: 'user/getUserById',
            payload: {
                userId: _id,
                _id: _id,
                token: token
            }
        })
    }
}

export function* submitCreateReportFailure({payload}) {
    yield put({
        type: 'site/setNotification',
        payload: {
            notification: 'Report Submission Failed!'+ payload.error,
            theme: 'red'
        }
})
}



export function* watchSubmitCreateReport() {
    yield takeLatest('user/submitCreateReport', submitCreateReport)
}

/* export function* watchSubmitCreateReport() {
    yield takeLatest('user/createReport', submitCreateReport)
} */
export function* watchSubmitCreateReportSuccess() {
    yield takeLatest('user/submitCreateReportSuccess', submitCreateReportSuccess)
}

export function* watchSubmitCreateReportFailure() {
    yield takeLatest('user/submitCreateReportFailure', submitCreateReportFailure)
}
