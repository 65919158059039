import {camera}                    from 'config/icons'
import {ACCEPTABLE_EXTENSIONS}     from 'config/variables'
import moment                      from 'moment'
import {memo, useState} from 'react'
import Dropzone                    from 'react-dropzone'
import 'react-image-crop/dist/ReactCrop.css'
import {useSelector}               from 'react-redux'
import Div                         from 'shared/Basic/Div'
import Icon                        from 'shared/Basic/Icon'
import Img                         from 'shared/Basic/Img'
import Span                        from 'shared/Basic/Span'
import {uploadErrorMessageStyle}   from 'shared/Fields/styles'
import {slugify}                   from 'utils/helpers'
import CurrentProfileImage         from '../../features/user/admin/views/CurrentProfileImage'
import CropPortal                  from './CropPortal'
import {
    defaultFieldHeadingStyle,
    imageDropZonePreviewStyle,
    imageDropZonePreviewMoreStyle,
    imageDropZonePreviewWrapperStyle,
    imageDropZoneStyle,
    imageDropZoneWrapperStyle
}                                  from './styles'

const UploadImage = memo(({
                              formik,
                              id,
                              file,
                              cropWidth,
                              cropHeight,
                              s3Path,
                              multiple = false,
                              inputLabel,
                              className,
                              aspect = 1,
                              errorMessage
                          }) => {
    const [cropPortalOpen, setCropPortalOpen] = useState(false)
    const [uploadedImages, setUploadedImages] = useState([])
    const {email} = useSelector(state => state.user)
    const [currentImage, setCurrentImage] = useState(formik.initialValues[id])

    const makeClientCrop = async (crop, percentageCrop, index) => {
        const {uploadBlob} = uploadedImages[index]
        if (uploadBlob && crop.width && crop.height) {
            let htmlImg = new Image()
            htmlImg.src = uploadBlob
            createCropPreview(htmlImg, percentageCrop, index)
        }
    }

    const createCropPreview = async (image, crop, index) => {
        const canvas = document.createElement('canvas')
        const ctx = canvas.getContext('2d')
        const naturalWidth = image.naturalWidth
        const naturalHeight = image.naturalHeight
        canvas.width = cropWidth
        canvas.height = cropHeight
        ctx.drawImage(
            image,
            Math.round((crop.x / 100) * naturalWidth),
            Math.round((crop.y / 100) * naturalHeight),
            Math.round((crop.width / 100) * naturalWidth),
            Math.round((crop.height / 100) * naturalHeight),
            0,
            0,
            canvas.width,
            canvas.height
        )

        return new Promise((resolve, reject) => {
            canvas.toBlob(blob => {
                if (!blob) {
                    reject(new Error('Canvas is empty'))
                    return
                }
                const {uploadType, sanitizedName} = uploadedImages[index]
                blob.name = sanitizedName
                uploadedImages[index].previewBlob = window.URL.createObjectURL(blob)
                uploadedImages[index].croppedImage = new File([blob], sanitizedName, {
                    type: uploadType
                })
                const clonedUploadedImages = [...uploadedImages]
                setUploadedImages(clonedUploadedImages)
            }, 'image/jpeg')
        })
    }

    const handleAcceptedFile = acceptedFiles => {
        //set state for uploaded file blob and type
        const images = acceptedFiles.map((acceptedFile) => {
            const sanitizedName = s3Path + slugify(acceptedFile.name + '_' + moment().format())
            const uploadType = acceptedFile.type
            const uploadBlob = URL.createObjectURL(acceptedFile);
            const previewBlob = uploadBlob.slice()
            const croppedImage = new File([uploadBlob], sanitizedName, {
                type: uploadType
            });
            const crop = { width: cropWidth, aspect }
            return { uploadBlob, previewBlob, uploadType, sanitizedName, croppedImage, crop }
        });
        setUploadedImages(images)

        //open crop portal
        setCropPortalOpen(true)
    }

    const onCropModalClose = () => {
        const croppedImages = uploadedImages.map(uploadedImage => uploadedImage.croppedImage)
        const sanitizedNames = uploadedImages.map(uploadedImage => uploadedImage.sanitizedName)
        if (multiple) {
            formik.setFieldValue(file, [...croppedImages])
            formik.setFieldValue(id, [...sanitizedNames])
        } else if (croppedImages.length && sanitizedNames.length) {
            formik.setFieldValue(file, croppedImages[0])
            formik.setFieldValue(id, sanitizedNames[0])
        }
    }

    return (
        <>
            <Div theme={defaultFieldHeadingStyle}>{inputLabel}</Div>

            <Div theme={imageDropZoneWrapperStyle} className={className ? className : ''}>
                <Dropzone
                    id={id}
                    accept={ACCEPTABLE_EXTENSIONS}
                    maxFiles={multiple ? undefined : 1}
                    multiple={multiple}
                    onDropAccepted={(acceptedFiles) => handleAcceptedFile(acceptedFiles)}
                    onDropRejected={() => console.log('error')}
                >
                    {({getRootProps, getInputProps}) => (
                        <Div {...getRootProps()} theme={imageDropZoneStyle}>
                            <Span theme={uploadErrorMessageStyle}>{errorMessage}</Span>
                            <input {...getInputProps()} />
                            <Icon icon={camera} theme={imageDropZoneStyle.icon}/>
                        </Div>
                    )}
                </Dropzone>
                <Div theme={imageDropZonePreviewWrapperStyle}>
                    {(uploadedImages && uploadedImages.length > 0 &&
                        <>
                            <Img
                                alt="Crop preview"
                                src={uploadedImages[0].previewBlob}
                                theme={imageDropZonePreviewStyle}
                            />
                            {uploadedImages.length > 1 && <Div theme={imageDropZonePreviewMoreStyle}>+{uploadedImages.length - 1}</Div>}
                        </>
                    ) || ((Object.keys(formik.values).indexOf('avatar') > -1) && (
                        <CurrentProfileImage
                            email={email}
                            formik={formik}
                            currentImage={currentImage}
                            setCurrentImage={setCurrentImage}
                        />
                    ))}
                </Div>
            </Div>
            <CropPortal
                isOpen={cropPortalOpen}
                onCropModalClose={onCropModalClose}
                uploadedImages={uploadedImages}
                setUploadedImages={setUploadedImages}
                setIsOpen={setCropPortalOpen}
                makeClientCrop={makeClientCrop}
            />
        </>
    )
})

export default UploadImage
