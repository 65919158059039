import MapboxGeocoder                                                                  from '@mapbox/mapbox-gl-geocoder'
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css'
import {MAPBOX_PUBLIC, MAPBOX_SECRET}                                                  from 'config/variables'
import {useEffect, useRef}                                                             from 'react'
import {debounce}                                                                      from '../../utils/helpers'
import Div                                                                             from '../Basic/Div'
import H3                                                                              from '../Basic/H3'
import {defaultFieldHeadingStyle, searchBarZipcodeInputStyle, serviceAreaWrapperStyle} from './styles'

const mapboxGeo = require('@mapbox/mapbox-sdk/services/geocoding');
const geocodingClient = mapboxGeo({accessToken: MAPBOX_PUBLIC});

const ServiceArea = ({
                         formik,
                         inputLabel,
                     }) => {

    const geoCoderRef = useRef()
    const customizeGeocoderUI = () => {
        if (geoCoderRef.current) {
            const svg = geoCoderRef.current.querySelector('svg')
            if (svg) {
                svg.innerHTML = `
          <path fill-rule="evenodd" clip-rule="evenodd" d="M0.291687 10.5116C0.291687 4.86781 4.85627 0.303223 10.5 0.303223C16.1438 0.303223 20.7084 4.86781 20.7084 10.5116C20.7084 16.5928 14.2625 24.9782 11.6229 28.172C11.0396 28.872 9.97502 28.872 9.39169 28.172C6.73752 24.9782 0.291687 16.5928 0.291687 10.5116ZM6.85419 10.5116C6.85419 12.5241 8.48752 14.1574 10.5 14.1574C12.5125 14.1574 14.1459 12.5241 14.1459 10.5116C14.1459 8.49906 12.5125 6.86572 10.5 6.86572C8.48752 6.86572 6.85419 8.49906 6.85419 10.5116Z" fill="black" fill-opacity="0.54"/>
        `
                svg.setAttribute('viewBox', '0 0 21 32')
            }
            const input = geoCoderRef.current.querySelector('input')
            if (input) {
                input.placeholder = 'Search for City, state, or zip code'
            }
        }
    }

    const setDefaultLocation = () => {
        if (formik.values.latitude?.length > 0 && formik.values.longitude?.length > 0) {
            const lat = parseFloat(formik?.values?.latitude)
            const lon = parseFloat(formik?.values?.longitude)

            geocodingClient.reverseGeocode({
                query: [lon, lat],
                mode: 'mapbox.places',
            })
                .send()
                .then(response => {
                    const match = response.body;
                    const zip = match?.features?.[0].context?.[1].text
                    const input = geoCoderRef.current.querySelector('input')
                    input.value = zip
                })
        }
    }
    const setLonLatWithGeocode = () => {
        const geocoder = new MapboxGeocoder({
            accessToken: MAPBOX_SECRET,
            types: 'country,region,place,postcode,locality,neighborhood',
        })

        geocoder.addTo(geoCoderRef.current)
        customizeGeocoderUI()

        geocoder.on('result', (e) => {
            const {geometry} = e.result

            if (geometry && geometry.coordinates) {
                const [lon, lat] = geometry.coordinates
                formik.setFieldValue('longitude', lon)
                formik.setFieldValue('latitude', lat)
                formik.submitForm() // maybe not autosubmit?
            }
        })
    }

    useEffect(() => {
        if (geoCoderRef.current) {
            setLonLatWithGeocode()
        }

        debounce(setDefaultLocation(), 5000)


        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <Div theme={serviceAreaWrapperStyle}>
            <H3 theme={defaultFieldHeadingStyle}>
                {inputLabel}
            </H3>
            <Div ref={geoCoderRef} theme={searchBarZipcodeInputStyle}/>

        </Div>
    )
}

export default ServiceArea
