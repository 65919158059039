import {resourceFields, validateReview} from 'features/place/admin/fields/resource'
import {
    reviewFormHeadingStyle,
    reviewFormStyle,
    reviewFormWrapperStyle,
    reviewLeaveWrapperStyle
}                                     from 'features/place/views/styles'
import { useEffect } from 'react'
import { useSelector,useDispatch }                  from 'react-redux'
import Div                            from 'shared/Basic/Div'
import H2                             from 'shared/Basic/H2'
import Form                           from 'shared/Fields/Form'
import { useContext } from 'react'
import {menuPanelContext}            from 'shared/Containers/MenuPanelController'


const UpdateResourceForm = () => {
    const {currentPanel, setPanel} = useContext(menuPanelContext)
    const dispatch = useDispatch()
    const {_id, token, nameFirst, email, avatar} = useSelector(state => state.user)
    const {slug} = useSelector(state => state.site)
    const {place, resource, currentEditResourceSlug, updateResourceSuccess, subjectsList, categoryList } = useSelector(state => state.place)
    const {
        description,
        // _id,
        title,
        document,
        videolink,
        user,
        createdAt,
        updated,
        status,
        subjects,
        category
    } = resource
    

    useEffect(() => {
        if(!!currentEditResourceSlug){
            dispatch({
                type: 'report/getResource',
                payload: {
                   slug: currentEditResourceSlug
                }
               })
        }
    },[])

    const options = [
        {
            name: 'subjects',
            options: subjectsList
        },
        {
            name: 'category',
            options: categoryList
        }
    ]

    const initialValues = {
        _id: currentEditResourceSlug,
        title,
        description,
        document: '',
        videolink,
        user: _id,
        token,
        subjects,
        category  
    }


    return (
        <Div theme={reviewFormWrapperStyle} className='leave_a_review_sec'>
            <Div theme={reviewLeaveWrapperStyle}>
                <H2 theme={reviewFormHeadingStyle}>
                    <Div>Edit Resource</Div>
                </H2>
            </Div>
            <Form
                initialValues={initialValues}
                fields={resourceFields}
                validationSchema={validateReview}
                dispatchAction={'place/updateResource'}
                buttonText={'Update'}
                theme={reviewFormStyle}
                enableReinitialize={true}
                options={options}
            />
        </Div>
    )
}

export default UpdateResourceForm
