import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { CreditCard, PaymentForm } from 'react-square-web-payments-sdk';

const SquarePaymentForm = () => {
    const dispatch = useDispatch();
    const { _id, token, nameFirst, paymentLoader } = useSelector(state => state.user);

    // State for address fields
    const [address, setAddress] = useState({
        street: '',
        city: '',
        state: '',
        zip: '',
        country: 'US'
    });

    const isValidZip = (zip) => /^[0-9]{5}(-[0-9]{4})?$/.test(zip);
    const [validationMsg, setValidationMsg] = useState('');

    // Handle change for address input fields
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setAddress(prevState => ({ ...prevState, [name]: value }));

           // Basic ZIP code validation
    if (name === 'zip') {
        if (!isValidZip(value)) {
            setValidationMsg('Please enter a valid ZIP code.');
            return;
        }else{
            setValidationMsg('');

        }
    }

    };

    return (
        <>
        { paymentLoader && <div className='loader'></div> }
        {  !paymentLoader && <PaymentForm
            applicationId='sandbox-sq0idb-z1Tk9PWZFUb1wncZdhNDPA'
            //applicationId="sq0idp-C3gm7iPY_JmC5N6aREUkVA"
            cardTokenizeResponseReceived={(ptoken, buyer) => {
                if (ptoken.status === "OK" && ptoken.details !== null) {

                    dispatch({
                        type: 'user/postUserPayment',
                        payload: {
                            _id: _id,
                            token: token,
                            nonce: ptoken.token,
                            buyer: buyer,
                            amount: 20,
                            address: address   // Include the address in the payload
                        }
                    });
                }
            }}
            createVerificationDetails={() => ({
                amount: '20.00',
                billingContact: {
                    familyName: '',
                    givenName: nameFirst,
                    countryCode: 'US'
                },
                currencyCode: 'USD',
                intent: 'CHARGE',
            })}
            locationId='LPH3E4MPWK160'
           // locationId="LH0QHPC1CSKW3"
        >
           <div className='paymentAddess'>
            <h4>Mailing address </h4>
             <input type="text" placeholder="Street Address" name="street" value={address.street} onChange={handleInputChange} />
            <input type="text" className='col33' placeholder="City" name="city" value={address.city} onChange={handleInputChange} />
            <input type="text" className='col33' placeholder="State" name="state" value={address.state} onChange={handleInputChange} />
            <input type="text" className='col33' placeholder="Zip Code" name="zip" value={address.zip} onChange={handleInputChange} />
           
            {validationMsg && <span style={{color: "#ff0000",fontSize:'11px'}} className='error'>{validationMsg}</span>}

            </div>
            <CreditCard />
            <p className='paymentNote'>By clicking on the 'Pay' button, you agree to our <a target='_blank' href='https://inclusive-guide.s3.us-east-2.amazonaws.com/assets/InclusiveGuide-TermsOfService-01.18.21.pdf'>Terms & Conditions.</a> You will be charged monthly until you cancel the plan.</p>
        </PaymentForm>
        }
        </>
        
    );
}

export default SquarePaymentForm;
