import {API} from 'config/variables'

export const updateEntity = ({slug, parentSlug, body, _id, token}) =>
    fetch(`${API}/${parentSlug}/${slug}/${_id}`, {
        method: 'PUT',
        headers: {
            Accept: 'application/json',
            Authorization: `Bearer ${token}`
        },
        body: body
    })
        .then(response => {
            return response.json()
        })
        .catch(error => {
            return error
        })

export const createEntity = ({_id, token, body, slug}) =>
    fetch(`${API}/${slug}/create/${_id}`, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            Authorization: `Bearer ${token}`
        },
        body: body
    })
        .then(response => {
            return response.json()
        })
        .catch(error => {
            return error
        })

export const submitEntity = ({_id, token, body, slug}) =>
    fetch(`${API}/${slug}/submit/${_id}`, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            Authorization: `Bearer ${token}`
        },
        body: body
    })
        .then(response => {
            return response.json()
        })
        .catch(error => {
            return error
        })
export const createReportEntity = ({_id, token, body, slug}) =>
   fetch(`${API}/${slug}/create/xlsx/${_id}/place/${body.placeId}`, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(body)
    })
        .then(response => {
            return response.json()
        })
        .catch(error => {
            return error
        })

export const getEntityById = ({entityId, path}) =>
    fetch(`${API}/${path}/by/id/${entityId}`, {
        method: 'GET'
    })
        .then(response => {
            return response.json()
        })
        .catch(error => {
            return error
        })

export const getEntitiesByIds = ({ids, path}) =>
    fetch(`${API}/${path}/by/ids`, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({ ids })
    })
        .then(response => {
            return response.json()
        })
        .catch(error => {
            return error
        })


export const listEntities = ({entityId, path,filters}) =>
    fetch(`${API}/${path}?${filters}`, {
        method: 'GET'
    })
        .then(response => {
            return response.json()
        })
        .catch(error => {
            return error
        })



export const createResourceEntity = ({token, body, slug}) =>
    fetch(`${API}/${slug}/create`, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            Authorization: `Bearer ${token}`
        },
        body: body
    })
        .then(response => {
            return response.json()
        })
        .catch(error => {
            return error
        })


//updateResourceEntity
export const updateResourceEntity = ({token, body, slug}) =>
    fetch(`${API}/${slug}/update`, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            Authorization: `Bearer ${token}`
        },
        body: body
    })
        .then(response => {
            return response.json()
        })
        .catch(error => {
            return error
        })


// delete resource entity
export const deleteResourceEntity = ({token, body, slug}) => 
    fetch(`${API}/${slug}/delete`, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            Authorization: `Bearer ${token}`
        },
        body: body
    })
        .then(response => {
            return response.json()
        })
        .catch(error => {
            return error
        })


// getReviewsByPlaceId
export const getReviewsByPlaceId = ({ token, placeId}) => 
 fetch(`${API}/reviews/by/${placeId}`, {
        method: 'GET'
    })
        .then(response => {
            return response.json()
        })
        .catch(error => {
            return error
        })
