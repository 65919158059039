import {faInstagram, faTwitter} from '@fortawesome/free-brands-svg-icons'
import {
    faArrowLeft,
    faArrowRight,
    faBackward,
    faBookmark,
    faBuilding,
    faCamera,
    faCaretDown,
    faCaretUp,
    faCheck,
    faChevronDown,
    faCogs,
    faComments,
    faDollarSign,
    faDoorOpen,
    faEllipsisH,
    faEllipsisV,
    faExclamationTriangle,
    faFile,
    faFileAudio,
    faFlag,
    faForward,
    faGlobe,
    faHandHoldingHeart,
    faHome,
    faList,
    faMapMarkerAlt,
    faMinusCircle,
    faMusic,
    faNewspaper,
    faPause,
    faPlay,
    faPlayCircle,
    faPlusCircle,
    faReceipt,
    faSave,
    faSearch,
    faSearchLocation,
    faShoppingBag,
    faSignOutAlt,
    faSpa,
    faStar,
    faStepBackward,
    faStepForward,
    faStore,
    faStream,
    faTags, faTimes,
    faTimesCircle,
    faTrashAlt,
    faUser,
    faUserCog,
    faUsersCog,
    faVolumeMute,
    faVolumeUp,
    faWindowMaximize,
    faWindowMinimize
} from '@fortawesome/free-solid-svg-icons'

import {
    faFilter,
    faInfoCircle,
    faCoffee,
    faChevronCircleDown,
    faChevronCircleUp,
    faGasPump,
    faMapMarkerAlt as faMapMarkerAltSolid,
    faPlus,
    faTree,
    faShoppingCart,
    faTimesCircle as faTimesCircleSolid,
    faUtensils,
} from '@fortawesome/free-solid-svg-icons'

export const
    arrowLeft = faArrowLeft,
    arrowRight = faArrowRight,
    bookmark = faBookmark,
    building = faBuilding,
    cart = faShoppingCart,
    camera = faCamera,
    cogs = faCogs,
    coffee = faCoffee,
    caretDown = faCaretDown,
    check = faCheck,
    chevronDown = faChevronDown,
    chevronCircleDown = faChevronCircleDown,
    chevronCircleUp = faChevronCircleUp,
    comments = faComments,
    caretUp = faCaretUp,
    doorOpen = faDoorOpen,
    ellipsis = faEllipsisH,
    ellipsisV = faEllipsisV,
    exclamationTriangle = faExclamationTriangle,
    usd = faDollarSign,
    fileAudio = faFileAudio,
    fileIcon = faFile,
    filter = faFilter,
    forward = faForward,
    flag = faFlag,
    globe = faGlobe,
    gas = faGasPump,
    backward = faBackward,
    handHoldingHeart = faHandHoldingHeart,
    home = faHome,
    infoCircle = faInfoCircle,
    list = faList,
    mapMarker = faMapMarkerAlt,
    mapMarkerSolid = faMapMarkerAltSolid,
    minusCircle = faMinusCircle,
    music = faMusic,
    newsPaper = faNewspaper,
    pause = faPause,
    plus = faPlus,
    plusCircle = faPlusCircle,
    receipt = faReceipt,
    save = faSave,
    searchLocation = faSearchLocation,
    search = faSearch,
    shoppingBag = faShoppingBag,
    shoppingCart = faShoppingCart,
    signOut = faSignOutAlt,
    spa = faSpa,
    star = faStar,
    store = faStore,
    stream = faStream,
    stepBackward = faStepBackward,
    stepForward = faStepForward,
    play = faPlay,
    playCircle = faPlayCircle,
    tags = faTags,
    times = faTimes,
    timesCircle = faTimesCircle,
    timesCircleSolid = faTimesCircleSolid,
    trashAlt = faTrashAlt,
    tree = faTree,
    twitter = faTwitter,
    instagram = faInstagram,
    user = faUser,
    users = faUsersCog,
    userCog = faUserCog,
    windowMinimize = faWindowMinimize,
    windowMaximize = faWindowMaximize,
    utensils = faUtensils,
    volumeMute = faVolumeMute,
    volumeUp = faVolumeUp
