import {colorPalette, globals}                                   from 'config/styles'
import {center, column, flex, flexStart, hidden, pointer, white} from 'utils/themer'

export const optionStyle = isSelected => {
    return {
        background: isSelected ? colorPalette.darkHoneyYellow : '#e4e4e4',
        alignSelf: flexStart,
        padding: '5px 25px',
        borderRadius: 5,
        marginRight: [10, globals.style.layoutScalingValue, 10],
        marginBottom: [10, globals.style.layoutScalingValue, 10],
        border: `1px solid ${isSelected ? colorPalette.darkHoneyYellow : globals.colors.borderColor}`,
        color: isSelected ? '#fff' : '#77787b',
        size: [14, .7, 14],
        weight: 500,
        hover: {
            backgroundColor: colorPalette.honeyYellow,
            color: white,
            border: `1px solid ${colorPalette.honeyYellow}`,
            cursor: pointer
        }
    }
}

export const multiSelectSearchFormStyle = {
    border: 0,
    padding: 0,
    fieldset: {
        marginBottom: [20, .7, 20]
    }
}

export const searchResultsWrapperStyle = {
    display: flex,
    flexDirection: column,
    marginTop: [30, .7, 30]
}

export const searchResultWrapperStyle = {
    display: flex,
    marginBottom: [20, .7, 20],
    background: '#e4e4e4',
    padding: [10, .7, 10],
    borderRadius: [5, .7, 5],
    hover: {
        cursor: pointer,
        backgroundColor: colorPalette.honeyYellow,
        color: white
    }
}

export const searchResultAvatarStyle = {
    display: flex,
    height: [100, .7, 100],
    width: [100, .7, 100],
    minWidth: [100, .7, 100],
    borderRadius: [50, .7, 50],
    overflown: hidden,
}

export const searchResultInfoStyle = {
    display: flex,
    flexDirection: column,
    justifyContent: center,
    paddingLeft: [20, .7, 20],
    size: [17, .7, 17]
}
