import {search}                                          from 'config/icons'
import {mobileFlag}                                      from 'features/site/slice'
import {useContext}                                      from 'react'
import {useSelector}                                     from 'react-redux'
import Div                                               from 'shared/Basic/Div'
import Icon                                              from 'shared/Basic/Icon'
import {menuPanelContext}                                from 'shared/Containers/MenuPanelController'
import {headerSearchIconStyle, headerSearchWrapperStyle} from 'shared/Layout/styles/header'
import MenuPanels                                        from 'shared/Menus/MenuPanel'
import HeaderMenuControls                                from './HeaderMenuControls'
import MenuToggle                                        from './MenuToggle'
import {headerMenuStyle, menuToggleStyle}                from './styles'

const HeaderMenu = () => {
    const {setPanel, currentPanel} = useContext(menuPanelContext)
    const isMobile = useSelector(mobileFlag)

    return (
        <Div theme={headerMenuStyle} className="header_menu">
            {(!isMobile && (
                <HeaderMenuControls/>
            )) || (
                <>
                    <MenuToggle
                        theme={menuToggleStyle}
                        onClick={
                            () => setPanel(
                                !currentPanel
                                    ? 'mobile-header-menu-panel'
                                    : null
                            )}
                    />
                    <Div
                        theme={headerSearchWrapperStyle}
                        onClick={
                            () => setPanel(
                                !currentPanel
                                    ? 'search-menu'
                                    : null
                            )}
                    >
                        <Icon
                            icon={search}
                            theme={headerSearchIconStyle}
                        />
                    </Div>
                </>
            )}
            <MenuPanels/>
        </Div>
    )
}

export default HeaderMenu
