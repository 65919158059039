/** API KEYS **/

export const API = "https://inclusiveguide.com/api"
//export const API = "http://localhost:9000/api"
//export const API = "http://192.168.100.108:9000/api"
export const CDN = "https://d23tf5onpu500n.cloudfront.net/"
export const CORS = "https://cors-anywhere.inclusiveguide.com/"
export const BOONE_HOST = "https://api.places.togogroup.io/"
export const BOONE_CLIENT_ID = "d80f0ff47127439dacaddd81928d7db1"
export const BOONE_CLIENT_SECRET = "ed40c4296342defaf295a5781ad5e13caf0a6090b91b13100b9bf1e4a604a533"
export const BOONE_API = "api/v3/"
export const ALGOLIA_APP_ID = "8EX4HV0QUR"
export const ALGOLIA_SEARCH_API_KEY = "3730fcb32ba59d3ee54125a8659782e1"
export const ALGOLIA_ADMIN_KEY = "b2772d19465c740d90681c83988eae9b"
export const GOOGLE_PLACES_API_KEY = "sk.eyJ1IjoiaW5jbHVzaXZlZ3VpZGUiLCJhIjoiY2ttanBpdzkzMHRpZzJvcW1mdHNyM2w1NiJ9.QvEGPD7da32YjoFMfYCJ-Q"
export const GOOGLE_ANALYTICS_ID = "G-F93QKLJ9SZ"
export const MAPBOX_SECRET = "sk.eyJ1IjoiaW5jbHVzaXZlZ3VpZGUiLCJhIjoiY2ttanBpdzkzMHRpZzJvcW1mdHNyM2w1NiJ9.QvEGPD7da32YjoFMfYCJ-Q"
export const MAPBOX_PUBLIC = "pk.eyJ1IjoiaW5jbHVzaXZlZ3VpZGUiLCJhIjoiY2ttanA1ZThnMHRkcjJ5cXU3MjJoZ2szbSJ9.0JsDAD-p_AH6RgoN1NMglA"
export const MAPBOX_HOST = "https://api.mapbox.com/geocoding/v5/mapbox.places/"
export const PLACES_INDEX = "Places"
export const USERS_INDEX = "Users"


/** INPUT TYPES **/
// HTML INPUT TYPES
export const BUTTON = "button"
export const CHECKBOX = "checkbox"
export const COLOR = "color"
export const DATE = "date"
export const DATETIME_LOCAL = "datetime-local"
export const EMAIL = "email"
export const FILE = "file"
export const HIDDEN = "hidden"
export const IMAGE = "image"
export const MONTH = "month"
export const NUMBER = "number"
export const PASSWORD = "password"
export const RADIO = "radio"
export const RANGE = "range"
export const RESET = "reset"
export const SEARCH = "search"
export const SELECT = 'select'
export const SUBMIT = "submit"
export const TEL = "tel"
export const TEXT = "text"
export const TIME = "time"
export const URL = "url"
export const WEEK = "week"

// CUSTOM INPUT TYPES
export const ADDRESS = 'autoAddress'
export const AUDIO_UPLOAD = 'audioUpload'
export const COUNT = "count"
export const COUNTRY = 'country'
export const FILTER = 'filter'
export const IMAGE_UPLOAD = 'imageUpload'
export const LIKERT = 'likert'
export const MULTI_SELECT = 'multi-select'
export const ALGOLIA_SEARCH = 'mongo-search'
export const REGION = 'region'
export const RICH_TEXT = 'richText'
export const SONGS = 'songs'
export const SINGLE_SELECT = 'single-select'
export const STATE_CITY = 'state-city'
export const SEARCH_BAR = 'search-bar'
export const SERVICE_AREA = 'service-area'
export const TEXTAREA = "textarea"
export const TOGGLE = 'toggle'
export const SUBMITTED_BY = 'submittedBy'
export const HOURS_OPEN = 'hours-open'

export const ACCEPTABLE_EXTENSIONS = [
    '.png',
    '.jpg',
    '.jpeg',
    '.svg',
    '.wav',
    '.mp3',
    '.pdf'
]

/** S3 IMAGE_UPLOAD PATHS **/
export const UPLOAD_PATHS = {
    post: 'post/',
    resources: 'resources/',
    shop: 'shop/',
    place: 'place/',
    user: 'user/',
    businessOwner: 'business-owner/'
}

/** SITE CONFIG **/
export const siteDisplayName = 'Inclusive Guide'
export const siteTwitterUrl = 'https://twitter.com/inclusiveguide'
export const siteInstagramUrl = 'https://instagram.com/inclusiveguide'
