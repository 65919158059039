import {useEffect}                from 'react'
import Gravatar                   from 'react-gravatar'
import {useDispatch, useSelector} from 'react-redux'
import Div                        from 'shared/Basic/Div'
import S3Img                      from 'shared/Basic/S3Img'
import {
    defaultFieldHeadingStyle,
    submittedByAvatarStyle,
    submittedByAvatarWrapperStyle,
    submittedByInfoWrapperStyle,
    submittedByWrapperStyle
}                                 from './styles'

const SubmittedBy = ({value, inputLabel}) => {
    const dispatch = useDispatch()
    const {_id, token} = useSelector(state => state.user)
    const {current} = useSelector(state => state.place)


    useEffect(() => {
        if (!!value)
            dispatch({
                type: 'user/getSubmittedByUser',
                payload: {
                    userId: value,
                    _id: _id,
                    token: token
                }
            })

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value])


    return (
        <Div>
            {current && (
                <Div theme={submittedByWrapperStyle}>
                    <Div theme={defaultFieldHeadingStyle}>{inputLabel}</Div>
                    <Div theme={submittedByAvatarWrapperStyle}>
                        {(!!current.submittedBy.avatar && (
                            <S3Img
                                url={current.submittedBy.avatar}
                                theme={submittedByAvatarStyle}
                            />
                        )) || (
                            <Div theme={submittedByAvatarStyle}>
                                {!!current.submittedBy.email && (
                                    <Gravatar
                                        size={100}
                                        email={current.submittedBy.email}
                                    />
                                )}
                            </Div>
                        )}
                        <Div theme={submittedByInfoWrapperStyle}>
                            <Div theme={{size: [28, .7, 28]}}>{current.submittedBy.nameFirst}</Div>
                            <Div>{current.submittedBy.email}</Div>
                        </Div>
                    </Div>
                </Div>
            )}
        </Div>
    )
}

export default SubmittedBy
