import Gravatar                                                                   from 'react-gravatar'
import Div                                                                        from 'shared/Basic/Div'
import S3Img                                                                      from 'shared/Basic/S3Img'
import {optionStyle}                                                              from 'shared/Fields/MultiSelect/styles'
import {searchResultAvatarStyle, searchResultInfoStyle, searchResultWrapperStyle} from './styles'

const SelectOption = ({result, name, formik}) => {
    return (
        <Div
            theme={{
                ...searchResultWrapperStyle,
                ...optionStyle(formik.values[name]?.indexOf(result._id) !== -1 && !!formik.values[name])
            }}
            onClick={() => (formik.values[name]?.indexOf(result._id) !== -1)
                ? formik.setFieldValue(name, formik.values[name].filter(item => item !== result._id))
                : formik.setFieldValue(name, ([...formik?.values[name], result._id]))
            }>
            <Div theme={searchResultAvatarStyle}>
                {(!!result.avatar && (
                    <S3Img url={result.avatar}/>
                )) || (
                    <Gravatar email={result.email} size={100}/>
                )}
            </Div>

            <Div theme={searchResultInfoStyle}>
                <Div>{result.nameFirst} {result.nameLast}</Div>
                <Div>{result.email}</Div>
                <Div>{result._id}</Div>
            </Div>
        </Div>
    )
}

export default SelectOption
