import {NUMBER, TEXT} from 'config/variables'
import * as Yup                                 from 'yup'

export const createReportFields = [
    {
        name: 'name',
        inputLabel: 'Place Name',
        type: TEXT,
        disabled: true,
    },
    {
        name: 'address1',
        inputLabel: 'Address',
        type: TEXT,
        disabled: true,
    },
    {
        name: 'longitude',
        inputLabel: 'Longitude ',
        type: NUMBER,
        disabled: true,
      //  hidden: true
    },
    {
        name: 'latitude',
        inputLabel: 'Latitude ',
        type: NUMBER,
        disabled: true,
       // hidden: true
    },

]


/**
 *
 * Validation Objects written with Yup
 * https://github.com/jquense/yup#api
 *
 */

export const validateCreateReport = Yup.object().shape({
    name: Yup
        .string()
        .max(300)
        .required('Required'),
})
