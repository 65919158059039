import {takeEvery}                    from '@redux-saga/core/effects'
import {getEntryway, getEntrywayList} from 'features/place/admin/taxonomy/entryway/services'
import {call, put}                    from 'redux-saga/effects'
import {createEntity, updateEntity}   from 'utils/abstractions/crud'
import {setFormData}                  from 'utils/abstractions/setFormData'

export function* createEntryway({payload}) {
    const {_id, token, name, description,} = payload

    //add to formdata so api can read
    const entryway = new FormData()
    const fields = [{name}, {description}]
    for (let field of fields)
        setFormData(entryway, field)

    const createdEntryway = yield call(createEntity, {
        _id: _id,
        token: token,
        body: entryway,
        slug: 'entryway'
    })
    if (!createdEntryway.error) {
        yield put({type: 'place/listEntryway'})
        // yield put(push('/admin/places/update/' + crea.slug))

    } else {
        yield put({type: 'place/createEntrywayFailure', payload})

    }
}

export function* listEntryway() {
    try {
        const payload = yield call(getEntrywayList)
        if (!payload.error) {
            yield put({type: 'place/listEntrywaySuccess', payload})
        } else {
            yield put({type: 'place/listEntrywayFailure', payload})
        }
    } catch (error) {
        yield put({type: 'place/listEntrywayFailure', error})
    }
}

export function* getEntrywayDetail({payload}) {
    try {
        const entryway = yield call(getEntryway, payload)
        if (!entryway.error) {
            yield put({type: 'place/getEntrywaySuccess', payload: entryway})
        } else {
            yield put({type: 'place/getEntrywayFailure', payload: entryway})
        }
    } catch (error) {
        yield put({type: 'place/getEntrywayFailure', payload: error})
    }
}

export function* updateEntrywayDetail({payload}) {
    const {slug, _id, token, name, description} = payload

    //add to formData so api can read
    const entryway = new FormData()
    const fields = [{name}, {description}]
    for (let field of fields)
        setFormData(entryway, field)

    try {
        const updated = yield call(updateEntity, {
            slug: slug,
            parentSlug: 'entryway',
            body: entryway,
            _id: _id,
            token: token,
        })
        if (!updated.error) {
            yield put({type: 'place/updateEntrywaySuccess', payload: updated})
            yield put({
                type: 'site/setNotification',
                payload: {
                    notification: 'Updated Entryway',
                    theme: 'green'
                }
            })

        } else {
            yield put({type: 'place/updateEntrywayFailure', payload: updated})
        }
    } catch (error) {
        yield put({type: 'place/updateEntrywayFailure'})
    }
}


export function* watchCreateEntryway() {
    yield takeEvery('place/createEntryway', createEntryway)
}

export function* watchGetEntrywayList() {
    yield takeEvery('place/listEntryway', listEntryway)
}

export function* watchGetEntrywayDetail() {
    yield takeEvery('place/getEntryway', getEntrywayDetail)
}

export function* watchUpdateEntrywayDetail() {
    yield takeEvery('place/updateEntryway', updateEntrywayDetail)
}

