import {IMAGE_UPLOAD, LIKERT, RICH_TEXT, TOGGLE, UPLOAD_PATHS} from 'config/variables'
import * as Yup                                                from 'yup'

export const replyFields = [
    {
        name: 'review',
        inputLabel: 'Leave a Review',
        type: RICH_TEXT
    }
]

export const replyAdminFields = [
    {
        name: 'review',
        inputLabel: 'Leave a Review',
        type: RICH_TEXT
    }
]


/**
 *
 * Validation Objects written with Yup
 * https://github.com/jquense/yup#api
 *
 */

export const validateReview = Yup.object().shape({
    review: Yup
        .string().max(5000)
        .required('Required')
})
