import {mobileAdminDashboardMenu} from 'config/menus/dashboard/admin'
import {userDashboardMenu}        from 'config/menus/dashboard/user'
import {useDispatch, useSelector} from 'react-redux'
import Div                                                                          from 'shared/Basic/Div'
import Span                                                                         from 'shared/Basic/Span'
import {adminMenuStyle}                                                             from 'shared/Layout/Dashboard/admin/styles'
import LinkSwitch                                                                   from '../Basic/LinkSwitch'
import {headerButtonSignInStyle, headerButtonSignUpStyle, headerButtonWrapperStyle} from '../Layout/styles/header'
import HeaderDashboardMenu                                                          from './HeaderDashboardMenu'
import {headerAccountMenuLinkStyle}                                                 from './styles'

const MobileHeaderMenu = () => {
    const {nameFirst, isAdmin, isAuthenticated} = useSelector(state => state.user)
    const dispatch = useDispatch()

    return (
        <Div theme={adminMenuStyle}>
            <Div theme={adminMenuStyle.list}>
            <LinkSwitch
                            url={'https://www.deiresources.inclusiveguide.com/'}
                            className="resourcesBtn"
                        >
                            DEI Resources
                        </LinkSwitch>


           
                {(isAuthenticated && (
                    <>
                   
                        <Div theme={adminMenuStyle.greeting}>Hello, {nameFirst}</Div>
                        <HeaderDashboardMenu
                            menu={isAdmin ? mobileAdminDashboardMenu : userDashboardMenu}
                        />
                        <Span
                            theme={{...headerAccountMenuLinkStyle, ...headerAccountMenuLinkStyle.signOut}}
                            onClick={() => dispatch({type: 'user/signOut'})}
                        >
                            Sign Out
                        </Span>
                    </>
                )) || (
                    <Div theme={headerButtonWrapperStyle} className='headerButtonWrapperStyle'>
                        <LinkSwitch
                            url={'/signin'}
                            theme={headerButtonSignInStyle}
                            className='signinstyle'
                        >
                            Login
                        </LinkSwitch>
                        <LinkSwitch
                            url={'/signup'}
                            theme={headerButtonSignUpStyle}
                            className='signupstyle'
                        >
                            Register
                        </LinkSwitch>
                    </Div>
                )}
            </Div>
        </Div>
    )
}


export default MobileHeaderMenu
