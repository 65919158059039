import algoliasearch                                                  from 'algoliasearch'
import {ALGOLIA_ADMIN_KEY, ALGOLIA_APP_ID, PLACES_INDEX, USERS_INDEX} from 'config/variables'
import {createContext}                                                from 'react'

export const searchContext = createContext(null)

const SearchController = ({children}) => {
    const searchClient = algoliasearch(ALGOLIA_APP_ID, ALGOLIA_ADMIN_KEY)
    const placesIndex = searchClient.initIndex(PLACES_INDEX)
    const usersIndex = searchClient.initIndex(USERS_INDEX)

    return (
        <searchContext.Provider value={{
            searchClient,
            placesIndex,
            usersIndex
        }}>
            {children}
        </searchContext.Provider>
    )
}

export default SearchController
