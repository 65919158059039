import {push}    from 'connected-react-router'
import {
    getAlgoliaPlaces,
    getPlace,
    getResourceByslug,
    getPlaceById,
    getPlaceCategories,
    getPlaces,
    getTopRatedPlaces,
    getAdminstats,
    getDemographicoverviews,
    handlePageView,
    getPlaceAdmins
}                from 'features/place/services'
import {
    call,
    put,
    takeEvery,
    takeLatest
}                from 'redux-saga/effects'
import {
    getEntitiesByIds,
    getEntityById,
    listEntities,
    getReviewsByPlaceId
}                from 'utils/abstractions/crud'
import {slugify} from 'utils/helpers'

/**
 *
 *
 * @param POST
 *
 *
 */


export function* getPlacesDetail() {
    try {
        const payload = yield call(getPlaces)
        if (!payload.error) {
            yield put({type: 'place/getPlacesSuccess', payload})
        } else {
            yield put({type: 'place/getPlacesFailure', payload})
        }
    } catch (error) {
        yield put({type: 'place/getPlacesFailure', error})
    }
}
//TODO: create reports slice/reducer
export function* getReports() {
    try {
        const payload = yield call(listEntities, {
            path: 'reports'
        })
        if (!payload.error) {
            yield put({type: 'place/getReportsSuccess', payload})
        } else {
            yield put({type: 'place/getReportsFailure', payload})
        }
    } catch (error) {
        yield put({type: 'place/getReportsFailure', error})
    }
}

export function* getResources({pload}) {
    try {
        const { filters } = pload
        
        const payload = yield call(listEntities, {
            path: 'resources',
            filters
        })
        if (!payload?.error) {
            yield put({type: 'place/getResourcesSuccess', payload})
        } else {
            yield put({type: 'place/getResourcesFailure', payload})
        }
    } catch (error) {
        yield put({type: 'place/getResourcesFailure', error})
    }
}

export function* getResource({payload}) {
    try {
        const {slug} = payload
        const resource = yield call(getResourceByslug, {
            slug: slug
        })
        console.log("resourceresourceresource");
        console.log(resource)
        if (!resource.error) {
            yield put({type: 'place/getResourceSuccess', resource})
        } else {
            yield put({type: 'place/getResourceFailure', resource})
        }
    } catch (error) {
        yield put({type: 'place/getResourceFailure', error})
    }
}

export function* getPlacesByIds({payload}) {
    const {ids, caller} = payload

    const places = yield call(getEntitiesByIds, {
        ids: ids,
        path: 'places'
    })

    if (!places.error) {
        if (caller === 'my-businesses') {
            yield put({type: 'user/getMyBusinessesSuccess', payload: places})
        }
    }


    if (!places.error) {
        // yield put({type: 'place/getReviewsSuccess', payload: places})
        yield put({type: 'place/getPlacesSuccess', payload: places})
    }
}

export function* getPlaceDetail({payload}) {
    try {
        const place = yield call(getPlace, payload)
        if (!place.error) {
            yield put({type: 'place/getPlaceSuccess', payload: place})
        } else {
            yield put({type: 'place/getPlaceFailure', payload: place})
        }
    } catch (error) {
        yield put({type: 'place/getPlaceFailure'})
    }
}

export function* getUserReviewedPlace({payload}) {
    const {placeId} = payload
    try {
        const place = yield call(getPlaceById, {_id: placeId})
        if (!place.error) {
            yield put({type: 'user/getUserReviewedPlaceSuccess', payload: place})
        } else {
            yield put({type: 'user/getUserReviewedPlaceFailure', payload: place})
        }
    } catch (error) {
        yield put({type: 'user/getUserReviewedPlaceFailure'})
    }
}

export function* searchAllPlaces({payload}) {
    if (payload.input.length > 0) {
        if (!!payload.longitude && !!payload.latitude) {
            yield put(push(`/places/search/${slugify(payload.input)}`))
            yield put({type: 'place/searchAlgoliaPlaceIndex', payload})
            yield put({type: 'place/searchMapboxPlaces', payload})
        }
    }
}

export function* getPlaceCategoryByNameOrDescription({payload}) {
    // yield put({type: 'place/getPlaceCategoryByNameOrDescription', payload: payload.input}

    yield call(getPlaceCategories, {payload})
}

export function* searchAlgoliaPlaceIndex({payload}) {
    const result = yield call(getAlgoliaPlaces, payload)
    if (result.length > 0) {
        if(!!payload.isFilter) {
            yield put({
                type: 'place/filterSuccess',
                payload: result
            })
        } else {
            yield put({
                type: 'place/getAlgoliaPlacesSuccess',
                payload: result
            })
        }
    } else {
        if(!!payload.isFilter) {
            yield put({
                type: 'place/filterSuccess',
                payload: []
            })
        } else {
            yield put({
                type: 'place/getAlgoliaPlacesFailure',
                payload: result
            })
        }
    }
}

export function* getReview({payload}) {
    const review = yield call(getEntityById, {
        entityId: payload.review,
        path: 'review'
    })
    if (!review.error) {
        yield put({type: 'place/getReviewSuccess', payload: review})
    }
}

export function* getReviews({payload}) {
    // const reviews = yield call(getEntitiesByIds, {
    //     ids: payload.reviewIds,
    //     path: 'reviews'
    // })

    const {  token, placeId } = payload 
    console.log("pay load " , payload)
    const reviews = yield call(getReviewsByPlaceId, {
        token, placeId
    })
    if (!reviews.error) {
        yield put({type: 'place/getReviewsSuccess', payload: reviews})
    }
}

export function* addToViewCount({payload}) {
    const place = yield call(handlePageView, payload)
    if (!place?.error) {
        yield put({type: 'place/addViewSuccess', payload: place})
        //TODO:: update recently viewed
    }
}

export function* getAdminstatsDetail() {
    try {
        const payload = yield call(getAdminstats)
        if (!payload.error) {
            yield put({type: 'place/getAdminstatsSuccess', payload})
        } else {
            yield put({type: 'place/getAdminstatsFailure', payload})
        }
    } catch (error) {
        yield put({type: 'place/getAdminstatsFailure', error})
    }
}

export function* getTopRatedPlacesDetail() {
    try {
        const payload = yield call(getTopRatedPlaces)
        if (!payload.error) {
            yield put({type: 'place/getTopRatedPlacesSuccess', payload})
        } else {
            yield put({type: 'place/getTopRatedPlacesFailure', payload})
        }
    } catch (error) {
        yield put({type: 'place/getTopRatedPlacesFailure', error})
    }
}

export function* getDemographicoverview({payload}) {
    const dopayload = yield call(getDemographicoverviews, payload)
    if (!dopayload.error) {
        yield put({
            type: 'place/getDemographicoverviewSuccess',
            payload: dopayload
        })
    }
}


export function* getBusinessAdmins({payload}) {
    const { _id } = payload
    console.log("businessadmins worker payload ",_id)
    const admins = yield call(getPlaceAdmins, payload)
    console.log("businessadmins result  ",admins)

    if (!admins.error) {
        yield put({
            type: 'place/getBusinessAdminsSuccess',
            payload: admins
        })
    }
    else{
        yield put({
            type: 'place/getBusinessAdminsFailure'
        })
    }
}
/**
 *
 *
 * POST WATCHERS
 *
 *
 */

export function* watchGetPlaces() {
    yield takeEvery('place/getPlaces', getPlacesDetail)
}

export function* watchGetReports() {
    yield takeLatest('report/getReports', getReports)
}
export function* watchGetResources() {
    yield takeLatest('report/getResources', getResources)
}
export function* watchGetResource() {
    yield takeLatest('report/getResource', getResource)
}
export function* watchGetReport() {
    yield takeLatest('report/getReport', getReports)
}

export function* watchGetPlacesByIds() {
    yield takeLatest('place/getPlacesByIds', getPlacesByIds)
}

export function* watchGetPlaceDetail() {
    yield takeEvery('place/getPlace', getPlaceDetail)
}

export function* watchSearchAllPlaces() {
    yield takeLatest('place/searchAllPlaces', searchAllPlaces)
}

export function* watchSearchAlgoliaPlaceIndex() {
    yield takeEvery('place/searchAlgoliaPlaceIndex', searchAlgoliaPlaceIndex)
}

export function* watchGetReview() {
    yield takeEvery('place/getReview', getReview)
}

export function* watchGetReviews() {
    yield takeLatest('place/getReviews', getReviews)
}

export function* watchGetUserReviewedPlaces() {
    yield takeEvery('place/getUserReviewedPlaces', getUserReviewedPlace)
}

export function* watchGetPlaceCategoryByNameOrDescription() {
    yield takeEvery('place/getPlaceCategoryByNameOrDescription', getPlaceCategoryByNameOrDescription)
}

export function* watchAddToViewCount() {
    yield takeLatest('place/addToViewCount', addToViewCount)
}

export function* watchgetTopRatedPlaces() {
    yield takeEvery('place/getTopRatedPlaces', getTopRatedPlacesDetail)
}
export function* watchgetAdminstats() {
    yield takeEvery('place/getAdminstats', getAdminstatsDetail)
}
export function* watchgetDemographicoverviews() {
    yield takeLatest('place/getDemographicoverviews', getDemographicoverview)
}
export function* watchgetBusinessOwners() {
    yield takeLatest('place/getBusinessAdmins', getBusinessAdmins)
}
