import {globals}                     from 'config/styles'
import CartPanel                     from 'features/shop/views/CartPanel'
import {useContext}                  from 'react'
import Div                           from 'shared/Basic/Div'
import {menuPanelContext}            from 'shared/Containers/MenuPanelController'
import MenuOverlay                   from 'shared/Menus/MenuOverlay'
import MenuPanelWrapper              from 'shared/Menus/MenuPanelWrapper'
import MobileHeaderMenu              from 'shared/Menus/MobileHeaderMenu'
import {headerMenuPanelWrapperStyle} from 'shared/Menus/styles'
import Player                        from 'shared/Player'
import LeaveAReview                  from './LeaveAReview'
import ReplyAReview                  from './ReplyAReview'
import PostResource                  from './PostResource'
import UpdateResource                from './UpdateResource'
import MakePayment                  from './MakePayment'

import SearchPanel                   from './SearchPanel'
import SubmitPlacePanel              from './SubmitPlacePanel'
import CreateReportPanel                    from './CreateReportPanel'
import PlacePhotos                   from './PlacePhotos'

const MenuPanels = () => {
    const {currentPanel, setPanel} = useContext(menuPanelContext)
    const setCurrentPanel = () => {
        switch (currentPanel) {
            case 'cart-menu-panel':
                return <CartPanel/>
            case 'mobile-header-menu-panel':
                return <MobileHeaderMenu/>
            case 'search-menu':
                return <SearchPanel/>
            case 'leave-a-review':
                return <LeaveAReview/>
            case 'reply-a-review':
                    return <ReplyAReview/>
            case 'make-payment':
                    return <MakePayment/>    
             case 'post-resource':
                        return <PostResource/>           
            case 'post-resource':
                  return <PostResource/>  
            case 'update-resource':
                  return <UpdateResource />        
            case 'submit-a-place':
                return <SubmitPlacePanel/>
            case 'create-a-report':
                return <CreateReportPanel/>
            case 'player':
                return <Player/>
            case 'place-photos':
                return <PlacePhotos/>
            default:
                return null
        }
    }

    return (
        <Div theme={headerMenuPanelWrapperStyle}>
            <MenuPanelWrapper
                children={setCurrentPanel()}
                name={currentPanel}
            />
            <MenuOverlay
                isOpen={!!currentPanel}
                onClick={
                    () => {
                        setPanel(null)
                        globals.style.resetBody()
                    }
                }
            />
        </Div>
    )
}

MenuPanels.displayName = 'MenuPanels'

export default MenuPanels
