import Div                                                          from 'shared/Basic/Div'
import S3Img                                                        from 'shared/Basic/S3Img'
import MenuOverlay                                                  from 'shared/Menus/MenuOverlay'
import {placePhotoDetailContainerStyle, placePhotoDetailImageStyle} from './styles'

export default function PhotoDetailModal({photo, close}) {
    const handleClose = () => {
        close();
    }
    return (
        <Div theme={placePhotoDetailContainerStyle} onClick={handleClose}>
            <Div theme={placePhotoDetailImageStyle}>
                <S3Img url={photo}/>
            </Div>
            <MenuOverlay isOpen/>
        </Div>
    )
}
