import {useEffect, useRef} from 'react'
import {Formik}           from 'formik'
import PropTypes          from 'prop-types'
import {useDispatch}      from 'react-redux'
import Div                from 'shared/Basic/Div'
import H3                 from 'shared/Basic/H3'
import SubmitButton       from 'shared/Basic/SubmitButton'
import FieldSwitch        from 'shared/Fields'
import {defaultFormStyle} from './styles'
import Select from 'react-select'

const Form = ({
                  algoliaSearchOptions,
                  autoSubmit = false,
                  hideButton = false,
                  buttonText,
                  children,
                  dispatchAction,
                  enableReinitialize,
                  fields,
                  formHeading,
                  initialValues,
                  options,
                  payload = {},
                  customErrors = [],
                  theme,
                  validationSchema,
                  formsubheading=''
              }) => {
    const formikRef = useRef(null)
    const dispatch = useDispatch()
    useEffect(() => {
        if (customErrors.length && formikRef.current) {
            customErrors.forEach(error => {
                const {name, message} = error
                formikRef.current.setFieldError(name, message)
            })
        }
    }, [customErrors])
   
    const timingOptions = [
        { value: '10AM', label: '10AM' },
        { value: '11AM', label: '11AM' },
        { value: '12PM', label: '12PM' }
    ]

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={values => dispatch({
                type: dispatchAction,
                payload: {...values, ...payload}
            })}
            enableReinitialize={enableReinitialize}
        >
            {formik => {
                formikRef.current = formik
                return (
                    <Div 
                        id="form_sec"
                        as="form"
                        theme={{...defaultFormStyle, ...theme}}
                        onSubmit={formik.handleSubmit}
                        className="form_sec"
                    >
                        <H3
                            theme={{
                                ...defaultFormStyle.heading,
                                ...theme.heading
                            }}
                            children={formHeading}
                        />
                        { 
                           formsubheading!='' && 
                           <p>{formsubheading}</p>
                        }
                        <Div theme={{...defaultFormStyle.inner, ...theme.inner}}>
                            {fields.map((f, i) =>
                                <FieldSwitch
                                    key={i}
                                    formik={formik}
                                    field={f}
                                    options={options}
                                    algoliaSearchOptions={algoliaSearchOptions}
                                    theme={theme}
                                    autoSubmit={autoSubmit}
                                />
                            )}
                        </Div>
                        {false && 
                            <Div className="open_hours_sec">
                            <h2>Open Hours</h2>
                            <div className='open_hours_innr_sec'>
                                <span className='day'>Sunday</span>
                                <div className='selection_sec'>
                                    <div className='custom_slect'>
                                            <Select
                                            placeholder="Select Open Time"
                                            classNamePrefix="select_time"
                                            theme={(theme) => ({
                                                ...theme,
                                                colors: {
                                                ...theme.colors,
                                                primary25: 'rgba(255, 180, 0, 0.2)',
                                                primary: 'rgba(255, 180, 0, 0.7)',
                                                }
                                            })}
                                            options={timingOptions}
                                            />
                                    </div>
                                    <div className='custom_slect'>
                                            <Select
                                            placeholder="Select Closing Time"
                                            classNamePrefix="select_time"
                                            theme={(theme) => ({
                                                ...theme,
                                                colors: {
                                                ...theme.colors,
                                                primary25: 'rgba(255, 180, 0, 0.2)',
                                                primary: 'rgba(255, 180, 0, 0.7)',
                                                }
                                            })}
                                            options={timingOptions}
                                            />
                                    </div>
                                </div>
                            </div>
                            <div className='open_hours_innr_sec'>
                                <span className='day'>Monday</span>
                                <div className='selection_sec'>
                                    <div className='custom_slect'>
                                            <Select
                                            placeholder="Select Open Time"
                                            classNamePrefix="select_time"
                                            theme={(theme) => ({
                                                ...theme,
                                                colors: {
                                                ...theme.colors,
                                                primary25: 'rgba(255, 180, 0, 0.2)',
                                                primary: 'rgba(255, 180, 0, 0.7)',
                                                }
                                            })}
                                            options={timingOptions}
                                            />
                                    </div>
                                    <div className='custom_slect'>
                                            <Select
                                            placeholder="Select Closing Time"
                                            classNamePrefix="select_time"
                                            theme={(theme) => ({
                                                ...theme,
                                                colors: {
                                                ...theme.colors,
                                                primary25: 'rgba(255, 180, 0, 0.2)',
                                                primary: 'rgba(255, 180, 0, 0.7)',
                                                }
                                            })}
                                            options={timingOptions}
                                            />
                                    </div>
                                </div>
                            </div>
                            <div className='open_hours_innr_sec'>
                                <span className='day'>Tuesday</span>
                                <div className='selection_sec'>
                                    <div className='custom_slect'>
                                            <Select
                                            placeholder="Select Open Time"
                                            classNamePrefix="select_time"
                                            theme={(theme) => ({
                                                ...theme,
                                                colors: {
                                                ...theme.colors,
                                                primary25: 'rgba(255, 180, 0, 0.2)',
                                                primary: 'rgba(255, 180, 0, 0.7)',
                                                }
                                            })}
                                            options={timingOptions}
                                            />
                                    </div>
                                    <div className='custom_slect'>
                                            <Select
                                            placeholder="Select Closing Time"
                                            classNamePrefix="select_time"
                                            theme={(theme) => ({
                                                ...theme,
                                                colors: {
                                                ...theme.colors,
                                                primary25: 'rgba(255, 180, 0, 0.2)',
                                                primary: 'rgba(255, 180, 0, 0.7)',
                                                }
                                            })}
                                            options={timingOptions}
                                            />
                                    </div>
                                </div>
                            </div>
                            <div className='open_hours_innr_sec'>
                                <span className='day'>Wednesday</span>
                                <div className='selection_sec'>
                                    <div className='custom_slect'>
                                            <Select
                                            placeholder="Select Open Time"
                                            classNamePrefix="select_time"
                                            theme={(theme) => ({
                                                ...theme,
                                                colors: {
                                                ...theme.colors,
                                                primary25: 'rgba(255, 180, 0, 0.2)',
                                                primary: 'rgba(255, 180, 0, 0.7)',
                                                }
                                            })}
                                            options={timingOptions}
                                            />
                                    </div>
                                    <div className='custom_slect'>
                                            <Select
                                            placeholder="Select Closing Time"
                                            classNamePrefix="select_time"
                                            theme={(theme) => ({
                                                ...theme,
                                                colors: {
                                                ...theme.colors,
                                                primary25: 'rgba(255, 180, 0, 0.2)',
                                                primary: 'rgba(255, 180, 0, 0.7)',
                                                }
                                            })}
                                            options={timingOptions}
                                            />
                                    </div>
                                </div>
                            </div>
                            <div className='open_hours_innr_sec'>
                                <span className='day'>Thursday</span>
                                <div className='selection_sec'>
                                    <div className='custom_slect'>
                                            <Select
                                            placeholder="Select Open Time"
                                            classNamePrefix="select_time"
                                            theme={(theme) => ({
                                                ...theme,
                                                colors: {
                                                ...theme.colors,
                                                primary25: 'rgba(255, 180, 0, 0.2)',
                                                primary: 'rgba(255, 180, 0, 0.7)',
                                                }
                                            })}
                                            options={timingOptions}
                                            />
                                    </div>
                                    <div className='custom_slect'>
                                            <Select
                                            placeholder="Select Closing Time"
                                            classNamePrefix="select_time"
                                            theme={(theme) => ({
                                                ...theme,
                                                colors: {
                                                ...theme.colors,
                                                primary25: 'rgba(255, 180, 0, 0.2)',
                                                primary: 'rgba(255, 180, 0, 0.7)',
                                                }
                                            })}
                                            options={timingOptions}
                                            />
                                    </div>
                                </div>
                            </div>
                            <div className='open_hours_innr_sec'>
                                <span className='day'>Friday</span>
                                <div className='selection_sec'>
                                    <div className='custom_slect'>
                                            <Select
                                            placeholder="Select Open Time"
                                            classNamePrefix="select_time"
                                            theme={(theme) => ({
                                                ...theme,
                                                colors: {
                                                ...theme.colors,
                                                primary25: 'rgba(255, 180, 0, 0.2)',
                                                primary: 'rgba(255, 180, 0, 0.7)',
                                                }
                                            })}
                                            options={timingOptions}
                                            />
                                    </div>
                                    <div className='custom_slect'>
                                            <Select
                                            placeholder="Select Closing Time"
                                            classNamePrefix="select_time"
                                            theme={(theme) => ({
                                                ...theme,
                                                colors: {
                                                ...theme.colors,
                                                primary25: 'rgba(255, 180, 0, 0.2)',
                                                primary: 'rgba(255, 180, 0, 0.7)',
                                                }
                                            })}
                                            options={timingOptions}
                                            />
                                    </div>
                                </div>
                            </div>
                            <div className='open_hours_innr_sec'>
                                <span className='day'>Saturday</span>
                                <div className='selection_sec'>
                                    <div className='custom_slect'>
                                            <Select
                                            placeholder="Select Open Time"
                                            classNamePrefix="select_time"
                                            theme={(theme) => ({
                                                ...theme,
                                                colors: {
                                                ...theme.colors,
                                                primary25: 'rgba(255, 180, 0, 0.2)',
                                                primary: 'rgba(255, 180, 0, 0.7)',
                                                }
                                            })}
                                            options={timingOptions}
                                            />
                                    </div>
                                    <div className='custom_slect'>
                                            <Select
                                            placeholder="Select Closing Time"
                                            classNamePrefix="select_time"
                                            theme={(theme) => ({
                                                ...theme,
                                                colors: {
                                                ...theme.colors,
                                                primary25: 'rgba(255, 180, 0, 0.2)',
                                                primary: 'rgba(255, 180, 0, 0.7)',
                                                }
                                            })}
                                            options={timingOptions}
                                            />
                                    </div>
                                </div>
                            </div>
                            </Div>
                        }
                        
                        {!autoSubmit && !hideButton && (
                            <SubmitButton
                                theme={{...theme.button}}
                                children={buttonText}
                            />
                        )}
                        {!!children && children.map((c, i) => (
                            <Div key={i} theme={{width: '100%'}}>
                                {c?.type(formik)}
                            </Div>
                        ))}
                    </Div>
                )
            }}
        </Formik>
    )
}

Form.propTypes = {
    theme: PropTypes.object,
}

Form.defaultProps = {
    theme: {}
}

export default Form
