import MapboxGeocoder                  from '@mapbox/mapbox-gl-geocoder'
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css'
import {search}                        from 'config/icons'
import {MAPBOX_PUBLIC, MAPBOX_SECRET}  from 'config/variables'
import PropTypes                       from 'prop-types'
import {useContext, useEffect, useRef} from 'react'
import Div                             from 'shared/Basic/Div'
import Fieldset                        from 'shared/Basic/Fieldset'
import Icon                            from 'shared/Basic/Icon'
import Input                           from 'shared/Basic/Input'
import Span                            from 'shared/Basic/Span'
import {mapContext}                    from 'shared/Containers/MapController'
import {isEmpty}                       from 'utils/helpers'
import {
    defaultFieldErrorStyle,
    searchBarDividerStyle,
    searchBarFieldsetStyle,
    searchBarInputStyle,
    searchBarSearchIconStyle,
    searchBarWrapperStyle,
    searchBarZipcodeInputStyle
}                                      from './styles'

const mapboxGeo = require('@mapbox/mapbox-sdk/services/geocoding');
const geocodingClient = mapboxGeo({accessToken: MAPBOX_PUBLIC});

const SearchBar = ({
                       autoFocus,
                       className,
                       errorMessage,
                       formik,
                       id,
                       onChange,
                       theme,
                       type,
                       value,
                   }) => {
    const inputRef = useRef()
    const geoCoderRef = useRef()
    const {setCoords, coords} = useContext(mapContext)

    const handleBlur = () => {
    }
    const handleFocus = () => {
        document.addEventListener("keydown", function (event) {
            if (event.code === 'Enter') {
                if (inputRef?.current?.value.length > 0) {
                    if (geoCoderRef.current.querySelector('input').value.length > 0) {
                        event.preventDefault()
                        formik.submitForm()
                    }
                } else {
                    event.preventDefault()
                }
            }
        })
    }
    const handleKeyUp = (e) => {
    }

    const customizeGeocoderUI = () => {
        if (geoCoderRef.current) {
            const svg = geoCoderRef.current.querySelector('svg')
            if (svg) {
                svg.innerHTML = `
          <path fill-rule="evenodd" clip-rule="evenodd" d="M0.291687 10.5116C0.291687 4.86781 4.85627 0.303223 10.5 0.303223C16.1438 0.303223 20.7084 4.86781 20.7084 10.5116C20.7084 16.5928 14.2625 24.9782 11.6229 28.172C11.0396 28.872 9.97502 28.872 9.39169 28.172C6.73752 24.9782 0.291687 16.5928 0.291687 10.5116ZM6.85419 10.5116C6.85419 12.5241 8.48752 14.1574 10.5 14.1574C12.5125 14.1574 14.1459 12.5241 14.1459 10.5116C14.1459 8.49906 12.5125 6.86572 10.5 6.86572C8.48752 6.86572 6.85419 8.49906 6.85419 10.5116Z" fill="black" fill-opacity="0.54"/>
        `
                svg.setAttribute('viewBox', '0 0 21 32')
            }
            const input = geoCoderRef.current.querySelector('input')
            if (input) {
                input.placeholder = 'City, state, or zip code'
            }
        }
    }


    useEffect(() => {
        if (geoCoderRef.current) {
            const geocoder = new MapboxGeocoder({
                accessToken: MAPBOX_SECRET,
                types: 'country,region,place,postcode,locality,neighborhood',
            })

            geocoder.addTo(geoCoderRef.current)
            customizeGeocoderUI()

            geocoder.on('result', (e) => {
                const {geometry} = e.result
                if (geometry && geometry.coordinates) {
                    const [lon, lat] = geometry.coordinates
                    setCoords({lat, lon})
                }
            })
        }

        /* Set default location of location selector input if coordinates exist */
        if (!isEmpty(coords)) {
            //TODO:: try for coords two or three times a few 100ms apart
            geocodingClient.reverseGeocode({
                query: [coords.lon, coords.lat],
                mode: 'mapbox.places',
            })
                .send()
                .then(response => {
                    const match = response.body;
                    const zip = match?.features?.[0].context?.[1].text
                    const input = geoCoderRef.current.querySelector('input')
                    input.value = zip
                })
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    return (
        <Div theme={searchBarWrapperStyle} id="search_area">
            <Fieldset
                theme={{...searchBarFieldsetStyle, ...theme}}
                className='searchBarFieldsetStyle'
            >
                <Icon
                    icon={search}
                    theme={searchBarSearchIconStyle}
                />
                <Input
                    id={id}
                    onChange={onChange}
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                    onKeyUp={handleKeyUp}
                    type={type || 'text'}
                    value={value || ''}
                    theme={{
                        ...searchBarInputStyle,
                        ...theme.field
                    }}
                    placeholder="I'm looking for?"
                    autoFocus={autoFocus}
                    autoComplete="no"
                    ref={inputRef}
                />
                <Span theme={defaultFieldErrorStyle}>{errorMessage}</Span>
                <Div theme={searchBarDividerStyle} className="searchbardivider" />
            </Fieldset>
            <Div ref={geoCoderRef} theme={searchBarZipcodeInputStyle} className='search_area_home'/>
        </Div>
    )
}

SearchBar.propTypes = {
    theme: PropTypes.object,
}

SearchBar.defaultProps = {
    theme: {},
}

export default SearchBar
