import {takeEvery}                  from '@redux-saga/core/effects'
import {getVisualImpairment, getVisualImpairmentList}       from 'features/user/admin/taxonomy/visualImpairment/services'
import {call, put}                  from 'redux-saga/effects'
import {createEntity, updateEntity} from 'utils/abstractions/crud'
import {setFormData}                from 'utils/abstractions/setFormData'

export function* createVisualImpairment({payload}) {
    const {_id, token, name, description,} = payload

    //add to formdata so api can read
    const visualImpairment = new FormData()
    const fields = [{name}, {description}]
    for (let field of fields)
        setFormData(visualImpairment, field)

    const createdVisualImpairment = yield call(createEntity, {
        _id: _id,
        token: token,
        body: visualImpairment,
        slug: 'visual-impairment'
    })
    if (!createdVisualImpairment.error) {
        yield put({type: 'user/listVisualImpairment'})
        yield put({
            type: 'site/setNotification',
            payload: {
                notification: 'New VisualImpairment Taxonomy Type Added',
                theme: 'green'
            }
        })
        // yield put(push('/admin/places/update/' + crea.slug))

    } else {
        yield put({type: 'user/createVisualImpairmentFailure', payload})

    }
}

export function* listVisualImpairment() {
    try {
        const payload = yield call(getVisualImpairmentList)
        if (!payload.error) {
            yield put({type: 'user/listVisualImpairmentSuccess', payload})
        } else {
            yield put({type: 'user/listVisualImpairmentFailure', payload})
        }
    } catch (error) {
        yield put({type: 'user/listVisualImpairmentFailure', error})
    }
}

export function* getVisualImpairmentDetail({payload}) {
    try {
        const visualImpairment = yield call(getVisualImpairment, payload)
        if (!visualImpairment.error) {
            yield put({type: 'user/getVisualImpairmentSuccess', payload: visualImpairment})
        } else {
            yield put({type: 'user/getVisualImpairmentFailure', payload: visualImpairment})
        }
    } catch (error) {
        yield put({type: 'user/getVisualImpairmentFailure', payload: error})
    }
}

export function* updateVisualImpairmentDetail({payload}) {
    const {slug, _id, token, name, description} = payload

    //add to formData so api can read
    const visualImpairment = new FormData()
    const fields = [{name}, {description}]
    for (let field of fields)
        setFormData(visualImpairment, field)

    try {
        const updated = yield call(updateEntity, {
            slug: slug,
            parentSlug: 'visual-impairment',
            body: visualImpairment,
            _id: _id,
            token: token,
        })
        if (!updated.error) {
            yield put({type: 'user/updateVisualImpairmentSuccess', payload: updated})
            yield put({
                type: 'site/setNotification',
                payload: {
                    notification: 'Updated VisualImpairment',
                    theme: 'green'
                }
            })

        } else {
            yield put({type: 'user/updateVisualImpairmentFailure', payload: updated})
        }
    } catch (error) {
        yield put({type: 'user/updateVisualImpairmentFailure'})
    }
}


export function* watchCreateVisualImpairment() {
    yield takeEvery('user/createVisualImpairment', createVisualImpairment)
}

export function* watchGetVisualImpairmentList() {
    yield takeEvery('user/listVisualImpairment', listVisualImpairment)
}

export function* watchGetVisualImpairmentDetail() {
    yield takeEvery('user/getVisualImpairment', getVisualImpairmentDetail)
}

export function* watchUpdateVisualImpairmentDetail() {
    yield takeEvery('user/updateVisualImpairment', updateVisualImpairmentDetail)
}

