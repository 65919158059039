import {createSlice}               from '@reduxjs/toolkit'
import {adaptiveEquipmentSolution} from './admin/taxonomy/adaptiveEquipmentSolution/reducers'
import {bathroom}                  from './admin/taxonomy/bathroom/reducers'
import {communitiesServed}         from './admin/taxonomy/communitiesServed/reducers'
import {doorway}                   from './admin/taxonomy/doorway/reducers'
import {entryway}                  from './admin/taxonomy/entryway/reducers'
import {foodOptions}               from './admin/taxonomy/foodOptions/reducers'
import {hearingImpairedSolution}   from './admin/taxonomy/hearingImpairedSolution/reducers'
import {languageSpoken}            from './admin/taxonomy/languageSpoken/reducers'
import {placeCategory}             from './admin/taxonomy/placeCategory/reducers'
import {publicTransportation}      from './admin/taxonomy/publicTransportation/reducers'
import {visualImpairedSolution}    from './admin/taxonomy/visualImpairedSolution/reducers'
import {ownerIdentity}             from './admin/taxonomy/ownerIdentity/reducers'

const subjectsList = [
    {
        type: "subjects",
        name: "Ageism",
        _id: "Ageism"
    },
    {
        type: "subjects",
        name: "Allyship",
        _id: "Allyship"
    },
    {
        type: "subjects",
        name: "Bias",
        _id: "Bias"
    },
    {
        type: "subjects",
        name: "Body Positivity",
        _id: "Body_Positivity"
    },
    {
        type: "subjects",
        name: "Class",
        _id: "Class"
    },
    {
        type: "subjects",
        name: "Colonialism",
        _id: "Colonialism"
    },
    {
        type: "subjects",
        name: "Colorism",
        _id: "Colorism"
    },
    {
        type: "subjects",
        name: "Community Engagement",
        _id: "Community_Engagement"
    },
    {
        type: "subjects",
        name: "Conservation",
        _id: "Conservation"
    }, {
        type: "subjects",
        name: "Cultural Appropriation",
        _id: "Cultural_Appropriation"
    }, {
        type: "subjects",
        name: "Cultural Competence",
        _id: "Cultural_Competence"
    }, {
        type: "subjects",
        name: "Culture",
        _id: "Culture"
    }, {
        type: "subjects",
        name: "Decolonization",
        _id: "Decolonization"
    }, {
        type: "subjects",
        name: "Disability",
        _id: "Disability"
    },
    {
        type: "subjects",
        name: "Environment",
        _id: "Environment"
    },{
        type: "subjects",
        name: "Environmental Justice",
        _id: "Environmental_Justice"
    },{
        type: "subjects",
        name: "Ethnicity",
        _id: "Ethnicity"
    },{
        type: "subjects",
        name: "Gender",
        _id: "Gender"
    },
    {
        type: "subjects",
        name: "Gender Identity",
        _id: "Gender_Identity"
    },
    {
        type: "subjects",
        name: "Immigration and Migration",
        _id: "Immigration_and_Migration"
    },
    {
        type: "subjects",
        name: "Inclusive Design",
        _id: "Inclusive_Design"
    },

    {
        type: "subjects",
        name: "Indigenous",
        _id: "Indigenous"
    },
    {
        type: "subjects",
        name: "Intersectionality",
        _id: "Intersectionality"
    },
    {
        type: "subjects",
        name: "Language Diversity",
        _id: "Language_Diversity"
    },
    {
        type: "subjects",
        name: "LGBTQIA+",
        _id: "LGBTQIA+"
    },
    {
        type: "subjects",
        name: "Media",
        _id: "Media"
    },
    {
        type: "subjects",
        name: "Mental Health Awareness",
        _id: "Mental_Health_Awareness"
    },{
        type: "subjects",
        name: "Microaggressions",
        _id: "Microaggressions"
    },{
        type: "subjects",
        name: "Multicultural Education",
        _id: "Multicultural_Education"
    },{
        type: "subjects",
        name: "Nationality and Immigration Status",
        _id: "Nationality_and_Immigration_Status"
    },{
        type: "subjects",
        name: "Neurodiversity",
        _id: "Neurodiversity"
    },
    {
        type: "subjects",
        name: "Outdoor Education",
        _id: "Outdoor_Education"
    },
    {
        type: "subjects",
        name: "Parenting and Family Structures",
        _id: "Parenting_and_Family_Structures"
    },
    {
        type: "subjects",
        name: "Privilege",
        _id: "Privilege"
    },
    {
        type: "subjects",
        name: "Race",
        _id: "Race"
    },
    {
        type: "subjects",
        name: "Recruiting And Hiring",
        _id: "Recruiting_And_Hiring"
    },
    {
        type: "subjects",
        name: "Religion",
        _id: "Religion"
    },
    {
        type: "subjects",
        name: "Restorative Justice",
        _id: "Restorative_Justice"
    },
    {
        type: "subjects",
        name: "Sexual Orientation",
        _id: "Sexual_Orientation"
    },
    {
        type: "subjects",
        name: "Socioeconomic Status",
        _id: "Socioeconomic_Status"
    },
    {
        type: "subjects",
        name: "Transgender",
        _id: "Transgender"
    },
    {
        type: "subjects",
        name: "White Supremacy",
        _id: "White_Supremacy"
    }
]

const categoryList = [
    {
        type: "category",
        name: "Activities",
        _id: "Activities"
    },
    {
        type: "category",
        name: "Media",
        _id: "Media1"
    },
    {
        type: "category",
        name: "Reading",
        _id: "Reading"
    },
    {
        type: "category",
        name: "Tips & Tools",
        _id: "Tips_Tools"
    },    
    

]

const initialState = {
    addReviewSuccess: false,
    addResourceSuccess: false,
    updateResourceSuccess: false,
    currentEditResourceSlug: null,
    currentReplyReviewId: null,
    currentPlaceId: null,
    subjectsList,
    categoryList,
    adaptiveEquipmentSolutions: [],
    bathrooms: [],
    businessOwner: [],
    businessAdmins: [],
    communitiesServed: [], 
    ownerIdentity: [],
    doorways: [],
    entryways: [],
    foodOptions: [],
    hearingImpairedSolutions: [],
    languageSpoken: [],
    locationList: [],
    mapboxPlace: {},
    placeCategory: [],
    publicTransportationList: [],
    visualImpairedSolutions: [],
    pendingPlaces: [],
    place: [],
    placeLoaded: false,
    places: [],
    review: {},
    reviews: [],
    report:[],
    reports: [],
    resources: [],
    resource: {},
    flaggedReviews: [],
    globalReviews:[],
    toprateplaces:[],
    adminstatsadminstats:[],
    demographicoverview:{},
    businessAdmins: [],
    error: {
        places: {},
        boonePlaces: {},
        place: {},
        boonePlace: {
            status: '',
            message: ''
        }
    },
    boonePlaces: {},
    boonePlace: {},
    noResults: {
        boone: false,
        algolia: false
    },
    taxonomy: {
        adaptiveEquipmentSolution: [],
        bathroom: [],
        communitiesServed: [],
        ownerIdentity: [],
        entryway: [],
        doorway: [],
        foodOptions: [],
        hearingImpairedSolution: [],
        languageSpoken: [],
        placeCategory: [],
        publicTransportation: [],
        visualImpairedSolution: [],
    },
    algoliaPlaces: [],
    createdFromBoone: [],
    current: {
        submittedBy: {},
        businessOwners: [],
        filteredPlaces: undefined
    }
}

export const slice = createSlice({
    name: 'place',
    initialState: initialState,
    reducers: {
        addReviewSuccess: (state) => {
            state.addReviewSuccess = true
        },
        addResourceSuccess: (state) => {
            state.addResourceSuccess = true
        },
        updateResourceSuccess: (state) => {
            state.updateResourceSuccess = true
        },
        setCurrentEditResourceSlug: (state,action) =>{
            state.currentEditResourceSlug = action.payload.resourceSlug
        },
        setCurrentReplyReviewId: (state,action) =>{
            state.currentReplyReviewId = action.payload.reviewId
        },
        buildLocationList: (state, action) => {
            state.locationList = action.payload.features
        },
        closeReviewPanel: (state) => {
            state.addReviewSuccess = false
        },
        clear: (state, action) => {
            state.error = {
                places: {},
                boonePlaces: {},
                place: {},
                boonePlace: {
                    status: '',
                    message: ''
                }
            }
        },
        getPlace: (state, action) => {
            state.place = []
            state.current.place = []
            state.adaptiveEquipmentSolutions = []
            state.bathrooms = []
            state.businessOwner = []
            state.businessAdmins= []
            state.communitiesServed = []
            state.ownerIdentity = []
            state.entryways = []
            state.doorways = []
            state.foodOptions = []
            state.hearingImpairedSolutions = []
            state.languageSpoken = []
            state.placeCategory = []
            state.publicTransportations = []
            state.visualImpairedSolutions = []
        },
        getPlaceSuccess: (state, action) => {
            state.place = action.payload
            state.current.place = action.payload
            state.reviews = []
            state.placeLoaded = true
        },
        taxonomyLoaded: (state) => {
            state.placeLoaded = false
        },
        getPlaceFailure: (state, action) => {
            state.error.place = action.payload
        },
        getPlacesSuccess: (state, action) => {
            state.places = action.payload
        },
        getPlacesFailure: (state, action) => {
            state.error.places = action.payload
        },
        getBooneAutoCompleteSuccess: (state, action) => {
            state.boonePlaces = action.payload
            state.noResults.boone = false
        },
        getBooneAutoCompleteFailure: (state, action) => {
            state.noResults.boone = true
            state.boonePlaces = {}
        },
        getBoonePlaceSuccess: (state, action) => {
            state.boonePlace = action.payload.data
            state.error.boonePlace = {}
        },
        getBoonePlaceFailure: (state, action) => {
            state.error.boonePlace = action.payload
        },
        createPlaceFromBooneSuccess: (state, action) => {
            state.createdFromBoone = [...state.createdFromBoone, action.payload.createdPlace]
        },
        createPlaceFromBooneIndexSuccess: (state) => {
            state.createdFromBoone = []
        },
        updatePlaceFailure: (state, action) => {
            state.error.place = action.payload
        },
        updatePlaceSuccess: (state, action) => {
            state.place = action.payload
            state.current.place = action.payload
        },
        destroyPlaceSuccess: (state, action) => {
            state.places = state.places.filter(item => item.objectID !== action.payload.objectID)
            state.current.places = state.places.filter(item => item.objectID !== action.payload.objectID)
        },
        getAlgoliaPlacesSuccess: (state, action) => {
            state.algoliaPlaces = action.payload
            state.noResults.algolia = false
        },
        getAlgoliaPlacesFailure: (state, action) => {
            state.noResults.algolia = true
            state.algoliaPlaces = []
        },
        getPendingPlacesSuccess: (state, action) => {
            state.pendingPlaces = action.payload
        },
        getReviewSuccess: (state, action) => {
            state.review = action.payload
        },
        getResourcesSuccess: (state, action) => {
            state.resources = action.payload
        },
        getResourcesFailure: (state) => {
            state.resources = []
        },
        getResourceSuccess: (state, action) => {
            state.resource = action.resource;
            state.updateResourceSuccess = false 
        },
        getReviewsSuccess: (state, action) => {
            state.reviews = action.payload
        },
        getReportSuccess: (state, action) => {
            state.report = action.payload
        },
        getReportsSuccess: (state, action) => {
            state.reports = action.payload
        },
        loadMapboxPlace: (state, action) => {
            state.mapboxPlace.name = action?.payload.text
            state.mapboxPlace.address = action?.payload.properties.address
            state.mapboxPlace.categories = action?.payload.properties.category.split(',')
            state.mapboxPlace.longitude = action.payload.geometry.coordinates[0]
            state.mapboxPlace.latitude = action.payload.geometry.coordinates[1]
            state.mapboxPlace.city = action.payload.context[2].text
            state.mapboxPlace.state = action.payload.context[5].text
            state.mapboxPlace.zip = action.payload.context[1].text
        },
        searchMapboxPlacesSuccess: (state, action) => {
            state.mapboxPlaces = action.payload.features
        },
        getAdaptiveEquipmentSolutionEntitySuccess: (state, action) => {
            state.adaptiveEquipmentSolutions = state.adaptiveEquipmentSolutions.filter(item => item._id === action.payload.entity._id).length < 1
                ? [...state.adaptiveEquipmentSolutions, action.payload.entity]
                : state.adaptiveEquipmentSolutions
        },
        getBathroomEntitySuccess: (state, action) => {
            state.bathrooms = state.bathrooms.filter(item => item._id === action.payload.entity._id).length < 1
                ? [...state.bathrooms, action.payload.entity]
                : state.bathrooms
        },
        getBusinessOwnerEntitySuccess: (state, action) => {
            state.businessOwner = state.businessOwner.filter(item => item._id === action.payload.entity._id).length < 1
                ? [...state.businessOwner, action.payload.entity]
                : state.businessOwner
        },
        getBusinessAdminsEntitySuccess: (state, action) => {
            state.businessOwner = state.businessAdmins.filter(item => item._id === action.payload.entity._id).length < 1
                ? [...state.businessAdmins, action.payload.entity]
                : state.businessAdmins
        },
        getDoorwayEntitySuccess: (state, action) => {
            state.doorways = state.doorways.filter(item => item._id === action.payload.entity._id).length < 1
                ? [...state.doorways, action.payload.entity]
                : state.doorways
        },
        getEntrywayEntitySuccess: (state, action) => {
            state.entryways = state.entryways.filter(item => item._id === action.payload.entity._id).length < 1
                ? [...state.entryways, action.payload.entity]
                : state.entryways
        },
        getPlaceCategoryEntitySuccess: (state, action) => {
            state.placeCategory = state.placeCategory.filter(item => item._id === action.payload.entity._id).length < 1
                ? [...state.placeCategory, action.payload.entity]
                : state.placeCategory
        },
        getCommunitiesServedEntitySuccess: (state, action) => {
            state.communitiesServed = state.communitiesServed.filter(item => item._id === action.payload.entity._id).length < 1
                ? [...state.communitiesServed, action.payload.entity]
                : state.communitiesServed
        },
        getFoodOptionsEntitySuccess: (state, action) => {
            state.foodOptions = state.foodOptions.filter(item => item._id === action.payload.entity._id).length < 1
                ? [...state.foodOptions, action.payload.entity]
                : state.foodOptions
        },
        getHearingImpairedSolutionEntitySuccess: (state, action) => {
            state.hearingImpairedSolutions = state.hearingImpairedSolutions.filter(item => item._id === action.payload.entity._id).length < 1
                ? [...state.hearingImpairedSolutions, action.payload.entity]
                : state.hearingImpairedSolutions
        },
        getLanguageSpokenEntitySuccess: (state, action) => {
            state.languageSpoken = state.languageSpoken.filter(item => item._id === action.payload.entity._id).length < 1
                ? [...state.languageSpoken, action.payload.entity,]
                : state.languageSpoken
        },
        getPublicTransportationEntitySuccess: (state, action) => {
            state.publicTransportationList = state.publicTransportationList.filter(item => item._id === action.payload.entity._id).length < 1
                ? [...state.publicTransportationList, action.payload.entity]
                : state.publicTransportationList
        },
        getVisualImpairedSolutionEntitySuccess: (state, action) => {
            state.visualImpairedSolutions = state.visualImpairedSolutions.filter(item => item._id === action.payload.entity._id).length < 1
                ? [...state.visualImpairedSolutions, action.payload.entity]
                : state.visualImpairedSolutions
        },
        getOwnerIdentityEntitySuccess: (state, action) => {
            state.ownerIdentity = state.ownerIdentity.filter(item => item._id === action.payload.entity._id).length < 1
                ? [...state.ownerIdentity, action.payload.entity]
                : state.ownerIdentity
        },
        getFlaggedReviewsSuccess: (state, action) => {
            state.flaggedReviews = [...action.payload]
        },
        getGlobalReviewsSuccess: (state, action) => {
            state.globalReviews = action.payload
        },
        getSubmittedByUserSuccess: (state, action) => {
            state.current.submittedBy = action.payload
        },
        getCurrentBusinessOwnersSuccess: (state, action) => {
            state.current.businessOwners = action.payload
        },
        filterSuccess: (state, action) => {
          state.current.filteredPlaces = action.payload
        },
            
        getTopRatedPlacesSuccess: (state, action) => {
            state.toprateplaces = action.payload
        },
        getTopRatedPlacesFailure: (state, action) => {
            state.error.toprateplaces = action.payload
        },
        getAdminstatsSuccess: (state, action) => {
            state.adminstats = action.payload
        },
        getTAdminstatsFailure: (state, action) => {
            state.error.adminstats = action.payload
        },
        getDemographicoverviewSuccess: (state, action) => {
            state.demographicoverview = action.payload
        },
        getBusinessAdminsSuccess: (state,action) =>{
            state.businessAdmins = action.payload
        },
        getBusinessAdminsFailure: (state,action) =>{
            state.businessAdmins = []
        },
        setPlaceIdSuccess: (state,action)=> {
            state.currentPlaceId = action.payload.placeId
        },
        /*   ADMIN  */

        //taxonomy
        ...adaptiveEquipmentSolution,
        ...bathroom,
        ...communitiesServed,
        ...doorway,
        ...entryway,
        ...foodOptions,
        ...hearingImpairedSolution,
        ...languageSpoken,
        ...placeCategory,
        ...publicTransportation,
        ...visualImpairedSolution,
        ...ownerIdentity,

    },
})


export default slice.reducer
