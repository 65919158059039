import {BrowserRouter}                                                     from 'react-router-dom';
import Div                                                                 from 'shared/Basic/Div'
import LinkSwitch                                                          from 'shared/Basic/LinkSwitch'
import {excerpt}                                                           from 'utils/helpers'
import {mapPopUpAddressStyle, mapPopUpDescriptionStyle, mapPopUpNameStyle} from './styles'


const PopUp = ({currentFeature}) => {
    return (
        <Div>
            <BrowserRouter>
                <Div id="map_cnt">
                    {/* <LinkSwitch
                        url={`/places/${currentFeature?.properties?.slug}`}
                        theme={mapPopUpNameStyle}
                    >
                        {currentFeature?.properties?.name}
                    </LinkSwitch> */}
                    <h4>{currentFeature?.properties?.name}</h4>
                    <Div id="map_location" theme={mapPopUpAddressStyle}>{currentFeature?.properties?.address}</Div>
                  
                    <Div>
                    <LinkSwitch id="explore"
                        url={`/places/${currentFeature?.properties?.slug}`}
                        theme={mapPopUpNameStyle}
                    >
                        EXPLORE BUSINESS
                    </LinkSwitch>
                    </Div>
                </Div>
            </BrowserRouter>

        </Div>
    )
}

export default PopUp
