import {search}                        from 'config/icons'
import {useContext, useState}                    from 'react'
import {useSelector}                   from 'react-redux'
import Div                             from 'shared/Basic/Div'
import Icon                            from 'shared/Basic/Icon'
import LinkSwitch                      from 'shared/Basic/LinkSwitch'
import {menuPanelContext}              from 'shared/Containers/MenuPanelController'
import {
    headerButtonSignInStyle,
    headerButtonSignUpStyle,
    headerButtonWrapperStyle,
    headerMenuIconWrapperStyle,
    headerSearchIconStyle,
    headerSearchWrapperStyle
}                                      from 'shared/Layout/styles/header'
import HeaderMenuUserDropdown          from './HeaderMenuUserDropdown'
import {headerMenuControlWrapperStyle} from './styles'

const HeaderMenuControls = () => {
    const {isAuthenticated, isAdmin, nameFirst} = useSelector(state => state.user)
    const {setPanel, currentPanel} = useContext(menuPanelContext)
    const [isOpen, menuOpen] = useState("false");
    const ToggleMenu = () => {
        menuOpen(!isOpen); 
    };

    return (
        <Div theme={headerMenuControlWrapperStyle}>
            <Div
                url={
                    isAuthenticated && isAdmin
                        ? '/admin'
                        : isAuthenticated
                        ? '/dashboard'
                        : '/signin'
                }
                theme={headerMenuIconWrapperStyle}
            >
                
                    <LinkSwitch
                            url={'https://www.deiresources.inclusiveguide.com/'}
                            className="resourcesBtn"
                        >
                            DEI Resources
                        </LinkSwitch>


           
                {(isAuthenticated && (
                   <HeaderMenuUserDropdown
                        nameFirst={nameFirst}
                    />
                     

                )) || (
                    <Div theme={headerButtonWrapperStyle} className='headerButtonWrapperStyle'>
                        <LinkSwitch
                            url={'/signin'}
                            theme={headerButtonSignInStyle}
                            className='signinstyle'
                        >
                            Login
                        </LinkSwitch>
                        <LinkSwitch
                            url={'/signup'}
                            theme={headerButtonSignUpStyle}
                            className='signupstyle'
                        >
                            Register
                        </LinkSwitch>
                        <div className={isOpen ? "menu_icon_wrapper" : "menu_icon_wrapper open"}>
                            <div className="menu_icon" onClick={ToggleMenu}>
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                        </div>
                        <div className={isOpen ? "dropdown" : "dropdown open"}>
                            <ul>
                                <li>
                                <LinkSwitch
                                    url={'/about'}
                                >
                                    About
                                </LinkSwitch>
                                </li>
                                <li>
                                <LinkSwitch
                                    url={'/faq'}
                                >
                                    FAQ
                                </LinkSwitch>
                                </li>
                                <li>
                                <LinkSwitch
                                    url={'https://inclusive-guide.s3.us-east-2.amazonaws.com/assets/InclusiveGuide-PrivacyPolicy-11.18.21.pdf'}
                                >
                                    Privacy Policy
                                </LinkSwitch>
                                </li>
                                <li>
                                <LinkSwitch
                                    url={'https://inclusive-guide.s3.us-east-2.amazonaws.com/assets/InclusiveGuide-TermsOfService-01.18.21.pdf'}
                                >
                                    Terms of Service
                                </LinkSwitch>
                                </li>
                                <li>
                                    <LinkSwitch
                                    url={'https://docs.google.com/forms/d/1jVOQmYLDvdTEiGICDmZY9wmSmKirNo1oYNZxO-UjylE/edit?ts=60c78b0f'}
                                    >
                                        <span className='feedback_btn'>
                                            Leave us Feedback
                                        </span>
                                        
                                    </LinkSwitch>
                                </li>
                            </ul>
                        </div>
                    </Div>
                )}
            { isAuthenticated && 
                    <LinkSwitch
                            url={'/dashboard'}
                            className="dashboardBtn"
                        >
                            Dashboard
                        </LinkSwitch>


            }
            </Div>
        </Div>

    )
}

export default HeaderMenuControls
