import {EMAIL, MULTI_SELECT, NUMBER, STATE_CITY, TEXT, TEL, HOURS_OPEN, SELECT} from 'config/variables'
import * as Yup                                 from 'yup'
import {phoneRegExp}   from '../../../../utils/helpers'


export const submitPlaceFields = [
    {
        name: 'name',
        inputLabel: 'Place Name',
        type: TEXT
    },
    {
        name: 'website',
        inputLabel: 'Website',
        type: TEXT
    },
    {
        name: 'address1',
        inputLabel: 'Address ',
        type: TEXT
    },
    {
        name: 'address2',
        inputLabel: 'Address 2 ',
        type: TEXT
    },
    {
        name: ['state', 'city'], // needs to be state, city
        inputLabel: 'City ',
        type: STATE_CITY
    },
    {
        name: 'zip',
        inputLabel: 'Zip ',
        type: TEXT,
      //  hidden: true
    },
    {
        name: 'longitude',
        inputLabel: 'Longitude ',
        type: NUMBER,
        disabled: true,
      //  hidden: true
    },
    {
        name: 'latitude',
        inputLabel: 'Latitude ',
        type: NUMBER,
        disabled: true,
       // hidden: true
    },
    //hours 
    {
        name: 'hours',
        inputLabel: 'Open Hours',
        type: HOURS_OPEN

    },
    {
        name: 'phoneNumber',
        inputLabel: 'Phone Number',
        type: TEL

    },
    {
        name: 'emailAddress',
        inputLabel: 'email Address',
        type: EMAIL

    },
    {
        name: 'categories',
        inputLabel: 'Categories',
        type: MULTI_SELECT
    },
    {
        name: 'communitiesServed',
        inputLabel: 'Affinity Space',
        type: MULTI_SELECT
    }
]


/**
 *
 * Validation Objects written with Yup
 * https://github.com/jquense/yup#api
 *
 */

export const validateSubmitPlace = Yup.object().shape({
    name: Yup
        .string()
        .max(300)
        .required('Required'),
    address1: Yup
        .string()
        .required('Required'),
    state: Yup
        .string()
        .required('Required'),
    city: Yup
        .string()
        .required('Required'),
    zip: Yup
        .string()
        .required('Required'),
    // phoneNumber: Yup
    //     .string()
    //     .matches(phoneRegExp, 'Phone number is not valid'),
    emailAddress: Yup.string()
        .email('invalid email')
}) 
