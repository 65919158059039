import {takeEvery}                  from '@redux-saga/core/effects'
import {
    getHearingImpairedSolution,
    getHearingImpairedSolutionList
}                                   from 'features/place/admin/taxonomy/hearingImpairedSolution/services'
import {call, put}                  from 'redux-saga/effects'
import {createEntity, updateEntity} from 'utils/abstractions/crud'
import {setFormData}                from 'utils/abstractions/setFormData'

export function* createHearingImpairedSolution({payload}) {
    const {_id, token, name, description,} = payload

    //add to formdata so api can read
    const hearingImpairedSolution = new FormData()
    const fields = [{name}, {description}]
    for (let field of fields)
        setFormData(hearingImpairedSolution, field)

    const createdHearingImpairedSolution = yield call(createEntity, {
        _id: _id,
        token: token,
        body: hearingImpairedSolution,
        slug: 'hearing-impaired-solution'
    })
    if (!createdHearingImpairedSolution.error) {
        yield put({type: 'place/listHearingImpairedSolution'})
        // yield put(push('/admin/places/update/' + crea.slug))

    } else {
        yield put({type: 'place/createHearingImpairedSolutionFailure', payload})

    }
}

export function* listHearingImpairedSolution() {
    try {
        const payload = yield call(getHearingImpairedSolutionList)
        if (!payload.error) {
            yield put({type: 'place/listHearingImpairedSolutionSuccess', payload})
        } else {
            yield put({type: 'place/listHearingImpairedSolutionFailure', payload})
        }
    } catch (error) {
        yield put({type: 'place/listHearingImpairedSolutionFailure', error})
    }
}

export function* getHearingImpairedSolutionDetail({payload}) {
    try {
        const hearingImpairedSolution = yield call(getHearingImpairedSolution, payload)
        if (!hearingImpairedSolution.error) {
            yield put({type: 'place/getHearingImpairedSolutionSuccess', payload: hearingImpairedSolution})
        } else {
            yield put({type: 'place/getHearingImpairedSolutionFailure', payload: hearingImpairedSolution})
        }
    } catch (error) {
        yield put({type: 'place/getHearingImpairedSolutionFailure', payload: error})
    }
}

export function* updateHearingImpairedSolutionDetail({payload}) {
    const {slug, _id, token, name, description} = payload

    //add to formData so api can read
    const hearingImpairedSolution = new FormData()
    const fields = [{name}, {description}]
    for (let field of fields)
        setFormData(hearingImpairedSolution, field)

    try {
        const updated = yield call(updateEntity, {
            slug: slug,
            parentSlug: 'hearing-impaired-solution',
            body: hearingImpairedSolution,
            _id: _id,
            token: token,
        })
        if (!updated.error) {
            yield put({type: 'place/updateHearingImpairedSolutionSuccess', payload: updated})
            yield put({
                type: 'site/setNotification',
                payload: {
                    notification: 'Updated HearingImpairedSolution',
                    theme: 'green'
                }
            })

        } else {
            yield put({type: 'place/updateHearingImpairedSolutionFailure', payload: updated})
        }
    } catch (error) {
        yield put({type: 'place/updateHearingImpairedSolutionFailure'})
    }
}


export function* watchCreateHearingImpairedSolution() {
    yield takeEvery('place/createHearingImpairedSolution', createHearingImpairedSolution)
}

export function* watchGetHearingImpairedSolutionList() {
    yield takeEvery('place/listHearingImpairedSolution', listHearingImpairedSolution)
}

export function* watchGetHearingImpairedSolutionDetail() {
    yield takeEvery('place/getHearingImpairedSolution', getHearingImpairedSolutionDetail)
}

export function* watchUpdateHearingImpairedSolutionDetail() {
    yield takeEvery('place/updateHearingImpairedSolution', updateHearingImpairedSolutionDetail)
}

