import {API} from 'config/variables'

/**
 * to perform crud on admin
 * get all places
 * get a single admin
 * update single admin
 * delete single admin
 */

export const addPlace = ({_id, token, place}) =>
    fetch(`${API}/place/create/${_id}`, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            Authorization: `Bearer ${token}`
        },
        body: place
    })
        .then(response => {
            return response.json()
        })
        .catch(error => {
            return error
        })

export const getPlaces = () =>
    fetch(`${API}/places?limit=${50}`, {
        method: 'GET'
    })
        .then(response => {
            return response.json()
        })
        .catch(error => {
            return error
        })
/* export const getReports = () =>
    fetch(`${API}/reports?limit=${50}`, {
            method: 'GET'
        })
            .then(response => {
                return response.json()
            })
            .catch(error => {
                return error
            }) */
    
export const getPendingPlaces = () =>
    fetch(`${API}/pending-places?limit=undefined`, {
        method: 'GET'
    })
        .then(response => {
            return response.json()
        })
        .catch(error => {
            return error
        })

export const deletePlace = ({_id, token, slug}) =>
    fetch(`${API}/place/${slug}/${_id}`, {
        method: 'DELETE',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
        }
    })
        .then(response => {
            return response.json()
        })
        .catch(error => {
            return error
        })

export const getPlace = ({slug}) =>
    fetch(`${API}/place/${slug}`, {
        method: 'GET'
    })
        .then(response => {
            return response.json()
        })
        .catch(error => {
            return error
        })

export const getResourceByslug = ({slug}) =>
    fetch(`${API}/resource/by/slug/${slug}`, {
        method: 'GET'
    })
        .then(response => {
            return response.json()
        })
        .catch(error => {
            return error
      })

export const getPlaceById = ({_id}) =>
    fetch(`${API}/place/by/id/${_id}`, {
        method: 'GET'
    })
        .then(response => {
            return response.json()
        })
        .catch(error => {
            return error
        })

export const updatePlace = ({slug, _id, token, place}) =>
    fetch(`${API}/place/${slug}/${_id}`, {
        method: 'PUT',
        headers: {
            Accept: 'application/json',
            Authorization: `Bearer ${token}`
        },
        body: place
    })
        .then(response => {
            return response.json()
        })
        .catch(error => {
            return error
        })

export const addPlaceFromBoone = ({_id, token, place}) =>
    fetch(`${API}/place/create-from-boone/${_id}`, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            Authorization: `Bearer ${token}`
        },
        body: place
    })
        .then(response => {
            return response.json()
        })
        .catch(error => {
            return error
        })

export const addPlaceFromMapbox = ({_id, token, place}) =>
    fetch(`${API}/place/create-from-mapbox/${_id}`, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            Authorization: `Bearer ${token}`
        },
        body: place
    })
        .then(response => {
            return response.json()
        })
        .catch(error => {
            return error
        })

export const getAlgoliaPlaces = ({input, longitude, latitude}) => {
            const queryParams = new URLSearchParams({
              input: input,
              longitude: longitude.toFixed(2),
              latitude: latitude.toFixed(2),
            });
          
            return fetch(`${API}/placessearch?${queryParams}`, {
              method: 'GET',
            })
              .then(response => response.json())
              .then(data => {
                return data.hits.reduce((acc = [], cv) => {
                  acc.push(!!cv._id ? cv : cv[0]);
                  return acc;
                }, []);
              })
              .catch(error => {
                return error;
              });
 };

          


/* export const getAlgoliaPlaces = ({input, index, longitude, latitude}) =>
    index.search(input, {
        hitsPerPage: 50,
        aroundLatLng: `${latitude.toFixed(2)}, ${longitude.toFixed(2)}`,
        aroundRadius: 100000
    })
        .then(response => {
            return response.hits.reduce((acc = [], cv) => {
                acc.push(!!cv._id ? cv : cv[0])

                return acc
            }, [])
        })
        .catch(error => {
            return error
        }) */


export const getPlaceCategories = ({payload}) => {
    return (
        fetch(`${API}/place-category/by/name/or/description/${payload}`, {
            method: 'GET',
        })
            .then(response => {
                return response.json()
            })
            .catch(error => {
                return error
            })
    )
}

export const handlePageView = ({_id, token, viewedAt, placeId}) => {
    fetch(`${API}/place/${placeId}/handle-page-view/${_id}`, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
        },
        body: JSON.stringify({
            viewedBy: _id,
            viewedAt: viewedAt
        })
    })
        .then(response => {
            return response.json()
        })
        .catch(error => {
            return error
        })
}

export const handleSuccessfulPlaceSubmission = ({_id, token, submittedByEmail, 
    submittedByNameFirst, submittedPlaceSlug, placeName, placeId}) => {
    fetch(`${API}/place/successful-place-submission/${_id}`, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
        },
        body: JSON.stringify({
            email: submittedByEmail,
            nameFirst: submittedByNameFirst,
            slug: submittedPlaceSlug,
            placeName: placeName,
            placeId: placeId
        })
    })
        .then(response => {
            return response.json()
        })
        .catch(error => {
            return error
        })
}

export const handleCreateReportPlaceSubmission = (
    {_id, token, submittedByEmail, 
    submittedByNameFirst, submittedPlaceSlug, placeName, placeId
}) => {
    fetch(`${API}/place/successful-create-report-submission/${_id}`, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
        },
        body: JSON.stringify({
            email: submittedByEmail,
            nameFirst: submittedByNameFirst,
            slug: submittedPlaceSlug,
            placeName: placeName,
            placeId: placeId
        })
    })
        .then(response => {
            return response.json()
        })
        .catch(error => {
            return error
        })
        
}

export const getTopRatedPlaces = () =>
fetch(`${API}/places/toprated?limit=${50}`, {
    method: 'GET'
})
    .then(response => {
        return response.json()
    })
    .catch(error => {
        return error
})

export const getAdminstats = () =>
fetch(`${API}/adminstats?limit=${50}`, {
    method: 'GET'
})
    .then(response => {
        return response.json()
    })
    .catch(error => {
        return error
})

export const getDemographicoverviews = ({_id}) =>{

    return (
        fetch(`${API}/demographics/${_id}?limit=${50}`, {
            method: 'GET'
        })
            .then(response => {
                console.log("API Response:", response);

                return response.json()
            })
            .catch(error => {
                return error
        })
    )
   
}


export const getPlaceAdmins = ({_id}) =>{

    return (
        fetch(`${API}/businessAdmins/${_id}`, {
            method: 'GET'

        })
            .then(response => {
                console.log("API Response:", response);

                return response.json()
            })
            .catch(error => {
                return error
        })
    )
   
}
