import Gravatar                                                                   from 'react-gravatar'
import Div                                                                        from '../../Basic/Div'
import S3Img                                                                      from '../../Basic/S3Img'
import {searchResultAvatarStyle, searchResultInfoStyle, searchResultWrapperStyle} from './styles'

const BusinessOwners = ({owner}) =>
    <Div
        theme={{
            ...searchResultWrapperStyle,
        }}>
        <Div theme={searchResultAvatarStyle}>
            {(!!owner.avatar && (
                <S3Img url={owner.avatar}/>
            )) || (
                <Gravatar email={owner.email} size={100}/>
            )}
        </Div>

        <Div theme={searchResultInfoStyle}>
            <Div>{owner.nameFirst} {owner.nameLast}</Div>
            <Div>{owner.email}</Div>
            <Div>{owner._id}</Div>
        </Div>
    </Div>

export default BusinessOwners
