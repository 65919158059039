import {bookmark}                 from 'config/icons'
import {adminDashboardMenu}       from 'config/menus/dashboard/admin'
import {userDashboardMenu}        from 'config/menus/dashboard/user'
import {useRef}                   from 'react'
import {useDispatch, useSelector} from 'react-redux'
import Div                        from 'shared/Basic/Div'
import MotionDiv                  from 'shared/Basic/MotionDiv'
import Span                       from 'shared/Basic/Span'
import useOutsideAlerter          from 'utils/clickOutside'
import HeaderDashboardMenu        from './HeaderDashboardMenu'
import {
    headerAccountMenuDropdownInnerWrapperStyle,
    headerAccountMenuDropdownWrapperStyle,
    headerAccountMenuLinkStyle
}                                 from './styles'

const HeaderMenuUserDropdownMenu = () => {
    const dispatch = useDispatch()
    const {isAdmin, user} = useSelector(state => state.user)
    const {placesOwned} = user
    const {closeHeaderMenuDropdown} = useSelector(state => state.site)
    const menuRef = useRef(null)
    const variants = {
        initial: {
            height: 0,
            transition: {
                duration: 0.25,
                ease: 'easeOut'
            }
        },
        open: {
            height: 'auto',
        }
    }
    const myBusinessMenuOption = {
        url: '/dashboard/my-businesses',
        icon: bookmark,
        title: 'My Business',
        active: ['business'],
    }

    useOutsideAlerter(menuRef, {type: 'site/closeHeaderMenuDropdown', payload: true})

    return (
        <MotionDiv
            intial={{height: 0}}
            animate={closeHeaderMenuDropdown === false ? 'open' : 'initial'}
            exit={'initial'}
            variants={variants}
            theme={headerAccountMenuDropdownWrapperStyle}
            ref={menuRef}
        >
            <Div theme={headerAccountMenuDropdownInnerWrapperStyle}>
                <HeaderDashboardMenu
                    menu={
                        isAdmin
                            ? adminDashboardMenu
                            : placesOwned?.length > 0
                            ? [...userDashboardMenu, myBusinessMenuOption]
                            : userDashboardMenu
                    }
                />
                <Span
                    theme={{...headerAccountMenuLinkStyle, ...headerAccountMenuLinkStyle.signOut}}
                    onClick={() => dispatch({type: 'user/signOut'})}
                >
                    Sign Out
                </Span>
            </Div>
        </MotionDiv>
    )
}

export default HeaderMenuUserDropdownMenu
