import {takeEvery}                  from '@redux-saga/core/effects'
import {getDoorway, getDoorwayList} from 'features/place/admin/taxonomy/doorway/services'
import {call, put}                  from 'redux-saga/effects'
import {createEntity, updateEntity} from 'utils/abstractions/crud'
import {setFormData}                from 'utils/abstractions/setFormData'

export function* createDoorway({payload}) {
    const {_id, token, name, description,} = payload

    //add to formdata so api can read
    const doorway = new FormData()
    const fields = [{name}, {description}]
    for (let field of fields)
        setFormData(doorway, field)

    const createdDoorway = yield call(createEntity, {
        _id: _id,
        token: token,
        body: doorway,
        slug: 'doorway'
    })
    if (!createdDoorway.error) {
        yield put({type: 'place/listDoorway'})
        // yield put(push('/admin/places/update/' + crea.slug))

    } else {
        yield put({type: 'place/createDoorwayFailure', payload})

    }
}

export function* listDoorway() {
    try {
        const payload = yield call(getDoorwayList)
        if (!payload.error) {
            yield put({type: 'place/listDoorwaySuccess', payload})
        } else {
            yield put({type: 'place/listDoorwayFailure', payload})
        }
    } catch (error) {
        yield put({type: 'place/listDoorwayFailure', error})
    }
}

export function* getDoorwayDetail({payload}) {
    try {
        const doorway = yield call(getDoorway, payload)
        if (!doorway.error) {
            yield put({type: 'place/getDoorwaySuccess', payload: doorway})
        } else {
            yield put({type: 'place/getDoorwayFailure', payload: doorway})
        }
    } catch (error) {
        yield put({type: 'place/getDoorwayFailure', payload: error})
    }
}

export function* updateDoorwayDetail({payload}) {
    const {slug, _id, token, name, description} = payload

    //add to formData so api can read
    const doorway = new FormData()
    const fields = [{name}, {description}]
    for (let field of fields)
        setFormData(doorway, field)

    try {
        const updated = yield call(updateEntity, {
            slug: slug,
            parentSlug: 'doorway',
            body: doorway,
            _id: _id,
            token: token,
        })
        if (!updated.error) {
            yield put({type: 'place/updateDoorwaySuccess', payload: updated})
            yield put({
                type: 'site/setNotification',
                payload: {
                    notification: 'Updated Doorway',
                    theme: 'green'
                }
            })

        } else {
            yield put({type: 'place/updateDoorwayFailure', payload: updated})
        }
    } catch (error) {
        yield put({type: 'place/updateDoorwayFailure'})
    }
}


export function* watchCreateDoorway() {
    yield takeEvery('place/createDoorway', createDoorway)
}

export function* watchGetDoorwayList() {
    yield takeEvery('place/listDoorway', listDoorway)
}

export function* watchGetDoorwayDetail() {
    yield takeEvery('place/getDoorway', getDoorwayDetail)
}

export function* watchUpdateDoorwayDetail() {
    yield takeEvery('place/updateDoorway', updateDoorwayDetail)
}

