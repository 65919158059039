import {API} from 'config/variables'

export const getGlobalReviews = ({id, token, lat, lng}) =>
    fetch(`${API}/reviews/global/`, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            Authorization: `Bearer ${token}`
        },
        body:JSON.stringify({
            lat: lat,
            lng: lng,
            id: id
        })
    })
        .then(response => {
            return response.json()
        })
        .catch(error => {
            console.log(error)
            return error
        })
