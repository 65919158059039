import {CORS, MAPBOX_HOST, MAPBOX_PUBLIC} from 'config/variables'

export const getMapboxPlacesList = ({input, longitude, latitude, radius}) => {
    const config = {
        ACCESS_TOKEN: MAPBOX_PUBLIC,
        FUZZY_MATCH: true,
        LIMIT: 10,
        RADIUS: radius,
        PROXIMITY: `${longitude},${latitude}`,
        BB_OFFSET_LAT: 1, // each 1 degree of lat = 69 miles
        BB_OFFSET_LONG: 1 // each 1 degree of long = 54.6 miles
    }

    const fetchString = `${CORS}${MAPBOX_HOST}${input}.json?types=poi`
            + `&proximity=${config.PROXIMITY}`
            + `&country=us&fuzzyMatch=${config.FUZZY_MATCH}`
            + `&limit=${config.LIMIT}`
            + `&access_token=${config.ACCESS_TOKEN}`
            + `&bbox=${longitude - config.BB_OFFSET_LONG},${latitude - config.BB_OFFSET_LAT},${longitude + config.BB_OFFSET_LONG},${latitude  + config.BB_OFFSET_LAT}`
    return (
        fetch(fetchString, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                "Content-Type": "application/json"
            },
        })
            .then(response => {
                return response.json()
            })
            .catch(error => {
                return error
            })
    )
}
