/* WATCHERS */

import {push}                            from 'connected-react-router'
import {deleteAdaptiveEquipmentSolution} from 'features/place/admin/taxonomy/adaptiveEquipmentSolution/services'
import {deleteBathroom}                  from 'features/place/admin/taxonomy/bathroom/services'
import {deleteCommunitiesServed}         from 'features/place/admin/taxonomy/communitiesServed/services'
import {deleteDoorway}                   from 'features/place/admin/taxonomy/doorway/services'
import {deleteEntryway}                  from 'features/place/admin/taxonomy/entryway/services'
import {deleteFoodOptions}               from 'features/place/admin/taxonomy/foodOptions/services'
import {deleteHearingImpairedSolution}   from 'features/place/admin/taxonomy/hearingImpairedSolution/services'
import {deleteLanguageSpoken}            from 'features/place/admin/taxonomy/languageSpoken/services'
import {deleteOwnerIdentity}             from 'features/place/admin/taxonomy/ownerIdentity/services'
import {deletePlaceCategory}             from 'features/place/admin/taxonomy/placeCategory/services'
import {deletePublicTransportation}      from 'features/place/admin/taxonomy/publicTransportation/services'
import {deleteVisualImpairedSolution}    from 'features/place/admin/taxonomy/visualImpairedSolution/services'
import {deletePlace}                     from 'features/place/services'
import {deleteAdaptiveEquipment}         from 'features/user/admin/taxonomy/adaptiveEquipment/services'
import {deleteBodyModification}          from 'features/user/admin/taxonomy/bodyModification/services'
import {deleteGender}                    from 'features/user/admin/taxonomy/gender/services'
import {deleteHearingImpairment}         from 'features/user/admin/taxonomy/hearingImpairment/services'
import {deleteLanguage}                  from 'features/user/admin/taxonomy/language/services'
import {deleteMethodOfCommunication}     from 'features/user/admin/taxonomy/methodOfCommunication/services'
import {deletePhysicalAppearance}        from 'features/user/admin/taxonomy/physicalAppearance/services'
import {deletePronoun}                   from 'features/user/admin/taxonomy/pronoun/services'
import {deleteRace}                      from 'features/user/admin/taxonomy/race/services'
import {deleteServiceAnimal}             from 'features/user/admin/taxonomy/serviceAnimal/services'
import {deleteSexualOrientation}         from 'features/user/admin/taxonomy/sexualOrientation/services'
import {deleteVisualImpairment}          from 'features/user/admin/taxonomy/visualImpairment/services'
import {deleteReview, deleteUser}        from 'features/user/services'
import {call, put, takeLatest}           from 'redux-saga/effects'


export function* attemptDestroyEntity({payload}) {
    yield put({type: 'site/confirmDestroyEntity', payload: payload})
}

export function* destroyEntity({payload}) {
    const deleteSwitch = () => {
        switch (payload.type) {
            case 'adaptive-equipment':
                return deleteAdaptiveEquipment
            case 'adaptive-equipment-solution':
                return deleteAdaptiveEquipmentSolution
            case 'body-modification':
                return deleteBodyModification
            case 'entryway':
                return deleteEntryway
            case 'doorway':
                return deleteDoorway
            case 'hearing-impairment':
                return deleteHearingImpairment
            case 'hearing-impaired-solution':
                return deleteHearingImpairedSolution
            case 'gender':
                return deleteGender
            case 'language':
                return deleteLanguage
            case 'method-of-communication':
                return deleteMethodOfCommunication
            case 'physical-appearance':
                return deletePhysicalAppearance
            case 'pronoun':
                return deletePronoun
            case 'public-transportation':
                return deletePublicTransportation
            case 'race':
                return deleteRace
            case 'service-animal':
                return deleteServiceAnimal
            case 'sexual-orientation':
                return deleteSexualOrientation
            case 'bathroom':
                return deleteBathroom
            case 'communities-served':
                return deleteCommunitiesServed
            case 'food-options':
                return deleteFoodOptions
            case 'language-spoken':
                return deleteLanguageSpoken
            case 'place-category':
                return deletePlaceCategory
            case 'place':
                return deletePlace
            case 'user':
                return deleteUser
            case 'review':
                return deleteReview
            case 'visual-impairment':
                return deleteVisualImpairment
            case 'visual-impaired-solution':
                return deleteVisualImpairedSolution
            case 'owner-identity':
                return deleteOwnerIdentity

            default:
                return null
        }
    }
    const destroyed = yield call(deleteSwitch(), payload)
    const {objectID, type} = payload

    if (!destroyed.error) {
        yield put({type: 'site/destroyEntitySuccess'})
        yield put({
            type: 'site/setNotification',
            payload: {
                notification: 'Deleted Successfully',
                theme: 'green'
            }
        })

        if (type === 'user') {
            yield put({type: 'user/destroyUserSuccess', payload: {objectID}})
            yield put({type: 'user/signOut'})
            yield put(push('/signin'))

        } else if (type === 'place') {
            yield put({type: 'place/destroyPlaceSuccess', payload: {objectID}})
        }
        // yield put({type: 'shop/destroyProductSuccess', payload: {objectID}})
        // yield put({type: 'shop/getShop'})
        yield put(push('/admin/'))
    } else {
        yield put({type: 'admin/destroyProductFailure'})
    }
}

export function* watchAttemptDestroyEntity() {
    yield takeLatest('site/attemptDestroyEntity', attemptDestroyEntity)
}

export function* watchDestroyEntity() {
    yield takeLatest('site/destroyEntity', destroyEntity)
}
