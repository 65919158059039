import {
    auto,
    hidden
} from 'utils/themer'

export const headerMenuPanelVariants = {
    open: (height = 1000) => ({
        clipPath: `circle(${height * 2 + 200}px at 100% 0)`,
        overflowY: auto,
        transition: {
            type: 'spring',
            stiffness: 20,
            restDelta: 2
        }
    }),
    closed: {
        clipPath: 'circle(30px at 110% 0)',
        overflowY: hidden,
        transition: {
            //  delay: 0.5,
            type: 'spring',
            stiffness: 400,
            damping: 40
        }
    }
}

