import {memo}                                                                           from 'react'
import Div                                                                              from 'shared/Basic/Div'
import Span                                                                             from 'shared/Basic/Span'
import {defaultHelperTextStyle, selectFieldErrorMessageStyle}                           from 'shared/Fields/styles'
import {arrayEquals}                                                                    from 'utils/helpers'
import {
    likertOptionStyle,
    likertOptionsWrapperStyle,
    likertStyle,
    likertContent,
    leftSide,
    rightSide,
    suggestContainer,
    iconStyle,
    getLikertHeadingStyle
}                                                                                       from './styles'
import Icon                                                                             from 'shared/Basic/Icon'
import {doorOpen, handHoldingHeart, spa}                                                from 'config/icons'

const ratingIcons = {
    safe: spa,
    welcome: doorOpen,
    celebrated: handHoldingHeart
}

const Likert = memo(({field, helperText, className, errorMessage, formik, value}) => {
    //important structure -- backend dependent on array represented as ['text', Number]
    const optionsArray = [['Strongly Disagree', 1], ['Disagree', 2], ['Neutral', 3], ['Agree', 4], ['Strongly Agree', 5]]

    return (
        <Div theme={likertStyle} className={className ? className : ''}>
            <Div theme={getLikertHeadingStyle(field?.color)}>{field.inputLabel}</Div>
            <Div theme={likertContent}>
                <Div theme={leftSide}>
                    <Icon icon={ratingIcons[field?.name]} theme={iconStyle}/>
                </Div>
                <Div theme={rightSide}>
                    <Div theme={defaultHelperTextStyle}>{helperText}</Div>
                    <Div theme={suggestContainer}>
                        <Div>Strongly Agree</Div>
                        <Div>Strongly Disagree</Div>
                    </Div>
                    <Div theme={likertOptionsWrapperStyle} className="rating_sec">
                        {optionsArray?.length > 0 && optionsArray?.slice(0)?.reverse().map(o => (
                            [<Div
                                key={o}
                                onClick={() => formik.setFieldValue(field?.name, o)}
                                theme={{
                                    ...likertOptionStyle(value?.length > 1 ? arrayEquals(value, o) : o?.[0] === value?.[0], field?.color, o[1])
                                }}
                            >
                                {o[1]}
                            </Div>]
                        ))}
                    </Div>
                </Div>
            </Div>
            <Span theme={selectFieldErrorMessageStyle}>{errorMessage}</Span>
        </Div>
    )
})


export default Likert
