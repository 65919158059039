import {check}              from 'config/icons'
import ReactCrop            from 'react-image-crop'
import {Portal}             from 'react-portal'
import Div                  from 'shared/Basic/Div'
import Icon                 from 'shared/Basic/Icon'
import Img                  from 'shared/Basic/Img'
import MotionDiv            from 'shared/Basic/MotionDiv'
import {imageDropZoneStyle} from './styles'

const CropPortal = ({isOpen, setIsOpen, onCropModalClose, makeClientCrop, uploadedImages, setUploadedImages}) => {
    const cropPortalVariants = {
        initial: {
            top: '100vh',
            opacity: 0
        },
        animate: {
            top: '5vh',
            opacity: 1
        }
    }

    const handleCropChange = (crop, index) => {
        uploadedImages[index].crop = crop
        setUploadedImages([...uploadedImages])
    }

    const onClose = () => {
        onCropModalClose()
        setIsOpen(false)
    }

    return (
        <Portal>
            <MotionDiv
                variants={cropPortalVariants}
                initial="initial"
                animate={isOpen ? 'animate' : 'initial'}
                theme={imageDropZoneStyle.portal}
            >
                <Div theme={imageDropZoneStyle.portalInner}>
                    <Div
                        theme={imageDropZoneStyle.close}
                        onClick={onClose}
                    >
                        <Icon icon={check} theme={imageDropZoneStyle.closeIcon}/>
                    </Div>
                    <Div theme={imageDropZoneStyle.portalContent}>
                        {uploadedImages.map((uploadedImage, index) => {
                            const {uploadBlob, previewBlob, crop} = uploadedImage
                            return (
                                <Div key={index} theme={imageDropZoneStyle.cropRow}>
                                    {uploadBlob && (
                                        <ReactCrop
                                            src={uploadBlob}
                                            crop={crop}
                                            onChange={(c) => handleCropChange(c, index)}
                                            onComplete={(crop, percentageCrop) => makeClientCrop(crop, percentageCrop, index)}
                                        />
                                    )}

                                    {previewBlob && (
                                        <Div theme={imageDropZoneStyle.cropPreview}>
                                            <Img
                                                alt="Crop preview"
                                                src={previewBlob}
                                            />
                                        </Div>
                                    )}
                                </Div>
                            )
                        })}
                    </Div>
                </Div>
            </MotionDiv>
        </Portal>
    )
}

export default CropPortal
