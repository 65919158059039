import {motion}           from 'framer-motion'
import {useContext}       from 'react'
import Div                from 'shared/Basic/Div'
import {menuPanelContext} from 'shared/Containers/MenuPanelController'
import {menuToggleStyle}  from './styles'

const Path = props => (
    <motion.path
        fill="transparent"
        strokeWidth="3"
        stroke="hsl(0, 0%, 18%)"
        strokeLinecap="round"
        {...props}
    />
)

const MenuToggle = ({onClick}) => {
    const {currentPanel} = useContext(menuPanelContext)
    const isMobileHeaderMenu = currentPanel && currentPanel === 'mobile-header-menu-panel'

    return (
        <Div onClick={onClick} theme={menuToggleStyle}>
            <svg width="23" height="23" viewBox="0 0 23 23">
                <Path
                    initial={'closed'}
                    animate={isMobileHeaderMenu ? 'open' : 'closed'}
                    variants={{
                        closed: {d: 'M 2 2.5 L 20 2.5'},
                        open: {d: 'M 3 16.5 L 17 2.5'}
                    }}
                />
                <Path
                    d="M 2 9.423 L 20 9.423"
                    initial={'closed'}
                    animate={isMobileHeaderMenu ? 'open' : 'closed'}
                    variants={{
                        closed: {opacity: 1},
                        open: {opacity: 0}
                    }}
                    transition={{duration: 0.1}}
                />
                <Path
                    initial={'closed'}
                    animate={isMobileHeaderMenu ? 'open' : 'closed'}
                    variants={{
                        closed: {d: 'M 2 16.346 L 20 16.346'},
                        open: {d: 'M 3 2.5 L 17 16.346'}
                    }}
                />
            </svg>
        </Div>
    )
}


export default MenuToggle

