import {takeEvery}                  from '@redux-saga/core/effects'
import {
    getAdaptiveEquipmentSolution,
    getAdaptiveEquipmentSolutionList
}                                   from 'features/place/admin/taxonomy/adaptiveEquipmentSolution/services'
import {call, put}                  from 'redux-saga/effects'
import {createEntity, updateEntity} from 'utils/abstractions/crud'
import {setFormData}                from 'utils/abstractions/setFormData'

export function* createAdaptiveEquipmentSolution({payload}) {
    const {_id, token, name, description,} = payload

    //add to formdata so api can read
    const adaptiveEquipmentSolution = new FormData()
    const fields = [{name}, {description}]
    for (let field of fields)
        setFormData(adaptiveEquipmentSolution, field)

    const createdAdaptiveEquipmentSolution = yield call(createEntity, {
        _id: _id,
        token: token,
        body: adaptiveEquipmentSolution,
        slug: 'adaptive-equipment-solution'
    })
    if (!createdAdaptiveEquipmentSolution.error) {
        yield put({type: 'place/listAdaptiveEquipmentSolution'})
        // yield put(push('/admin/places/update/' + crea.slug))

    } else {
        yield put({type: 'place/createAdaptiveEquipmentSolutionFailure', payload})

    }
}

export function* listAdaptiveEquipmentSolution() {
    try {
        const payload = yield call(getAdaptiveEquipmentSolutionList)
        if (!payload.error) {
            yield put({type: 'place/listAdaptiveEquipmentSolutionSuccess', payload})
        } else {
            yield put({type: 'place/listAdaptiveEquipmentSolutionFailure', payload})
        }
    } catch (error) {
        yield put({type: 'place/listAdaptiveEquipmentSolutionFailure', error})
    }
}

export function* getAdaptiveEquipmentSolutionDetail({payload}) {
    try {
        const adaptiveEquipmentSolution = yield call(getAdaptiveEquipmentSolution, payload)
        if (!adaptiveEquipmentSolution.error) {
            yield put({type: 'place/getAdaptiveEquipmentSolutionSuccess', payload: adaptiveEquipmentSolution})
        } else {
            yield put({type: 'place/getAdaptiveEquipmentSolutionFailure', payload: adaptiveEquipmentSolution})
        }
    } catch (error) {
        yield put({type: 'place/getAdaptiveEquipmentSolutionFailure', payload: error})
    }
}

export function* updateAdaptiveEquipmentSolutionDetail({payload}) {
    const {slug, _id, token, name, description} = payload

    //add to formData so api can read
    const adaptiveEquipmentSolution = new FormData()
    const fields = [{name}, {description}]
    for (let field of fields)
        setFormData(adaptiveEquipmentSolution, field)

    try {
        const updated = yield call(updateEntity, {
            slug: slug,
            parentSlug: 'adaptive-equipment-solution',
            body: adaptiveEquipmentSolution,
            _id: _id,
            token: token,
        })
        if (!updated.error) {
            yield put({type: 'place/updateAdaptiveEquipmentSolutionSuccess', payload: updated})
            yield put({
                type: 'site/setNotification',
                payload: {
                    notification: 'Updated AdaptiveEquipmentSolution',
                    theme: 'green'
                }
            })

        } else {
            yield put({type: 'place/updateAdaptiveEquipmentSolutionFailure', payload: updated})
        }
    } catch (error) {
        yield put({type: 'place/updateAdaptiveEquipmentSolutionFailure'})
    }
}


export function* watchCreateAdaptiveEquipmentSolution() {
    yield takeEvery('place/createAdaptiveEquipmentSolution', createAdaptiveEquipmentSolution)
}

export function* watchGetAdaptiveEquipmentSolutionList() {
    yield takeEvery('place/listAdaptiveEquipmentSolution', listAdaptiveEquipmentSolution)
}

export function* watchGetAdaptiveEquipmentSolutionDetail() {
    yield takeEvery('place/getAdaptiveEquipmentSolution', getAdaptiveEquipmentSolutionDetail)
}

export function* watchUpdateAdaptiveEquipmentSolutionDetail() {
    yield takeEvery('place/updateAdaptiveEquipmentSolution', updateAdaptiveEquipmentSolutionDetail)
}

