import PropTypes                                       from 'prop-types'
import Div                                             from 'shared/Basic/Div'
import LinkSwitch                                      from 'shared/Basic/LinkSwitch'
import HeaderMenu                                      from 'shared/Menus/HeaderMenu'
import Logo                                            from './Logo'
import {headerInnerStyle, headerStyle, headerTopStyle} from './styles/header'
import Search from 'shared/Layout/Search'
import {useSelector}    from 'react-redux'

const Header = ({theme}) => {
    const {slug, url} = useSelector(state => state.site)
    const isHome = url.length === 0

    return (
        <Div as="header" theme={{...headerStyle, ...theme}} className="header">
            <Div theme={headerInnerStyle} className="header_cnt">
                <Logo/>
                {!isHome &&  <Search />

                }
                <HeaderMenu/>
            </Div>
        </Div>
    )
}

Header.defaultProps = {
    theme: {}
}

Header.propTypes = {
    theme: PropTypes.object
}

export default Header
