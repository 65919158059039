import {takeEvery}                  from '@redux-saga/core/effects'
import {getHearingImpairment, getHearingImpairmentList}       from 'features/user/admin/taxonomy/hearingImpairment/services'
import {call, put}                  from 'redux-saga/effects'
import {createEntity, updateEntity} from 'utils/abstractions/crud'
import {setFormData}                from 'utils/abstractions/setFormData'

export function* createHearingImpairment({payload}) {
    const {_id, token, name, description,} = payload

    //add to formdata so api can read
    const hearingImpairment = new FormData()
    const fields = [{name}, {description}]
    for (let field of fields)
        setFormData(hearingImpairment, field)

    const createdHearingImpairment = yield call(createEntity, {
        _id: _id,
        token: token,
        body: hearingImpairment,
        slug: 'hearing-impairment'
    })
    if (!createdHearingImpairment.error) {
        yield put({type: 'user/listHearingImpairment'})
        yield put({
            type: 'site/setNotification',
            payload: {
                notification: 'New HearingImpairment Taxonomy Type Added',
                theme: 'green'
            }
        })
        // yield put(push('/admin/places/update/' + crea.slug))

    } else {
        yield put({type: 'user/createHearingImpairmentFailure', payload})

    }
}

export function* listHearingImpairment() {
    try {
        const payload = yield call(getHearingImpairmentList)
        if (!payload.error) {
            yield put({type: 'user/listHearingImpairmentSuccess', payload})
        } else {
            yield put({type: 'user/listHearingImpairmentFailure', payload})
        }
    } catch (error) {
        yield put({type: 'user/listHearingImpairmentFailure', error})
    }
}

export function* getHearingImpairmentDetail({payload}) {
    try {
        const hearingImpairment = yield call(getHearingImpairment, payload)
        if (!hearingImpairment.error) {
            yield put({type: 'user/getHearingImpairmentSuccess', payload: hearingImpairment})
        } else {
            yield put({type: 'user/getHearingImpairmentFailure', payload: hearingImpairment})
        }
    } catch (error) {
        yield put({type: 'user/getHearingImpairmentFailure', payload: error})
    }
}

export function* updateHearingImpairmentDetail({payload}) {
    const {slug, _id, token, name, description} = payload

    //add to formData so api can read
    const hearingImpairment = new FormData()
    const fields = [{name}, {description}]
    for (let field of fields)
        setFormData(hearingImpairment, field)

    try {
        const updated = yield call(updateEntity, {
            slug: slug,
            parentSlug: 'hearing-impairment',
            body: hearingImpairment,
            _id: _id,
            token: token,
        })
        if (!updated.error) {
            yield put({type: 'user/updateHearingImpairmentSuccess', payload: updated})
            yield put({
                type: 'site/setNotification',
                payload: {
                    notification: 'Updated HearingImpairment',
                    theme: 'green'
                }
            })

        } else {
            yield put({type: 'user/updateHearingImpairmentFailure', payload: updated})
        }
    } catch (error) {
        yield put({type: 'user/updateHearingImpairmentFailure'})
    }
}


export function* watchCreateHearingImpairment() {
    yield takeEvery('user/createHearingImpairment', createHearingImpairment)
}

export function* watchGetHearingImpairmentList() {
    yield takeEvery('user/listHearingImpairment', listHearingImpairment)
}

export function* watchGetHearingImpairmentDetail() {
    yield takeEvery('user/getHearingImpairment', getHearingImpairmentDetail)
}

export function* watchUpdateHearingImpairmentDetail() {
    yield takeEvery('user/updateHearingImpairment', updateHearingImpairmentDetail)
}

