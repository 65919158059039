import {useState, useEffect}   from 'react'
import Div                     from 'shared/Basic/Div'
import S3Img                   from 'shared/Basic/S3Img'
import {useSelector}           from 'react-redux'
import {
    placePhotosWrapperStyle,
    placePhotosHeadingStyle,
    placePhotosInnerWrapperStyle,
    placePhotosContentStyle,
    placePhotoSubtitleStyle
}                              from './styles'
import PhotoDetailModal from './PhotoDetailModal'

const PlacePhotos = () => {
    const {reviews, mapboxPlace, place} = useSelector(state => state.place)
    const [photos, setPhotos] = useState([])
    const [selectedPhoto, setSelectedPhoto] = useState(null)

    useEffect(() => {
        let reviewPhotos = []
        reviews?.forEach(review => {
            const photos = review?.photos
            if (photos?.length > 0) {
                reviewPhotos = [...reviewPhotos, ...photos]
            }
        })
        setPhotos([...reviewPhotos])
    }, [reviews])

    const handlePhotoSelect = (photo) => {
        setSelectedPhoto(photo)
    }

    const closeModal = () => {
        setSelectedPhoto(null)
    }

    return (
        <Div theme={placePhotosWrapperStyle}>
            <Div theme={placePhotosInnerWrapperStyle}>
                <Div theme={placePhotosHeadingStyle}>
                    All Photos for
                    <Div>
                        {place?.name || mapboxPlace?.name}
                    </Div>
                </Div>
                <Div theme={placePhotosContentStyle}>
                    {photos.length > 0 && photos.map((photo, index) => (
                        <S3Img url={photo} itemId={index} onClick={() => handlePhotoSelect(photo)} key={index} />
                    ))}
                </Div>
                <Div theme={placePhotoSubtitleStyle}>
                    Your added photos
                </Div>
                <Div theme={placePhotosContentStyle}>
                    {photos.length > 0 && photos.map((photo, index) => (
                        <S3Img url={photo} itemId={index} onClick={() => handlePhotoSelect(photo)} key={index} />
                    ))}
                </Div>
            </Div>
            {selectedPhoto && <PhotoDetailModal photo={selectedPhoto} close={closeModal} />}
        </Div>
    )
}

export default PlacePhotos
