import {resourceFields, validateReview} from 'features/place/admin/fields/resource'
import {
    reviewFormHeadingStyle,
    reviewFormStyle,
    reviewFormWrapperStyle,
    reviewLeaveWrapperStyle
}                                     from 'features/place/views/styles'
import {useSelector}                  from 'react-redux'
import Div                            from 'shared/Basic/Div'
import H2                             from 'shared/Basic/H2'
import Form                           from 'shared/Fields/Form'

const PostResourceform = () => {
    const {_id, token, nameFirst, email, avatar} = useSelector(state => state.user)
    const {slug} = useSelector(state => state.site)
    const {place, subjectsList, categoryList } = useSelector(state => state.place)

    const initialValues = {
        title: '',
        description: '',
        document:'',
        videolink: '',
        user: _id,
        token,
        subjects: [],
        category: '' 
    }



    const options = [
        {
            name: 'subjects',
            options: subjectsList
        },
        {
            name: 'category',
            options: categoryList
        }
    ]

    return (
        <Div theme={reviewFormWrapperStyle} className='leave_a_review_sec'>
            <Div theme={reviewLeaveWrapperStyle}>
                <H2 theme={reviewFormHeadingStyle}>
                    <Div>Post Resource</Div>
                </H2>
            </Div>
            <Form
                initialValues={initialValues}
                fields={resourceFields}
                validationSchema={validateReview}
                dispatchAction={'place/addResource'}
                buttonText={'Submit'}
                theme={reviewFormStyle}
                enableReinitialize={true}
                options={options}
            />
        </Div>
    )
}

export default PostResourceform
