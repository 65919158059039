import {
    multiSelectHelperTextStyle,
    multiSelectInnerWrapperStyle,
    multiSelectOptionSelectedWrapperStyle,
    multiSelectSearchFieldStyle
}                                  from 'features/user/admin/views/styles'
import {memo, useEffect, useState} from 'react'
import {useDispatch, useSelector}  from 'react-redux'
import Div                         from 'shared/Basic/Div'
import Span                        from 'shared/Basic/Span'
import {defaultFieldHeadingStyle}  from '../styles'
import BusinessOwners              from './BusinessOwners'
import SelectOption                from './SelectOption'
import {searchResultsWrapperStyle} from './styles'

const AlgoliaSearch = memo(({
                                name,
                                algoliaSearchOptions,
                                field,
                                formik,
                                theme,
                                value
                            }) => {
    const {searchResults} = useSelector(state => state.user)
    const {current} = useSelector(state => state.place)
    const [filterInput, setFilteredInput] = useState('')
    const dispatch = useDispatch()
    const interval = 750
    let timer
    const actionSearch = algoliaSearchOptions?.filter(item => item.name === name)?.[0]?.actions.search
    const actionCurrent = algoliaSearchOptions?.filter(item => item.name === name)?.[0]?.actions.current
    const index = algoliaSearchOptions?.filter(item => item.name === name)?.[0]?.index

    const finishedTyping = () => {
        dispatch({
            type: actionSearch,
            payload: {
                index: index,
                input: filterInput
            }
        })
    }

    useEffect(() => {
        if (value.length > 0) {
            dispatch({
                type: actionCurrent,
                payload: {
                    ids: value
                }
            })
        }

    }, [value, actionCurrent, dispatch])

    return (
        <Div>
            <Div theme={{...defaultFieldHeadingStyle, ...theme.heading}}>
                {field.inputLabel}
                <Span theme={{...multiSelectHelperTextStyle}}>- Search by name, email, userId</Span>
            </Div>
            <Div theme={{...multiSelectInnerWrapperStyle}}>
                <Div
                    as='input'
                    onChange={(event) => setFilteredInput(event.target.value)}
                    onKeyUp={() => {
                        clearTimeout(timer);
                        timer = setTimeout(finishedTyping, interval);
                    }}
                    onKeyDown={() => clearTimeout(timer)}
                    onKeyPress={e => {
                        e.which === 13 && e.preventDefault()
                    }}
                    theme={{...multiSelectSearchFieldStyle}}
                />
                <Div theme={{...multiSelectOptionSelectedWrapperStyle}}>
                    <Div theme={{...multiSelectOptionSelectedWrapperStyle.heading}}>Selected</Div>
                    {value.length > 0 && current?.businessOwners?.length > 0 && current?.businessOwners?.map((owner) =>
                        <BusinessOwners
                            key={owner._id}
                            owner={owner}
                        />
                    )}
                </Div>
                {searchResults.length > 0 && (
                    <Div theme={searchResultsWrapperStyle}>
                        {searchResults.map((result) => (
                                <SelectOption
                                    key={result._id}
                                    result={result}
                                    formik={formik}
                                    name={name}
                                />
                            )
                        )}
                    </Div>
                )}
            </Div>
        </Div>
    )
})

export default AlgoliaSearch
