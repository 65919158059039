import {
    watchAddReview,
    watchAddResource,
    watchUpdateResource,
    watchDeleteResource,
    watchSetEditResourceSlug,
    watchSetReplyReviewId,
    watchAttemptDestroyPlace,
    watchCreatePlace,
    watchDestroyPlace,
    watchDestroyPlaceSuccess,
    watchFlaggedReviews,
    watchGlobalReviews,
    watchSetCurrentPlaceId,
    watchGetPendingPlaces,
    watchUpdatePlace,
    watchUpdateReview
}                                   from 'features/place/admin/sagas'
import {
    watchAddToViewCount,
    watchGetPlaceCategoryByNameOrDescription,
    watchGetPlaceDetail,
    watchGetPlaces,
    watchgetTopRatedPlaces,
    watchgetAdminstats,
    watchgetDemographicoverviews,
    watchgetBusinessOwners,
    watchGetPlacesByIds,
    watchGetReport,
    watchGetReports,
    watchGetResources,
    watchGetResource,
    watchGetReview,
    watchGetReviews,
    watchGetUserReviewedPlaces,
    watchSearchAlgoliaPlaceIndex,
    watchSearchAllPlaces
}                                   from 'features/place/sagas'
import {
    watchAttemptDestroyProduct,
    watchCreateProduct,
    watchDestroyProduct,
    watchDestroyProductSuccess,
    watchUpdateProduct,
    watchUpdateProductQuantity
}                                   from 'features/shop/admin/product/sagas'
import {
    watchCreateProductCategory,
    watchDestroyProductCategory,
    watchDestroyProductCategorySuccess,
    watchUpdateProductCategory
}                                   from 'features/shop/admin/product/sagas/category'
import {
    watchGetBraintreeToken,
    watchGetFilteredShop,
    watchGetPaymentNonce,
    watchGetShop
}                                   from 'features/shop/sagas'
import {
    watchAddToCart,
    watchRemoveFromCart
}                                   from 'features/shop/sagas/cart'
import {
    watchGetOrders,
    watchGetStatusValues,
    watchUpdateStatusValue
}                                   from 'features/shop/sagas/orders'
import {
    watchGetProductDetail,
    watchGetRelatedProducts
}                                   from 'features/shop/sagas/product'
import {
    watchGetProductCategories,
    watchGetProductCategory
}                                   from 'features/shop/sagas/product/category'
import {
    watchGetEntityById,
    watchLoadConfig,
    watchNavigate
}                                   from 'features/site/sagas'
import {
    watchConfirmResetToken,
    watchGetBookmark,
    watchGetBusinessOwners,
    watchGetUserReviews,
    watchManageBookmark,
    watchRecoverPassword,
    watchResendVerificationLink,
    watchResetPassword,
    watchSearchUser,
    watchUpdateProfile,
    watchUpdateUser,
    watchUpdateUserIdentity
}                                   from 'features/user/admin/sagas'
import {
    watchConfirmUpdateEmail,
    watchConfirmUpdatePhoneNumber,
    watchConfirmUser,
    watchCreateVerificationToken,
    watchFlagReview,
    watchGetRecentlyViewedPlace,
    watchGetSubmittedBy,
    watchGetUser,
    watchGetUsers,
    watchGetUserSuccess,
    watchSignIn,
    watchpostUserPayment,
    watchSignOut,
    watchSignUp,
    watchSignUpSignInSuccess,
    watchUpdateUserEmail,
    watchUpdateUserPhoneNumber,
    watchUserHistory,
    watchVerifyUser,
    watchGetUserPayments,
    watchGetPaymentsList,
    watchCancelPlan
}                                   from 'features/user/sagas'
import {
    watchAuthenticate,
    watchAuthenticateSuccess,
    watchAuthenticateUserSuccess,
    watchIsAuthenticated
}                                   from 'features/user/sagas/auth'
import {
    all,
    fork
}                                   from 'redux-saga/effects'
import {watchCreatePlaceFromMapbox} from './features/place/admin/sagas/mapbox'
import {
    watchCreateAdaptiveEquipmentSolution,
    watchGetAdaptiveEquipmentSolutionDetail,
    watchGetAdaptiveEquipmentSolutionList,
    watchUpdateAdaptiveEquipmentSolutionDetail
}                                   from './features/place/admin/taxonomy/adaptiveEquipmentSolution/sagas'
import {
    watchCreateBathroom,
    watchGetBathroomDetail,
    watchGetBathroomList,
    watchUpdateBathroomDetail
}                                   from './features/place/admin/taxonomy/bathroom/sagas'

import {
    watchCreateCommunitiesServed,
    watchGetCommunitiesServedDetail,
    watchGetCommunitiesServedList,
    watchUpdateCommunitiesServedDetail
} from './features/place/admin/taxonomy/communitiesServed/sagas'
import {
    watchCreateDoorway,
    watchGetDoorwayDetail,
    watchGetDoorwayList,
    watchUpdateDoorwayDetail
} from './features/place/admin/taxonomy/doorway/sagas'
import {
    watchCreateEntryway,
    watchGetEntrywayDetail,
    watchGetEntrywayList,
    watchUpdateEntrywayDetail
} from './features/place/admin/taxonomy/entryway/sagas'
import {
    watchCreateFoodOptions,
    watchGetFoodOptionsDetail,
    watchGetFoodOptionsList,
    watchUpdateFoodOptionsDetail
} from './features/place/admin/taxonomy/foodOptions/sagas'
import {
    watchCreateHearingImpairedSolution,
    watchGetHearingImpairedSolutionDetail,
    watchGetHearingImpairedSolutionList,
    watchUpdateHearingImpairedSolutionDetail
} from './features/place/admin/taxonomy/hearingImpairedSolution/sagas'
import {
    watchCreateLanguageSpoken,
    watchGetLanguageSpokenDetail,
    watchGetLanguageSpokenList,
    watchUpdateLanguageSpokenDetail
} from './features/place/admin/taxonomy/languageSpoken/sagas'
import {
    watchCreateOwnerIdentity,
    watchGetOwnerIdentityDetail,
    watchGetOwnerIdentityList,
    watchUpdateOwnerIdentityDetail
} from './features/place/admin/taxonomy/ownerIdentity/sagas'
import {
    watchCreatePlaceCategory,
    watchGetPlaceCategoryDetail,
    watchGetPlaceCategoryList,
    watchUpdatePlaceCategoryDetail
} from './features/place/admin/taxonomy/placeCategory/sagas'
import {
    watchCreatePublicTransportation,
    watchGetPublicTransportationDetail,
    watchGetPublicTransportationList,
    watchUpdatePublicTransportationDetail
} from './features/place/admin/taxonomy/publicTransportation/sagas'
import {
    watchCreateVisualImpairedSolution,
    watchGetVisualImpairedSolutionDetail,
    watchGetVisualImpairedSolutionList,
    watchUpdateVisualImpairedSolutionDetail
} from './features/place/admin/taxonomy/visualImpairedSolution/sagas'
import {
    watchGetMapboxPlace,
    watchSearchMapbox
} from './features/place/sagas/mapbox'
import {
    watchAttemptDestroyEntity,
    watchDestroyEntity
} from './features/site/admin/sagas'
import {
    watchSubmitPlace,
    watchSubmitPlaceSuccess
} from './features/user/admin/sagas/submit'
import { 
    watchSubmitCreateReport, 
    watchSubmitCreateReportSuccess,
    watchSubmitCreateReportFailure 
} from 'features/user/admin/sagas/createReport'

import {
    watchCreateAdaptiveEquipment,
    watchGetAdaptiveEquipmentDetail,
    watchGetAdaptiveEquipmentList,
    watchUpdateAdaptiveEquipmentDetail
} from './features/user/admin/taxonomy/adaptiveEquipment/sagas'
import {
    watchCreateBodyModification,
    watchGetBodyModificationDetail,
    watchGetBodyModificationList,
    watchUpdateBodyModificationDetail
} from './features/user/admin/taxonomy/bodyModification/sagas'
import {
    watchCreateGender,
    watchGetGenderDetail,
    watchGetGenderList,
    watchUpdateGenderDetail
} from './features/user/admin/taxonomy/gender/sagas'
import {
    watchCreateHearingImpairment,
    watchGetHearingImpairmentDetail,
    watchGetHearingImpairmentList,
    watchUpdateHearingImpairmentDetail
} from './features/user/admin/taxonomy/hearingImpairment/sagas'
import {
    watchCreateLanguage,
    watchGetLanguageDetail,
    watchGetLanguageList,
    watchUpdateLanguageDetail
} from './features/user/admin/taxonomy/language/sagas'
import {
    watchCreateMethodOfCommunication,
    watchGetMethodOfCommunicationDetail,
    watchGetMethodOfCommunicationList,
    watchUpdateMethodOfCommunicationDetail
} from './features/user/admin/taxonomy/methodOfCommunication/sagas'
import {
    watchCreatePhysicalAppearance,
    watchGetPhysicalAppearanceDetail,
    watchGetPhysicalAppearanceList,
    watchUpdatePhysicalAppearanceDetail
} from './features/user/admin/taxonomy/physicalAppearance/sagas'
import {
    watchCreatePronoun,
    watchGetPronounDetail,
    watchGetPronounList,
    watchUpdatePronounDetail
} from './features/user/admin/taxonomy/pronoun/sagas'
import {
    watchCreateRace,
    watchGetRaceDetail,
    watchGetRaceList,
    watchUpdateRaceDetail
} from './features/user/admin/taxonomy/race/sagas'
import {
    watchCreateServiceAnimal,
    watchGetServiceAnimalDetail,
    watchGetServiceAnimalList,
    watchUpdateServiceAnimalDetail
} from './features/user/admin/taxonomy/serviceAnimal/sagas'
import {
    watchCreateSexualOrientation,
    watchGetSexualOrientationDetail,
    watchGetSexualOrientationList,
    watchUpdateSexualOrientationDetail
} from './features/user/admin/taxonomy/sexualOrientation/sagas'
import {
    watchCreateVisualImpairment,
    watchGetVisualImpairmentDetail,
    watchGetVisualImpairmentList,
    watchUpdateVisualImpairmentDetail
} from './features/user/admin/taxonomy/visualImpairment/sagas'


//TODO: determine best method of combining rootSaga
export default function* rootSaga() {
    yield all([
        //site
        fork(watchNavigate),
        fork(watchLoadConfig),
        fork(watchGetEntityById),
        fork(watchAttemptDestroyEntity),
        fork(watchDestroyEntity),

        //auth
        fork(watchAuthenticate),
        fork(watchIsAuthenticated),
        fork(watchAuthenticateSuccess),
        fork(watchAuthenticateUserSuccess),

        //sign in
        fork(watchSignIn),
        fork(watchpostUserPayment),
        fork(watchSignOut),
        fork(watchSignUp),
        fork(watchSignUpSignInSuccess),

        //user
        fork(watchUserHistory),
        fork(watchUpdateProfile),
        fork(watchUpdateUser),
        fork(watchUpdateUserIdentity),
        fork(watchConfirmUser),
        fork(watchGetUsers),
        fork(watchGetUserSuccess),
        fork(watchGetUser),
        fork(watchGetSubmittedBy),
        fork(watchCreateVerificationToken),
        fork(watchVerifyUser),
        fork(watchManageBookmark),
        fork(watchGetBookmark),
        fork(watchGetUserReviews),
        fork(watchResendVerificationLink),
        fork(watchRecoverPassword),
        fork(watchConfirmResetToken),
        fork(watchResetPassword),
        fork(watchSearchUser),
        fork(watchGetBusinessOwners),
        fork(watchFlagReview),
        fork(watchGetRecentlyViewedPlace),
        fork(watchUpdateUserPhoneNumber),
        fork(watchConfirmUpdatePhoneNumber),
        fork(watchUpdateUserEmail),
        fork(watchConfirmUpdateEmail),
        fork(watchGetPaymentsList),
        fork(watchCancelPlan),
        
        //user -- taxonomy
        fork(watchCreateAdaptiveEquipment),
        fork(watchGetAdaptiveEquipmentList),
        fork(watchGetAdaptiveEquipmentDetail),
        fork(watchUpdateAdaptiveEquipmentDetail),

        fork(watchCreateBodyModification),
        fork(watchGetBodyModificationList),
        fork(watchGetBodyModificationDetail),
        fork(watchUpdateBodyModificationDetail),

        fork(watchCreateGender),
        fork(watchGetGenderList),
        fork(watchGetGenderDetail),
        fork(watchUpdateGenderDetail),

        fork(watchCreateLanguage),
        fork(watchGetLanguageList),
        fork(watchGetLanguageDetail),
        fork(watchUpdateLanguageDetail),

        fork(watchCreateMethodOfCommunication),
        fork(watchGetMethodOfCommunicationList),
        fork(watchGetMethodOfCommunicationDetail),
        fork(watchUpdateMethodOfCommunicationDetail),

        fork(watchCreatePhysicalAppearance),
        fork(watchGetPhysicalAppearanceList),
        fork(watchGetPhysicalAppearanceDetail),
        fork(watchUpdatePhysicalAppearanceDetail),

        fork(watchCreatePronoun),
        fork(watchGetPronounList),
        fork(watchGetPronounDetail),
        fork(watchUpdatePronounDetail),

        fork(watchCreateRace),
        fork(watchGetRaceList),
        fork(watchGetRaceDetail),
        fork(watchUpdateRaceDetail),

        fork(watchCreateServiceAnimal),
        fork(watchGetServiceAnimalList),
        fork(watchGetServiceAnimalDetail),
        fork(watchUpdateServiceAnimalDetail),

        fork(watchCreateSexualOrientation),
        fork(watchGetSexualOrientationList),
        fork(watchGetSexualOrientationDetail),
        fork(watchUpdateSexualOrientationDetail),

        fork(watchCreateHearingImpairment),
        fork(watchGetHearingImpairmentList),
        fork(watchGetHearingImpairmentDetail),
        fork(watchUpdateHearingImpairmentDetail),

        fork(watchCreateVisualImpairment),
        fork(watchGetVisualImpairmentList),
        fork(watchGetVisualImpairmentDetail),
        fork(watchUpdateVisualImpairmentDetail),


        // place
        fork(watchCreatePlace),
        fork(watchSubmitPlace),
        fork(watchSubmitPlaceSuccess),
        fork(watchUpdatePlace),
        fork(watchAttemptDestroyPlace),
        fork(watchDestroyPlace),
        fork(watchDestroyPlaceSuccess),
        fork(watchGetPlaces),
        fork(watchgetTopRatedPlaces),
        fork(watchgetAdminstats),
        fork(watchgetDemographicoverviews),
        fork(watchgetBusinessOwners),
        fork(watchGetPlacesByIds),
        fork(watchGetPlaceDetail),
        fork(watchSearchAllPlaces),
        fork(watchGetPlaceCategoryByNameOrDescription),
        fork(watchAddToViewCount),
        fork(watchSearchAlgoliaPlaceIndex),
        fork(watchAddReview),
        fork(watchAddResource),
        fork(watchUpdateResource),
        fork(watchDeleteResource),
        fork(watchSetEditResourceSlug),
        fork(watchSetReplyReviewId),
        fork(watchUpdateReview),
        fork(watchGetReview),
        fork(watchGetReviews),
        fork(watchGetUserReviewedPlaces),
        fork(watchGetPendingPlaces),
        fork(watchFlaggedReviews),
        fork(watchGlobalReviews),
        fork(watchSetCurrentPlaceId),
        //place taxonomy
        fork(watchCreateAdaptiveEquipmentSolution),
        fork(watchGetAdaptiveEquipmentSolutionList),
        fork(watchGetAdaptiveEquipmentSolutionDetail),
        fork(watchUpdateAdaptiveEquipmentSolutionDetail),

        fork(watchCreateBathroom),
        fork(watchGetBathroomList),
        fork(watchGetBathroomDetail),
        fork(watchUpdateBathroomDetail),

        fork(watchCreateCommunitiesServed),
        fork(watchGetCommunitiesServedList),
        fork(watchGetCommunitiesServedDetail),
        fork(watchUpdateCommunitiesServedDetail),

        fork(watchCreateDoorway),
        fork(watchGetDoorwayList),
        fork(watchGetDoorwayDetail),
        fork(watchUpdateDoorwayDetail),

        fork(watchCreateEntryway),
        fork(watchGetEntrywayList),
        fork(watchGetEntrywayDetail),
        fork(watchUpdateEntrywayDetail),

        fork(watchCreateFoodOptions),
        fork(watchGetFoodOptionsList),
        fork(watchGetFoodOptionsDetail),
        fork(watchUpdateFoodOptionsDetail),

        fork(watchCreateHearingImpairedSolution),
        fork(watchGetHearingImpairedSolutionList),
        fork(watchGetHearingImpairedSolutionDetail),
        fork(watchUpdateHearingImpairedSolutionDetail),

        fork(watchCreateLanguageSpoken),
        fork(watchGetLanguageSpokenList),
        fork(watchGetLanguageSpokenDetail),
        fork(watchUpdateLanguageSpokenDetail),

        fork(watchCreatePlaceCategory),
        fork(watchGetPlaceCategoryList),
        fork(watchGetPlaceCategoryDetail),
        fork(watchUpdatePlaceCategoryDetail),

        fork(watchCreatePublicTransportation),
        fork(watchGetPublicTransportationList),
        fork(watchGetPublicTransportationDetail),
        fork(watchUpdatePublicTransportationDetail),

        fork(watchCreateVisualImpairedSolution),
        fork(watchGetVisualImpairedSolutionList),
        fork(watchGetVisualImpairedSolutionDetail),
        fork(watchUpdateVisualImpairedSolutionDetail),

        fork(watchCreateOwnerIdentity),
        fork(watchGetOwnerIdentityList),
        fork(watchGetOwnerIdentityDetail),
        fork(watchUpdateOwnerIdentityDetail),


         // Manage Reports
         fork(watchSubmitCreateReport),
         fork(watchSubmitCreateReportSuccess),
         fork(watchSubmitCreateReportFailure),
         fork(watchGetReport),
         fork(watchGetReports),
         fork(watchGetResources),
         fork(watchGetResource),

        // shop
        fork(watchCreateProduct),
        fork(watchUpdateProduct),
        fork(watchAttemptDestroyProduct),
        fork(watchDestroyProduct),
        fork(watchDestroyProductSuccess),
        fork(watchGetProductDetail),
        fork(watchGetShop),
        fork(watchGetFilteredShop),
        fork(watchUpdateProductQuantity),

        // product placeCategory
        fork(watchCreateProductCategory),
        fork(watchUpdateProductCategory),
        fork(watchDestroyProductCategory),
        fork(watchDestroyProductCategorySuccess),
        fork(watchGetProductCategories),
        fork(watchGetProductCategory),
        fork(watchGetRelatedProducts),

        //orders
        fork(watchGetOrders),
        fork(watchGetStatusValues),
        fork(watchUpdateStatusValue),

        //checkout
        fork(watchAddToCart),
        fork(watchRemoveFromCart),
        fork(watchGetBraintreeToken),
        fork(watchGetPaymentNonce),

        //mapbox
        fork(watchSearchMapbox),
        fork(watchGetMapboxPlace),
        fork(watchCreatePlaceFromMapbox),
        fork(watchGetUserPayments)
    ])
}
