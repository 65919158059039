import {globals}                   from 'config/styles'
import {center, flex, flexStart, pointer, spaceBetween, white} from 'utils/themer'

const orderColors = ['ff', '90', '80', '70', '60']

export const likertStyle = {
    // paddingLeft: [30, .7, 30],
    // paddingRight: [30, .7, 30],
    // paddingTop: [30, .7, 30],
    // paddingBottom: [30, .7, 30],
    paddding: 0,
    border: `1px solid ${globals.colors.borderColor}`,
    borderRadius: [10, .7, 10],
    marginBottom: [50, .7, 30],
    background: white
}

export const likertContent = {
    display: flex,
    justifyContent: center,
    alignItems: center,
    padding: '24px 36px',
    mobile:{
        padding: '10px',
        flexDirection: 'column',
    }
}

export const leftSide = {
    width: '25%',
    marginTop: '-48px',
    mobile:{
        marginTop: '0',
        width: '100%'
    }
}

export const rightSide = {
    width: '75%',
    mobile:{
        width: '100%'
    }
}

export const likertOptionsWrapperStyle = {
    display: flex,
    justifyContent: spaceBetween
}

export const iconStyle = {
    width: '60px',
    height: 'auto',
    color: '#00000050',
    marginLeft: 16
}

export const likertOptionStyle = (isActive, fieldColor, order) => {
    return {
        size: [14, .7, 14],
        background: '#e4e4e4',
        color: globals.colors.textColor,
        alignSelf: flexStart,
        width: '56px',
        height: '56px',
        borderWidth: '14px',
        borderStyle: 'solid',
        borderColor: fieldColor + orderColors[5 - order],
        lineHeight: '28px',
        textAlign: center,
        borderRadius: '50%',
        outline: isActive ? '5px solid #232323' : 'unset',
        hover: {
            cursor: pointer
        }
    }
}

export const suggestContainer = {
    display: flex,
    justifyContent: spaceBetween,
    fontSize: 12,
    marginBottom: 16,
    child: {
        selector: '> div',
        width: '60px',
        textAlign: center,
        textTransform: 'uppercase',
        color: '#848484'
    }
}

export const suggestContent = {
    width: '60px'
}

export const getLikertHeadingStyle = (color) => ({
    height: 60,
    lineHeight: 60,
    color: globals.colors.textColor,
    paddingLeft: 28,
    background: `${color}50`,
    borderTopLeftRadius: '9px',
    borderTopRightRadius: '9px',
    fontSize: 18,
    fontWeight: 500
})
