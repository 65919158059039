import {timesCircle}                                                 from 'config/icons'
import Gravatar                                                      from 'react-gravatar'
import Div                                                           from 'shared/Basic/Div'
import Icon                                                          from 'shared/Basic/Icon'
import S3Img                                                         from 'shared/Basic/S3Img'
import {genericCardImageStyle}                                       from 'shared/Cards/styles'
import Tooltip                                                       from 'shared/Controls/ToolTip'
import {imageUploadGravatarTooltipStyle, imageUploadRemoveImageIcon} from 'shared/Fields/styles'

const CurrentProfileImage = ({currentImage, setCurrentImage, formik, email}) => {
    return (
        <Div>
            {(currentImage && (
                <S3Img
                    url={currentImage}
                    alt={'Post Image Preview'}
                    theme={genericCardImageStyle}
                />
            )) || ((
                <Gravatar email={email} size={250}/>
            ))}
            {(currentImage && (
                <Icon
                    icon={timesCircle}
                    theme={imageUploadRemoveImageIcon}
                    onClick={
                        () => {
                            formik.setFieldValue('avatar', '')
                            formik.submitForm()
                            setCurrentImage('')
                        }}
                />
            )) || (
                <Tooltip theme={imageUploadGravatarTooltipStyle}>
                    This image was imported from your Gravatar account associated with {email}.
                </Tooltip>
            )}
        </Div>
    )
}

export default CurrentProfileImage
