import {push}                  from 'connected-react-router'
import {addPlaceFromMapbox}    from 'features/place/services'
import {call, put, takeLatest} from 'redux-saga/effects'
import {createEntity}          from 'utils/abstractions/crud'
import {setFormData}           from 'utils/abstractions/setFormData'

export function* createPlaceFromMapbox({payload}) {
    const {
        _id,
        token,
        mapboxPlace
    } = payload

    const mapboxCategories = []
    const categories = payload?.mapboxPlace?.properties?.category.split(',')


    for (const category of categories) {
        const placeCategory = new FormData()
        const fields = [{name: category}, {description: category}]
        for (let field of fields)
            setFormData(placeCategory, field)

        try {
            const createdPlaceCategory = yield call(createEntity, {
                _id: _id,
                token: token,
                body: placeCategory,
                slug: 'place-category'
            })

            if (!createdPlaceCategory.error) {
                mapboxCategories.push(createdPlaceCategory.id)
            } else {
                if (!!createdPlaceCategory.error.id)
                    mapboxCategories.push(createdPlaceCategory.error.id)
            }
        } catch (error) {
            console.log('error', error)
        }
    }

    const place = new FormData()
    // console.log('lat', mapboxPlace?.center[1].toString())
    // console.log('lat with nullish', mapboxPlace?.center?.[1].toString())
    // console.log('long', mapboxPlace?.center?.[0].toString())

    const fields = [
        {mapboxId: mapboxPlace.id.split('.').pop()},
        {name: mapboxPlace?.text},
        {address1: mapboxPlace?.properties.address},
        {city: mapboxPlace?.context?.[2]?.text},
        {categories: mapboxCategories},
        {zip: mapboxPlace?.context?.[1]?.text},
        {country: mapboxPlace?.context?.[6]?.text},
        {state: mapboxPlace?.context?.[5]?.text},
        {latitude: mapboxPlace?.center[1].toString()},
        {longitude: mapboxPlace?.center?.[0].toString()},
    ]


    for (let field of fields)
        setFormData(place, field)

    const createdPlace = yield call(
        addPlaceFromMapbox,
        {
            _id: _id,
            token: token,
            place: place
        }
    )
    if (!createdPlace.error) {
        yield put(push(`/places/${createdPlace.slug}`))
        yield put({type: 'place/getPlace', payload: {slug: createdPlace.slug}})
        yield put({type: 'place/createPlaceFromMapboxSuccess', payload: {createdPlace}})

    } else {
        yield put(push(`/places/${createdPlace.error}`))
    }

}


/**
 *
 *
 * ADMIN BOONE PLACE WATCHERS
 *
 *
 */

export function* watchCreatePlaceFromMapbox() {
    yield takeLatest('place/createPlaceFromMapbox', createPlaceFromMapbox)
}
