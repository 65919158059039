import {push}                                                           from 'connected-react-router'
import {deletePlace, getPendingPlaces, handleSuccessfulPlaceSubmission} from 'features/place/services'
import {getFlaggedReviews}                                              from 'features/place/services/reviews'
import {getGlobalReviews}                                              from 'features/place/services/globalreviews'

import {getSignedRequest, getSignedRequests, uploadFile, uploadFiles}                                   from 'features/site/services/s3'
import {call, put, takeLatest}                                          from 'redux-saga/effects'
import {createEntity, updateEntity,createResourceEntity, updateResourceEntity, deleteResourceEntity}                                     from 'utils/abstractions/crud'
import {setFormData}                                                    from 'utils/abstractions/setFormData'

export function* createPlace({payload}) {
    const {
        _id,
        token,
        adaptiveEquipmentSolution,
        address1,
        address2,
        city,
        zip,
        country,
        state,
        latitude,
        longitude,
        bathrooms,
        businessOwner,
        categories,
        communitiesServed,
        description,
        doorway,
        entryway,
        foodOptions,
        hearingImpairedSolution,
        isPendingSubmission,
        isBrickAndMortar,
        languageSpoken,
        name,
        photo,
        photoFile,
        publicTransportation,
        website,
        wheelchairElevator,
        wheelchairParking,
        wheelchairRamps,
        wheelchairRestroom,
        visualImpairedSolution,
        ownerIdentity
    } = payload

    //add to formdata so api can read
    const place = new FormData()
    const fields = [
        {adaptiveEquipmentSolution},
        {address1},
        {address2},
        {city},
        {zip},
        {country},
        {state},
        {latitude},
        {longitude},
        {bathrooms},
        {businessOwner},
        {categories},
        {communitiesServed},
        {description},
        {doorway},
        {entryway},
        {foodOptions},
        {hearingImpairedSolution},
        {isPendingSubmission},
        {isBrickAndMortar},
        {languageSpoken},
        {name},
        {photo},
        {publicTransportation},
        {website},
        {wheelchairElevator},
        {wheelchairParking},
        {wheelchairRamps},
        {wheelchairRestroom},
        {visualImpairedSolution},
        {ownerIdentity}
    ]
    for (let field of fields)
        setFormData(place, field)

    const s3Payload = yield call(getSignedRequest, photoFile)
    if (!!s3Payload.signedRequest) {
        yield call(uploadFile, {file: photoFile, signedRequest: s3Payload.signedRequest})
        const created = yield call(createEntity, {
            slug: 'place',
            body: place,
            _id: _id,
            token: token,
        })
        if (!created.error) {
            yield put({
                type: 'site/setNotification',
                payload: {
                    notification: 'New Place Added!',
                    theme: 'green'
                }
            })
            yield put({type: 'place/getPlaces'})
            yield put(push('/admin/places/update/' + created.slug))

        } else {
            yield put({type: 'place/createPlacesFailure', payload})
        }

    }
}

export function* updatePlaceDetail({payload}) {
    const {
        slug,
        _id,
        token,
        adaptiveEquipmentSolution,
        address1,
        address2,
        city,
        zip,
        country,
        state,
        latitude,
        longitude,
        bathrooms,
        businessOwner,
        categories,
        communitiesServed,
        description,
        doorway,
        entryway,
        foodOptions,
        hearingImpairedSolution,
        isPendingSubmission,
        isBrickAndMortar,
        languageSpoken,
        name,
        photo,
        photoFile,
        publicTransportation,
        submittedBy,
        submittedByEmail,
        submittedByNameFirst,
        submittedPlaceSlug,
        website,
        wheelchairElevator,
        wheelchairParking,
        wheelchairRamps,
        wheelchairRestroom,
        visualImpairedSolution,
        ownerIdentity,
        hours,
        phoneNumber,
        emailAddress
    } = payload

    //add to formData so api can read
    const place = new FormData()
    const fields = [
        {adaptiveEquipmentSolution},
        {address1},
        {address2},
        {city},
        {zip},
        {country},
        {state},
        {latitude},
        {longitude},
        {bathrooms},
        {businessOwner},
        {categories},
        {communitiesServed},
        {description},
        {doorway},
        {entryway},
        {foodOptions},
        {hearingImpairedSolution},
        {isPendingSubmission},
        {isBrickAndMortar},
        {languageSpoken},
        {name},
        {photo},
        {publicTransportation},
        {submittedBy},
        {submittedByEmail},
        {submittedByNameFirst},
        {submittedPlaceSlug},
        {website},
        {wheelchairElevator},
        {wheelchairParking},
        {wheelchairRamps},
        {wheelchairRestroom},
        {visualImpairedSolution},
        {ownerIdentity},
        {hours: JSON.stringify(hours)},
        {phoneNumber},
        {emailAddress}
    ]

    for (let field of fields)
        setFormData(place, field)

    if (!!photoFile) {
        const s3Payload = yield call(getSignedRequest, photoFile)
        if (!!s3Payload.signedRequest) {
            yield call(uploadFile, {file: photoFile, signedRequest: s3Payload.signedRequest})
        }
    }

    try {
        const updated = yield call(updateEntity,
            {
                _id: _id,
                token: token,
                parentSlug: 'place',
                slug: slug,
                body: place
            })

        if (!updated.error) {
            yield put({type: 'place/updatePlaceSuccess', payload: updated})
            yield put({
                type: 'site/setNotification',
                payload: {
                    notification: 'Updated Place',
                    theme: 'green'
                }
            })


            /*
           *
           * We can determine this update function is being called during an acceptance of a place submission
           * by checking to see if isPendingSubmission is false. Only during accepting a place submission will
           * this value be false. Otherwise it will be undefined.
           *
           */
            if((isPendingSubmission === false) && !!submittedBy) {
                const sendPlaceAcceptanceEmail = yield call(handleSuccessfulPlaceSubmission, {
                    _id,
                    token,
                    submittedByEmail,
                    submittedByNameFirst,
                    submittedPlaceSlug,
                    placeName: name
                })

                if (!sendPlaceAcceptanceEmail.error) {
                    yield put({
                        type: 'site/setNotification',
                        payload: {
                            notification: `Email to ${submittedByEmail} sent!`,
                            theme: 'green'
                        }
                    })

                } else {
                    yield put({type: 'place/sendEmailFailure', payload: sendPlaceAcceptanceEmail})
                }
            }
        } else {
            yield put({type: 'place/updatePlaceFailure', payload: updated})
        }
    } catch (error) {
        yield put({type: 'place/updatePlaceFailure'})
    }
}

export function* attemptDestroyPlace({payload}) {
    yield put({type: 'place/confirmDestroyPlace', payload: payload})
}

export function* destroyPlace({payload}) {
    const destroyed = yield call(deletePlace, payload)
    const {objectID} = payload

    if (!destroyed.error) {
        // yield put({type: 'place/destroyPlaceSuccess'})
        yield put({type: 'place/destroyPlaceSuccess', payload: {objectID}})
        yield put({type: 'place/getPlaces'})
        yield put(push('/admin/places'))
    } else {
        yield put({type: 'place/destroyPlaceFailure'})
    }
}

export function* destroyPlaceSuccess() {
    yield put(push('/admin/places'))
}

export function* addReview({payload}) {
    yield put({type: 'place/closeReviewPanel'})
    const {
        celebrated,
        isAnon,
        parentId,
        photo,
        photoFile,
        placeName,
        placeSlug,
        review,
        reviewerAvatar,
        reviewerName,
        reviewerEmail,
        slug,
        safe,
        welcome,
        user,
        _id,
        placeId,
        token
    } = payload

    const validFileNames = []
    if (!!photoFile) {
        const s3Payloads = yield call(getSignedRequests, photoFile)
        const data = []

        s3Payloads.forEach((s3Payload, index) => {
            if (!!s3Payload.signedRequest) {
                data.push({file: photoFile[index], signedRequest: s3Payload.signedRequest})
                validFileNames.push(photo[index])
            }
        })
        if (data.length) {
            yield call(uploadFiles, data)
        }
    }

    //add to formData so api can read
    const placeReview = new FormData()
    const fields = [
        {celebrated},
        {parentId},
        {isAnon},
        {photos: validFileNames},
        {review},
        {reviewerAvatar},
        {reviewerName},
        {reviewerEmail},
        {safe},
        {welcome},
        {user},
        {place: placeId},
        {placeName},
        {placeSlug}
    ]

    for (let field of fields)
        setFormData(placeReview, field)

    try {
        const updated = yield call(updateEntity,
            {
                _id: _id,
                token: token,
                parentSlug: 'place',
                slug: slug,
                body: placeReview
            })

        if (!updated.error) {
            yield put({type: 'place/addReviewSuccess'})
            yield put({type: 'place/getReview', payload: {_id, token, review: updated._id}})
            yield put({type: 'place/getReviews',payload: { token, placeId}})
            yield put({
                type: 'site/setNotification',
                payload: {
                    notification: 'Review Added',
                    theme: 'green'
                }
            })
        } else {
            yield put({type: 'place/updatePlaceFailure', payload: updated})
        }
    } catch (error) {
        yield put({type: 'place/updatePlaceFailure'})
    }
}

export function* updateReview({payload}) {
    const {
        photo,
        photoFile,
        placeName,
        placeSlug,
        review,
        reviewerAvatar,
        reviewerName,
        safe,
        celebrated,
        welcome,
        safe_prev,
        celebrated_prev,
        welcome_prev,
        isAnon,
        isFlagged,
        flaggedBy,
        user,
        _id,
        id,
        placeId,
        token
    } = payload

    const validFileNames = []
    if (!!photoFile) {
        const s3Payloads = yield call(getSignedRequests, photoFile)
        const data = []

        s3Payloads.forEach((s3Payload, index) => {
            if (!!s3Payload.signedRequest) {
                data.push({file: photoFile[index], signedRequest: s3Payload.signedRequest})
                validFileNames.push(photo[index])
            }
        })
        console.log('data', data)
        if (data.length) {
            yield call(uploadFiles, data)
        }
    }

    //add to formData so api can read
    const placeReview = new FormData()
    const fields = [
        {id},
        {photos: validFileNames},
        {review},
        {reviewerAvatar},
        {reviewerName},
        {safe},
        {welcome},
        {celebrated},
        {safe_prev},
        {welcome_prev},
        {celebrated_prev},
        {isAnon},
        {isFlagged},
        {flaggedBy},
        {user},
        {place: placeId},
        {placeName},
        {placeSlug}
    ]

    for (let field of fields) {
        if (field) {
            setFormData(placeReview, field)
        }
    }

    try {
        const updated = yield call(updateEntity,
            {
                _id: _id,
                token: token,
                parentSlug: 'reviews',
                slug: id,
                body: placeReview
            })

        if (!updated.error) {
            yield put({type: 'place/updateReviewSuccess', payload: updated})
            yield put({type: 'place/getReview', payload: {_id, token, review: updated._id}})
            yield put({
                type: 'site/setNotification',
                payload: {
                    notification: 'Review Updated',
                    theme: 'green'
                }
            })

            //TODO: temp solution refresh reviews
            //  yield put({type: 'place/getFlaggedReviews'})

        } else {
            yield put({type: 'place/updatePlaceFailure', payload: updated})
        }
    } catch (error) {
        yield put({type: 'place/updatePlaceFailure'})
    }
}

export function* pendingPlaces() {
    const payload = yield call(getPendingPlaces)

    if (!payload.error) {
        yield put({
            type: 'place/getPendingPlacesSuccess',
            payload: payload
        })
    }
}

export function* setCurrentPlaceId ({payload}) {
    const {placeId} = payload
    if (!!placeId) {
        yield put({
            type: 'place/setPlaceIdSuccess',
            payload: {
                placeId
            }
        })
    }
}

export function* flaggedReviews() {
    const payload = yield call(getFlaggedReviews)

    if (!payload.error) {
        yield put({
            type: 'place/getFlaggedReviewsSuccess',
            payload: payload
        })
    }
}

export function* globalReviews({payload}) {
    const reviewpayload = yield call(getGlobalReviews, {id:payload._id, token:payload.token, lat:payload.lat, lng:payload.lng })
    if (!reviewpayload.error) {
        yield put({
            type: 'place/getGlobalReviewsSuccess',
            payload: reviewpayload
        })
    }
}


export function* addResource({payload}) {
    const {
        title,
        description,
        document,
        photoFile,
        videolink,
        user,
        token,
        subjects,
        category
    } = payload
    if (!!photoFile) {
        const s3Payload = yield call(getSignedRequest, photoFile)
        console.log("---------------111---------------------")
    
        console.log(!!s3Payload.signedRequest)
        if (!!s3Payload.signedRequest) {
            yield call(uploadFile, {file: photoFile, signedRequest: s3Payload.signedRequest})
        }
    }

    //add to formData so api can read
    const addResource = new FormData()
    const fields = [
        {title},
        {document},
        {description},
        {videolink},
        {user},
        {subjects},
        {category}
    ]

    for (let field of fields)
        setFormData(addResource, field)

    try {
        const updated = yield call(createResourceEntity,
            {
                token: token,
                slug: 'resource',
                body: addResource
            })
            console.log(updated)
        if (!updated.error) {
            yield put({type: 'place/addResourceSuccess'})
            yield put({type: 'report/getResources', payload: {token, review: updated._id}})
            yield put({
                type: 'site/setNotification',
                payload: {
                    notification: 'Resource Added',
                    theme: 'green'
                }
            })
        } else {
            yield put({type: 'place/updatePlaceFailure', payload: updated})
        }
    } catch (error) {
        yield put({type: 'place/updatePlaceFailure'})
    }
}

export function* setEditResourceSlug({payload}){
    const { resourceSlug } = payload
    yield put({type: 'place/setCurrentEditResourceSlug', payload: {resourceSlug}})

}

export function* setReplyReviewId({payload}){
   
    const { reviewId } = payload


    yield put({type: 'place/setCurrentReplyReviewId', payload: {reviewId}})

}
//updateresource
export function* updateResource({payload}){
    const {
        _id,
        title,
        description,
        document,
        photoFile,
        videolink,
        user,
        token,
        subjects,
        category
    } = payload
 
    if (!!photoFile) {
        const s3Payload = yield call(getSignedRequest, photoFile)
    
        if (!!s3Payload.signedRequest) {
            yield call(uploadFile, {file: photoFile, signedRequest: s3Payload.signedRequest})
        }
    }

    //add to formData so api can read
    const updateResourceD = new FormData()
    const fields = [
        {_id},
        {title},
        {document},
        {description},
        {videolink},
        {user},
        {subjects},
        {category}
    ]

    for (let field of fields)
        setFormData(updateResourceD, field)

    try {
        const updated = yield call(updateResourceEntity,
            {
                token: token,
                slug: 'resource',
                body: updateResourceD
            })
            // console.log("---224----updated ",updated)
        if (!updated.error) {
            yield put({type: 'place/updateResourceSuccess'})
            yield put({type: 'place/setCurrentEditResourceSlug', payload: {resourceId: null}})
            yield put({type: 'report/getResources', payload: {token, review: updated._id}})
            yield put({
                type: 'site/setNotification',
                payload: {
                    notification: 'Resource Updated',
                    theme: 'green'
                }
            })
        } else {
            yield put({type: 'place/updatePlaceFailure', payload: updated})
        }
    } catch (error) {
        yield put({type: 'place/updatePlaceFailure'})
    }
}

//delete resource
export function* deleteResource({payload}){
    const {
        resourceId,
        token
    } = payload

    //add to formData so api can read
    const deleteReso = new FormData()
    const fields = [
        {resourceId}
    ]

    for (let field of fields)
        setFormData(deleteReso, field)

    try {
        const deleted = yield call(deleteResourceEntity,
            {
                token: token,
                slug: 'resource',
                body: deleteReso
            })
            console.log("---334----updated ",deleted)
        if (!deleted.error) {
            yield put({type: 'report/getResources', payload: {token, review: deleted._id}})
            yield put({
                type: 'site/setNotification',
                payload: {
                    notification: 'Resource Deleted',
                    theme: 'green'
                }
            })
        } else {
            yield put({type: 'place/updatePlaceFailure', payload: deleted})
        }
    } catch (error) {
        yield put({type: 'place/updatePlaceFailure'})
    }
}

/**
 *
 *
 * ADMIN ARTIST WATCHERS
 *
 *
 */

export function* watchCreatePlace() {
    yield takeLatest('place/createPlace', createPlace)
}

export function* watchAttemptDestroyPlace() {
    yield takeLatest('place/attemptDestroyPlace', attemptDestroyPlace)
}

export function* watchDestroyPlace() {
    yield takeLatest('place/destroyPlace', destroyPlace)
}

export function* watchDestroyPlaceSuccess() {
    yield takeLatest('place/attemptDestroyPlaceSuccess', destroyPlaceSuccess)
}

export function* watchUpdatePlace() {
    yield takeLatest('place/updatePlace', updatePlaceDetail)
}

export function* watchAddReview() {
    yield takeLatest('place/addReview', addReview)
}
export function* watchAddResource() {
    yield takeLatest('place/addResource', addResource)
}
export function* watchUpdateResource(){
    yield takeLatest('place/updateResource', updateResource)
}
export function* watchDeleteResource() {
    yield takeLatest('place/deleteResource',deleteResource)
}
export function* watchSetEditResourceSlug(){
    yield takeLatest('place/setEditResourceSlug', setEditResourceSlug)
}
export function* watchSetReplyReviewId(){
    yield takeLatest('place/setReplyReviewId', setReplyReviewId)
}
export function* watchUpdateReview() {
    yield takeLatest('place/updateReview', updateReview)
}

export function* watchFlaggedReviews() {
    yield takeLatest('place/getFlaggedReviews', flaggedReviews)
}

export function* watchGlobalReviews() {
    yield takeLatest('place/getGlobalReviews', globalReviews)
}

export function* watchGetPendingPlaces() {
    yield takeLatest('place/getPendingPlaces', pendingPlaces)
}
export function* watchSetCurrentPlaceId() {
    yield takeLatest( 'place/setCurrentPlaceId',setCurrentPlaceId)
}