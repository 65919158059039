import {takeEvery}                  from '@redux-saga/core/effects'
import {
    getPublicTransportation,
    getPublicTransportationList
}                                   from 'features/place/admin/taxonomy/publicTransportation/services'
import {call, put}                  from 'redux-saga/effects'
import {createEntity, updateEntity} from 'utils/abstractions/crud'
import {setFormData}                from 'utils/abstractions/setFormData'

export function* createPublicTransportation({payload}) {
    const {_id, token, name, description,} = payload

    //add to formdata so api can read
    const publicTransportation = new FormData()
    const fields = [{name}, {description}]
    for (let field of fields)
        setFormData(publicTransportation, field)

    const createdPublicTransportation = yield call(createEntity, {
        _id: _id,
        token: token,
        body: publicTransportation,
        slug: 'public-transportation'
    })
    if (!createdPublicTransportation.error) {
        yield put({type: 'place/listPublicTransportation'})
        // yield put(push('/admin/places/update/' + crea.slug))

    } else {
        yield put({type: 'place/createPublicTransportationFailure', payload})
    }
}

export function* listPublicTransportation() {
    try {
        const payload = yield call(getPublicTransportationList)
        if (!payload.error) {
            yield put({type: 'place/listPublicTransportationSuccess', payload})
        } else {
            yield put({type: 'place/listPublicTransportationFailure', payload})
        }
    } catch (error) {
        yield put({type: 'place/listPublicTransportationFailure', error})
    }
}

export function* getPublicTransportationDetail({payload}) {
    try {
        const publicTransportation = yield call(getPublicTransportation, payload)
        if (!publicTransportation.error) {
            yield put({type: 'place/getPublicTransportationSuccess', payload: publicTransportation})
        } else {
            yield put({type: 'place/getPublicTransportationFailure', payload: publicTransportation})
        }
    } catch (error) {
        yield put({type: 'place/getPublicTransportationFailure', payload: error})
    }
}

export function* updatePublicTransportationDetail({payload}) {
    const {slug, _id, token, name, description} = payload

    //add to formData so api can read
    const publicTransportation = new FormData()
    const fields = [{name}, {description}]
    for (let field of fields)
        setFormData(publicTransportation, field)

    try {
        const updated = yield call(updateEntity, {
            slug: slug,
            parentSlug: 'public-transportation',
            body: publicTransportation,
            _id: _id,
            token: token,
        })
        if (!updated.error) {
            yield put({type: 'place/updatePublicTransportationSuccess', payload: updated})
            yield put({
                type: 'site/setNotification',
                payload: {
                    notification: 'Updated PublicTransportation',
                    theme: 'green'
                }
            })

        } else {
            yield put({type: 'place/updatePublicTransportationFailure', payload: updated})
        }
    } catch (error) {
        yield put({type: 'place/updatePublicTransportationFailure'})
    }
}


export function* watchCreatePublicTransportation() {
    yield takeEvery('place/createPublicTransportation', createPublicTransportation)
}

export function* watchGetPublicTransportationList() {
    yield takeEvery('place/listPublicTransportation', listPublicTransportation)
}

export function* watchGetPublicTransportationDetail() {
    yield takeEvery('place/getPublicTransportation', getPublicTransportationDetail)
}

export function* watchUpdatePublicTransportationDetail() {
    yield takeEvery('place/updatePublicTransportation', updatePublicTransportationDetail)
}

