import {IMAGE_UPLOAD, TEXT, RICH_TEXT, TOGGLE, UPLOAD_PATHS, FILE, MULTI_SELECT, SELECT} from 'config/variables'
import * as Yup                                                from 'yup'

export const resourceFields = [
    {
        name: 'title',
        inputLabel: 'Title',
        type: TEXT
    },
    {
        name: 'videolink',
        inputLabel: 'Video Link',
        type: TEXT
    },
    {
        name: 'document',
        file: 'photoFile',
        inputLabel: 'Document',
        s3Path: UPLOAD_PATHS.post,
        type: FILE,
    },
    {
        name: 'description',
        inputLabel: 'Description',
        type: RICH_TEXT
    },
    {
        name: 'subjects',
        inputLabel: 'Subjects',
        type: MULTI_SELECT
    },
    {
        name: 'category',
        inputLabel: 'Category',
        type: SELECT
    }
]

export const replyAdminFields = [
    {
        name: 'title',
        inputLabel: 'Title',
        type: TEXT
    },
    {
        name: 'videolink',
        inputLabel: 'Video Link',
        type: TEXT
    },
    {
        name: 'document',
        inputLabel: 'Document',
        file: 'photoFile',
        s3Path: UPLOAD_PATHS.post,
        type: FILE,
    },
    {
        name: 'description',
        inputLabel: 'Description',
        type: RICH_TEXT
    }
]


/**
 *
 * Validation Objects written with Yup
 * https://github.com/jquense/yup#api
 *
 */

export const validateReview = Yup.object().shape({
    title: Yup
        .string().max(5000)
        .required('Required')
})
