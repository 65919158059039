import {call, put, takeEvery} from 'redux-saga/effects'
import {getMapboxPlacesList}  from '../services/mapbox'


export function* searchMapboxPlaces({payload}) {
    try {
        const places = yield call(getMapboxPlacesList, payload)

        if (!places.errors && !places.error) {
            yield put({type: 'place/searchMapboxPlacesSuccess', payload: places})

        } else {
            yield put({type: 'place/searchMapboxPlacesFailure', payload: places.errors || places})
        }
    } catch (error) {
        yield put({type: 'place/searchMapboxPlacesFailure', error})
    }
}

export function* getMapboxPlace({payload}) {

}


/**
 *
 *
 * BOONE WATCHERS
 *
 *
 */

export function* watchSearchMapbox() {
    yield takeEvery('place/searchMapboxPlaces', searchMapboxPlaces)
}

export function* watchGetMapboxPlace() {
    yield takeEvery('place/getMapboxPlace', getMapboxPlace)
}
